import { STAGES } from 'apps/dashboard/constants';
import { INVESTMENT_AMOUNT_LABEL, UI_STAGE } from '../../apps/track-details/utils/constants';

import { convertDateStringToHumanReadable } from 'utils/date';
import { determineShouldShowSettlementCalculator, getCurrentStageConfig } from '../../apps/track-details/utils/helpers';

const getTrackDetailsCardTimeLabel = (label, date) => {
  return { label, date: date ? convertDateStringToHumanReadable(date, true) : 'Invalid date' };
};

const getTrackDetailsDefaultTimeStatus = track =>
  getTrackDetailsCardTimeLabel('Started on:', track.inquiry_created_date);

const getTrackDetailsCardDocumentLink = ({ track, hasUnreadDocs = false }) => ({
  label: 'Documents',
  link: `/track/${track.id}/documents`,
  hasUnreadDocs,
});

const TRACK_DETAILS_CARD_BADGE_DEFAULT = {
  label: 'In Progress',
  theme: 'neutralLight50',
};

export const uiStageModels = {
  [UI_STAGE.Apply.key]: {
    getCardData({ track, hasUnreadDocs }) {
      const shouldShowApplicationLink = track.active_application_id && !track.initial_application_reviewed;

      return {
        badge: TRACK_DETAILS_CARD_BADGE_DEFAULT,
        timeStatus: getTrackDetailsDefaultTimeStatus(track),
        links: [
          getTrackDetailsCardDocumentLink({ track, hasUnreadDocs }),
          shouldShowApplicationLink && { label: 'Application', link: `/track/${track.id}/application` },
        ],
      };
    },
  },

  [UI_STAGE.Review.key]: {
    getCardData({ track, hasUnreadDocs }) {
      return {
        timeStatus: getTrackDetailsDefaultTimeStatus(track),
        badge: TRACK_DETAILS_CARD_BADGE_DEFAULT,
        links: [getTrackDetailsCardDocumentLink({ track, hasUnreadDocs })],
      };
    },
  },

  [UI_STAGE.Accept.key]: {
    getCardData({ track, hasUnreadDocs }) {
      return {
        timeStatus: getTrackDetailsDefaultTimeStatus(track),
        badge: TRACK_DETAILS_CARD_BADGE_DEFAULT,
        links: [
          getTrackDetailsCardDocumentLink({ track, hasUnreadDocs }),
          track.active_offer_rundown_id && { label: 'Offer', link: `/offer/${track.active_offer_rundown_id}` },
        ],
      };
    },
  },

  [UI_STAGE.Sign.key]: {
    getCardData({ track, hasUnreadDocs }) {
      return {
        badge: TRACK_DETAILS_CARD_BADGE_DEFAULT,
        timeStatus: getTrackDetailsDefaultTimeStatus(track),
        links: [
          getTrackDetailsCardDocumentLink({ track, hasUnreadDocs }),
          track.active_offer_rundown_id && { label: 'Offer', link: `/offer/${track.active_offer_rundown_id}` },
        ],
      };
    },
  },

  [UI_STAGE.Invested.key]: {
    getCardData({ track, investmentSummary, statements, hasUnreadDocs }) {
      const docsLinkLabel = statements && statements.length ? 'Statements & Documents' : 'Documents';
      const shouldShowSettlementCalculator = determineShouldShowSettlementCalculator({ investmentSummary });

      return {
        badge: {
          label: STAGES.INVESTED,
          theme: 'secondaryGreyOutlined',
        },
        timeStatus: getTrackDetailsCardTimeLabel('Funded on:', track.effective_date),
        links: [
          { ...getTrackDetailsCardDocumentLink({ track, hasUnreadDocs }), label: docsLinkLabel },
          shouldShowSettlementCalculator && {
            label: 'Settlement calculator',
            link: `/investments/${track.active_investment_id}/settlement-calculator`,
          },
        ],
      };
    },
  },

  [UI_STAGE.Settled.key]: {
    getCardData({ track }) {
      return {
        timeStatus: getTrackDetailsCardTimeLabel('Funded on:', track.settlement_date),
        links: [],
        badge: {
          label: track.current_stage_display,
          theme: 'neutralDark50',
        },
      };
    },
  },
};

export const getCurrentUIStageData = track => {
  const {
    allUIProgressStepperStages,
    lastActiveStageIndex,
    currentUIStage,
    currentUIStageConfig,
    canShowProgressStepper,
  } = getCurrentStageConfig(track) ?? {};
  const trackDetailsCardConfig = uiStageModels[currentUIStage?.key];

  if (!trackDetailsCardConfig)
    return {
      lastActiveStageIndex: -1,
      allUIProgressStepperStages: [],
      config: {
        getCardData: ({ track }) => {
          return {
            badge: {
              label: track.current_stage_display,
              theme: 'neutralDark50',
            },
            timeStatus: getTrackDetailsDefaultTimeStatus(track),
            links: [],
          };
        },

        investmentAmountLabel: INVESTMENT_AMOUNT_LABEL.ESTIMATE,
      },
    };

  return {
    lastActiveStageIndex,
    allUIProgressStepperStages,
    canShowProgressStepper,
    config: { ...trackDetailsCardConfig, investmentAmountLabel: currentUIStageConfig.investmentAmountLabel },
  };
};
