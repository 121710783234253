import { ModalConfirm } from '@hometap/htco-components';
import './ApplicantSaveModal.scss';

const ApplicantSaveModal = ({ isUpdating, onSave, applicant, isOpen, onClose }) => {
  const formattedApplicantEmail = <b className="ApplicantSaveModalEmail">{applicant?.invited_email}</b>;

  return isUpdating ? (
    <ModalConfirm
      open={isOpen}
      header="Confirm Applicant Changes"
      confirmText="Continue"
      onConfirm={onSave}
      onClose={onClose}
    >
      If your co-applicant has not already received an email we will send an email to them at {formattedApplicantEmail}
      so they can review and confirm their personal details. They’ll need to complete this step before we can review
      your application.
    </ModalConfirm>
  ) : (
    <ModalConfirm open={isOpen} header="Confirm Applicant" confirmText="Confirm" onConfirm={onSave} onClose={onClose}>
      We’re about to send an email to your co-applicant at {formattedApplicantEmail} so they can review and confirm
      their personal details. They’ll need to complete this step before we can finish reviewing your application.
      <>
        <br />
        <br />
        Please keep in mind that co-applicants will be able to do the following:
        <br />
        <br />
        <ol className="ApplicantSaveModelPermissionsList">
          <li>Upload documents requested by our team.</li>
          <li>View Estimates and Offer.</li>
          <li>Contact your Investment Manager.</li>
        </ol>
      </>
    </ModalConfirm>
  );
};

export default ApplicantSaveModal;
