import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, useBoolState } from '@hometap/htco-components';
import { Link } from 'react-router-dom-v5-compat';

const NavTooltip = ({ title, description, link, linkText, position, children }) => {
  const isTooltipOpen = useBoolState();
  return (
    <div onMouseEnter={() => isTooltipOpen.setTrue()} onMouseLeave={() => isTooltipOpen.setFalse()}>
      <Tooltip
        action="controlled"
        visible={isTooltipOpen.value}
        target="body"
        position={position}
        content={
          <div onMouseLeave={() => isTooltipOpen.setFalse()}>
            <h4 className="mb-2.5">{title}</h4>
            <div className="mb-2.5 text-sm">{description}</div>
            <Link onClick={() => isTooltipOpen.setFalse()} to={link} className="text-blue-500 font-bold">
              {linkText}
            </Link>
          </div>
        }
      >
        {children}
      </Tooltip>
    </div>
  );
};

export default NavTooltip;

NavTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
};
