import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container } from '@hometap/htco-components';
import TrustPilot from 'apps/progressive-inquiry/components/TrustPilot';
import './ProgressiveUITemplate.scss';

const DEFAULT_GRID_SIZE = 6;

const layoutPropTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const panelPropTypes = {
  containsVisual: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  gridSize: PropTypes.number,
  header: PropTypes.node,
};

const imagePanelPropTypes = {
  imageSource: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  includeTrustPilot: PropTypes.bool,
};

const ProgressiveUITemplate = ({ children }) => {
  // This effect will scope a few style overrides to this component in
  // order to achieve a full page layout
  useEffect(() => {
    const FULL_PAGE_CLASSNAME = 'ProgressiveUIFullPageHtml';
    const HEADER_CLASSNAME = 'ProgressiveUIHeader';

    const htmlElement = document.querySelector('html');
    const headerElement = document.querySelector('header');

    htmlElement.classList.add(FULL_PAGE_CLASSNAME);
    headerElement.classList.add(HEADER_CLASSNAME);
  }, []);

  return (
    <div className="ProgressiveUIPageLayout">
      <Container className="ProgressiveUIPageContainer" row space={0}>
        {children}
      </Container>
    </div>
  );
};

const ImageContentPanel = ({ imageSource, imgAlt, includeTrustPilot = false, isCentered = false }) => {
  return (
    <div className={cx('ProgressiveUIImageContentPanel', { hasTrustPilot: includeTrustPilot, isCentered })}>
      <img src={imageSource} alt={imgAlt} />
      {includeTrustPilot && (
        <div className="ProgressiveUIImageContentTrustPilot">
          <div className="GradientLayer" />
          <TrustPilot divider={true} />
        </div>
      )}
    </div>
  );
};

const ContentPanel = ({
  cropImage,
  containsVisual,
  greyTheme = false,
  children,
  gridSize = DEFAULT_GRID_SIZE,
  className,
}) => {
  const containerClasses = cx(
    {
      ProgressiveUIPagePanel: true,
      FlexVisualParent: containsVisual,
      greyTheme,
    },
    className,
  );
  const childClasses = cx({ FlexVisualChild: containsVisual, CroppedImage: cropImage });

  return (
    <Container className="ProgressiveUIPagePanelContainer" grid={gridSize}>
      <div className={containerClasses}>
        <div className={childClasses}>{children}</div>
      </div>
    </Container>
  );
};

const CallToAction = ({ children }) => {
  return <div className="ProgressiveUIPageCTA">{children}</div>;
};

ProgressiveUITemplate.propTypes = layoutPropTypes;
ContentPanel.propTypes = panelPropTypes;
ImageContentPanel.propTypes = imagePanelPropTypes;

export { ProgressiveUITemplate, CallToAction, ContentPanel, ImageContentPanel };
