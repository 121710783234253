import numeral from 'numeral';
// TODO: currency(), percent() functions do not check for NaN or undefined

export const currency = (num = 0, includeCurrencySymbol = true) => {
  const format = includeCurrencySymbol ? '$0,0' : '0,0';
  return numeral(num).format(format);
};

export const percent = (num, format = '0.0') => {
  return numeral(num).format(`${format}%`);
};

export const percentToDecimal = percentInteger => {
  const parsed = parseFloat(percentInteger);

  if (!Number.isNaN(parsed)) {
    return parsed / 100;
  }
  return 0;
};

export const roundToDecimal = (num, decimal = 10) => {
  return Math.round(num / decimal) * decimal;
};

export const formatShareFraction = (num = 0) => {
  return numeral(num).format('0[.]0%');
};

// TODO: this is not used anywhere, should be removed
export const forceValueWithinRange = (n, min, max) => {
  return Math.min(Math.max(n, min), max);
};

// TODO: this is not used anywhere, should be removed
export const isWithinRange = (val, min, max) => val > min && val < max;

export const roundStringToDecimalPlaces = (str, decimals = 2) => parseFloat(str).toFixed(decimals);
