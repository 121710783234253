import React from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { NotFoundBlock, useAsync } from '@hometap/htco-components';
import useSearchParams from 'hooks/useSearchParams';
import { getNewInquiryUrl, getDashboardUrl } from 'utils/links';
import SpinnerLanding from './components/SpinnerLanding';
import InquiryOutcomeSuccessful from 'apps/progressive-inquiry/components/outcome/InquiryOutcomeSuccessful';
import { TRUST_PILOT_SCRIPT } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import 'apps/progressive-inquiry/styles/Inquiry.scss';
import PartnerInquiryUnsuccessful from './components/PartnerInquiryUnsuccessful';
import { fetchInquiryVerification } from './data/partnerInquiryRequests';

const PartnerInquiryController = () => {
  const query = useSearchParams();
  const { id } = useParams();
  const token = query.get('token');
  const body = token ? { token } : {};
  const { results, error } = useAsync(fetchInquiryVerification, {
    immediate: true,
    params: [id, body],
  });
  const { outcome_code, max_amount, set_password_url } = results || {};

  const renderSuccessOutcome = () => {
    switch (outcome_code) {
      case 'email_sent':
        return (
          <InquiryOutcomeSuccessful
            upToAmount={max_amount}
            name={null}
            email={null}
            resentMagicLink={null}
            onClickResendMagicLink={null}
            isAdditionalTrack={null}
            isPartnerOutcome={true}
          />
        );
      case 'converted':
        window.location = set_password_url;
        return <SpinnerLanding />;
      default:
        return <SpinnerLanding />;
    }
  };

  const renderErrorOutcome = () => {
    const errorOutcomeCode = error?.response?.data?.outcome_code || error?.response?.data?.email[0];
    switch (errorOutcomeCode) {
      case 'undetermined':
        return (
          <PartnerInquiryUnsuccessful
            header="Whoops! Your Offer has expired."
            subText="Let’s get you back on track and see if your property is eligible!"
            buttonText="Check my property’s eligibility"
            buttonHref={getNewInquiryUrl()}
          />
        );
      case 'email_must_match_session':
      case 'email_in_use':
      case 'already_converted':
        return (
          <PartnerInquiryUnsuccessful
            header="Hmm...it looks like you already have a Hometap account."
            subText="Please try logging in again with your existing username and password."
            buttonText="Log in"
            buttonHref={getDashboardUrl()}
          />
        );
      default:
        return <NotFoundBlock buttonLink="http://hometap.com" />;
    }
  };

  return (
    <>
      <Helmet>{TRUST_PILOT_SCRIPT}</Helmet>
      <div className={cx('PartnerInquiryController', { successScreen: outcome_code === 'email_sent' })}>
        {error ? renderErrorOutcome() : renderSuccessOutcome()}
      </div>
    </>
  );
};

export default PartnerInquiryController;
