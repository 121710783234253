import React, { useState } from 'react';
import moment from 'moment';
import { DataTable, Paper, Toggle, useWindowSize, Button, useBoolState } from '@hometap/htco-components';

import DocumentsUploadController from 'apps/documents/DocumentsUploadController';
import DownloadFileButton from 'components/DownloadFileButton/DownloadFileButton';
import NoDocuments from './components/NoDocuments/NoDocuments';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import SecondaryHeader from 'components/Headers/SecondaryHeader/SecondaryHeader';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import Footer from 'components/Footer/Footer';
import { getFormattedTime, getFormattedDate } from 'utils/date';
import { getInvestmentStatementUrl } from 'utils/links';
import useTrackDocuments from '../documents/hooks/useTrackDocuments';
import useTrack from 'hooks/useTrack';
import useInvestmentStatementList from 'apps/quarterly-statement/hooks/useInvestmentStatementList';
import useReadDocument from 'apps/documents/hooks/useReadDocument';

import './ResourcesTable.scss';
import NewBadge from 'components/NewBadge/NewBadge';

const DOCUMENT_LIST_TYPE = {
  HOMEOWNER: { label: 'My uploads', value: 'homeowner' },
  SHARED: { label: 'Shared with me', value: 'shared' },
};

const QUARTERLY_STATEMENT_DOCUMENT_KIND = 'quarterly_account_statement';

const StatementLink = ({ statement, track, showNewBadge }) => (
  <a
    href={getInvestmentStatementUrl({
      trackId: track?.id,
      investmentId: track?.active_investment_id,
      statementId: statement.id,
    })}
    className="mb-4 font-bold"
    data-testid="resources-statements-link"
  >
    {moment(statement.quarter.start).format('MMMM')}
    {' - '}
    {moment(statement.quarter.end).format('MMMM YYYY')}{' '}
    {showNewBadge && <NewBadge testId="resources-statements-badge" className="mb-0 ml-3 mr-2" />}
  </a>
);

const DocumentLink = ({ document }) => {
  const readDocumentMutation = useReadDocument();

  const formatFile = (name, extension) => {
    if (name.includes(extension)) {
      return name;
    }
    return `${name}.${extension}`;
  };

  return (
    <a
      href={document.file_url}
      onClick={() =>
        readDocumentMutation.mutate({
          documentId: document.id,
        })
      }
      target="_blank"
      rel="noopener noreferrer"
      className="font-bold"
    >
      {formatFile(document.name, document.file_extension)}
    </a>
  );
};

const MobileDocumentDownloadButton = ({ document, props }) => (
  <DownloadFileButton
    fileURL={document.file_url}
    filename={document.filename}
    {...props}
    size="normal"
    className="!p-0 [&_i]:!top-0 [&_i]:!m-0 [&_i]:!p-0"
  />
);

const ResourcesTableController = () => {
  const [toggleValue, setToggleValue] = useState(DOCUMENT_LIST_TYPE.SHARED.value);
  const { track } = useTrack();
  const { isScreenSize, screenWidth } = useWindowSize();

  const { statements, statementsQuery } = useInvestmentStatementList({
    track,
    investmentId: track?.active_investment_id,
  });
  const {
    documents,
    isLoading: isDocumentsLoading,
    error: documentsError,
    executeFetchTrackDocuments,
  } = useTrackDocuments({ trackId: track?.id, fetchImmediately: true });
  // We need a track in order to load investment statements and documents
  const isLoading = !track || statementsQuery.isLoading || isDocumentsLoading;
  const error = statementsQuery.error || documentsError;

  const filteredDocuments = documents.filter(document => {
    if (document.kind === QUARTERLY_STATEMENT_DOCUMENT_KIND) {
      return false;
    }

    if (toggleValue === DOCUMENT_LIST_TYPE.HOMEOWNER.value) {
      return !document.isShared;
    }

    return document.isShared;
  });

  const orderedStatements = statements?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const showNewBadge = row => {
    const [mostRecentStatement] = orderedStatements;
    const isMostRecentStatement = mostRecentStatement.id === row.id;
    const hasBeenRead = !!row.last_viewed_at;

    // If statement is the most recent AND it has not been viewed, show the new badge
    return isMostRecentStatement && !hasBeenRead;
  };

  const documentUploadedTime = document => {
    const formattedDate = getFormattedDate(document.created_at);
    const formattedTime = getFormattedTime(document.created_at);
    return `${formattedDate} at ${formattedTime}`;
  };

  const statementColumnConfig = [
    {
      name: 'Statement',
      selector: row => <StatementLink statement={row} track={track} showNewBadge={showNewBadge(row)} />,
      minWidth: '50%',
    },
    {
      name: 'Uploaded',
      selector: row => getFormattedDate(row.created_at),
    },
  ];

  const mobileStatementColumnConfig = [
    {
      name: 'Statement',
      selector: row => {
        return (
          <div>
            <div className="h-6 content-center text-sm">
              <StatementLink statement={row} track={track} showNewBadge={showNewBadge(row)} />
            </div>
            <p className="m-0 mt-1.5 text-neutral-dark-75">{getFormattedDate(row.created_at)}</p>
          </div>
        );
      },
    },
  ];

  const hasStatementDocument = orderedStatements?.some(statement => statement.document);
  if (hasStatementDocument) {
    statementColumnConfig.push({
      name: '',
      selector: row =>
        row.document && (
          <div className="text-neutral-dark-75">
            <DownloadFileButton
              fileURL={row.document.file_url}
              filename={row.document.filename}
              data-testid="statement-download"
            >
              Download
            </DownloadFileButton>
          </div>
        ),
    });

    mobileStatementColumnConfig.push({
      name: '',
      selector: row =>
        row.document && <MobileDocumentDownloadButton document={row.document} data-testid="statement-download" />,
      right: true,
      style: { alignItems: 'flex-start', minWidth: 'max-content', paddingLeft: 0 },
      allowOverflow: false,
      grow: 0,
    });
  }

  const documentColumnConfig = [
    {
      name: 'File',
      selector: row => (
        <>
          {row.is_unread_by_homeowner && <NewBadge className="mr-2" testId="new-document-label" />}
          <DocumentLink document={row} />
        </>
      ),
    },
    {
      name: 'Document type',
      selector: row => (
        <div className="text-neutral-dark-75">
          {row.kind_display}
          {!!row.uploadedFor?.applicant?.full_name && (
            <p className="m-0 mt-1.5 text-neutral-dark-50">{row.uploadedFor.applicant.full_name}</p>
          )}
        </div>
      ),
    },
    {
      name: 'Uploaded',
      selector: row => (
        <div className="text-neutral-dark-75">
          {documentUploadedTime(row)}
          <p className="m-0 mt-1.5 text-neutral-dark-50">{`By ${row.createdByDisplayName}`}</p>
        </div>
      ),
    },
    {
      name: '',
      selector: row => (
        <div className="text-neutral-dark-75">
          <DownloadFileButton fileURL={row.file_url} filename={row.filename}>
            Download
          </DownloadFileButton>
        </div>
      ),
    },
  ];

  const mobileDocumentColumnConfig = [
    {
      name: 'Document',
      selector: row => {
        return (
          <>
            <div className="text-sm text-neutral-dark-75">
              {row.is_unread_by_homeowner && <NewBadge className="mr-2" testId="new-document-label" />}
              <DocumentLink document={row} />
            </div>
            <div>
              <p className="m-0 mt-2 whitespace-normal">
                {row.kind_display}
                {row.uploadedFor?.applicant?.full_name ? ` for ${row.uploadedFor?.applicant?.full_name}` : ''}
              </p>
              <p className="m-0 mt-1.5 whitespace-normal">
                {documentUploadedTime(row)} by {row.createdByDisplayName}
              </p>
            </div>
          </>
        );
      },
    },
    {
      name: '',
      selector: row => <MobileDocumentDownloadButton document={row} />,
      right: true,
      style: { alignItems: 'flex-start', minWidth: 'max-content', paddingLeft: 0 },
      allowOverflow: false,
      grow: 0,
    },
  ];

  const statementColumns = isScreenSize('sm') ? mobileStatementColumnConfig : statementColumnConfig;
  const documentColumns = isScreenSize('sm') ? mobileDocumentColumnConfig : documentColumnConfig;
  const isModalOpen = useBoolState();

  const UPLOAD_MODAL_WIDTH = 1130;

  const shouldShowQuarterlyStatements = track?.active_investment_id && statements?.length > 0 && !statementsQuery.error;

  return (
    <SectionLoadingWrapper loading={isLoading} error={error}>
      <PrimaryLayoutGrid className="m-auto pt-6 sm:pt-8 md:px-16">
        <div className="m-w-[1512px] col-span-full">
          <SecondaryHeader label={'Investment ID:'} value={track?.friendly_id} />
          {shouldShowQuarterlyStatements && (
            <Paper className="mb-10 rounded-xl" data-testid="resources_statements_table">
              <div className="border-0 border-b border-solid border-neutral-light-75 p-6 max-xs:p-3">
                <h2 className="m-0 self-center">Statements</h2>
                <p className="m-0 mt-2 text-lg text-neutral-dark-75 max-sm:text-base max-xs:mt-0">
                  View quarterly statements for your Investment
                </p>
              </div>
              <div>
                <DataTable className="ResourcesTable" columns={statementColumns} data={orderedStatements} />
              </div>
            </Paper>
          )}
          <Paper className="mb-10 rounded-xl" data-testid="resources_documents_table">
            <div className="border-0 border-b border-solid border-neutral-light-75 p-6 max-sm:p-4 max-xs:p-3">
              <div className="ResourcesTableHeadingUploadRow mb-2 flex items-stretch justify-between gap-3">
                <h2 className="m-0 self-center">Shared documents</h2>
                <Button size="small" onClick={() => isModalOpen.setTrue()}>
                  Upload
                </Button>
              </div>
              <p className="m-0 mt-2 text-lg text-neutral-dark-75 max-sm:text-base max-xs:mt-0">
                View documents that you’ve submitted or have been shared with you, and upload new documents
              </p>
              <Toggle
                className="mt-6"
                value={toggleValue}
                options={Object.values(DOCUMENT_LIST_TYPE)}
                onChange={setToggleValue}
              />
            </div>
            <div className="max-w-full">
              <DataTable
                className="ResourcesTable"
                columns={documentColumns}
                data={filteredDocuments}
                noDataComponent={<NoDocuments />}
                customStyles={{ tableWrapper: { style: { display: 'block' } } }}
              />
            </div>
          </Paper>
          <Footer />
        </div>

        <DocumentsUploadController
          includeKindSelect
          asModalProps={{
            open: isModalOpen.value,
            width: screenWidth >= UPLOAD_MODAL_WIDTH + 20 ? UPLOAD_MODAL_WIDTH : '95%',
            onClose: () => isModalOpen.setFalse(),
          }}
          onUpload={async () => {
            isModalOpen.setFalse();
            setToggleValue(DOCUMENT_LIST_TYPE.HOMEOWNER.value);
            return await executeFetchTrackDocuments();
          }}
        />
      </PrimaryLayoutGrid>
    </SectionLoadingWrapper>
  );
};

export default ResourcesTableController;
