import React, { useEffect } from 'react';
import { SeasonChecklist } from 'apps/dashboard/components/maintenance-controller/homeMaintenanceTasks';

import { useHomeMaintenanceTasks } from 'apps/dashboard/hooks';
import Footer from 'components/Footer/Footer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

const MaintenanceController = () => {
  const { onLoadTasks, onSelectTask, curSeasonChecklist, outOfSeasonChecklists } = useHomeMaintenanceTasks();

  useEffect(() => {
    onLoadTasks();
  }, [onLoadTasks]);

  return (
    <ScrollToTop>
      <div className="col-span-full">
        <div className="pb-16 lg:pb-20">
          <div className="pl-2 sm:pl-0">
            <h2>Home maintenance</h2>
            <p className="m-0 mt-2 text-sm sm:text-lg">
              Keep your home in shape with seasonal checklists and DIY maintenance guides
            </p>
          </div>
          <div className="border-0 border-b border-solid border-b-neutral-light-100 pb-12">
            <div className="flex flex-col justify-between gap-6 pt-6 sm:flex-row">
              <div className="w-full" data-testid="current-tasks">
                <SeasonChecklist checklist={curSeasonChecklist} onSelectTask={onSelectTask} />
              </div>
            </div>
          </div>
          <div className="my-6 sm:my-10" data-testid="upcoming-tasks">
            <div className="tracking-px mb-8 text-sm uppercase text-neutral-dark-100">
              Plan ahead for upcoming seasons
            </div>
            <div className="grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3" data-testid="season-list">
              {outOfSeasonChecklists.map(checklist => {
                return (
                  <div key={checklist.season}>
                    <SeasonChecklist checklist={checklist} onSelectTask={onSelectTask} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer theme="light" />
      </div>
    </ScrollToTop>
  );
};

export default MaintenanceController;
