import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@hometap/htco-components';

import { getDashboardUrl } from 'utils/links';
import { parseRiskPrequalOutcomeDetails } from 'apps/progressive-inquiry/utils/parsePrequalificationOutcomes';
import { RISK_BAND_TYPES, FICO_SCORE_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';

const ReInquiryOutcomeFailure = ({ prequalifications, firstName, lastName, ficoScore }) => {
  const { InvestmentAmountPrequalification, RiskPrequalification } = prequalifications;
  const riskFailFactors = RiskPrequalification?.additional_details?.risk_fail_factors;
  const { risk_band, fico_score } = parseRiskPrequalOutcomeDetails(InvestmentAmountPrequalification?.outcome_details);

  const getAdverseActionLocationProps = (riskBand, fico) => {
    if (riskBand === RISK_BAND_TYPES.FAIL) {
      return { pathname: '/adverse-action/risk-band', state: { riskFailFactors } };
    }
    if (fico === FICO_SCORE_TYPES.FAIL) {
      return {
        pathname: '/adverse-action/fico',
        state: { riskFailFactors, ficoScore, firstName, lastName },
      };
    }
  };

  return (
    <>
      <h1>Sorry, we’re unable to invest in your property right now.</h1>
      {fico_score === FICO_SCORE_TYPES.FAIL || risk_band === RISK_BAND_TYPES.FAIL ? (
        <p>
          Unfortunately, your property no longer meets the pre-qualification criteria for a Hometap Investment. You may
          click <Link to={getAdverseActionLocationProps(risk_band, fico_score)}>here</Link> for more details. Please
          reach out to your Investment Manager with any additional questions.
        </p>
      ) : (
        <p>
          Unfortunately, your property no longer meets the pre-qualification criteria for a Hometap Investment. For more
          details, please reach out to your Investment Manager.
        </p>
      )}
      <Button label="Return to Dashboard" href={getDashboardUrl()} />
    </>
  );
};

export default ReInquiryOutcomeFailure;
