import { useMemo } from 'react';
import { STAGES, INVESTED_HOMEOWNER_SUPPORT_NUMBER, INVESTMENT_SUPPORT_EMAIL } from 'apps/dashboard/constants';
import investmentCat from 'images/dashboard/investment-support-cat.png';
import investmentDog from 'images/dashboard/investment-support-dog.png';

const preInvestmentStageList = [
  STAGES.APP_READY,
  STAGES.APP_IN_PROGRESS,
  STAGES.APP_IN_REVIEW,
  STAGES.APP_APPROVED,
  STAGES.OFFER_PREPARED,
  STAGES.OFFER_READY,
  STAGES.CONDITIONS_IN_REVIEW,
  STAGES.CLOSING_IN_PROGRESS,
];

const postInvestmentStageList = [STAGES.INVESTED];

const useInvestmentSupportContacts = tracks => {
  return useMemo(() => {
    const investmentManagers = {};
    const contacts = [];

    tracks?.forEach(track => {
      if (track.investment_manager && preInvestmentStageList.includes(track.current_stage)) {
        const imID = track.investment_manager.id;
        const ids = investmentManagers[imID]?.friendly_ids || [];
        ids.push(track.friendly_id);

        investmentManagers[imID] = {
          friendly_ids: ids,
          investment_manager: track.investment_manager,
        };
      }
    });

    const uniqInvestmentManagers = Object.values(investmentManagers);

    // Add Investment Managers contacts
    uniqInvestmentManagers.forEach(manager => {
      contacts.push({
        friendly_id: manager.friendly_ids.join(', '),
        card_title: 'Investment Manager',
        contact_information: manager.investment_manager,
        card_description:
          'Hometap Investment Managers work with you from start to finish to get your investment funded.',
      });
    });

    // Add post-investment contact
    const postInvestmentTrack = tracks?.find(track => postInvestmentStageList.includes(track.current_stage));
    if (postInvestmentTrack) {
      contacts.push({
        friendly_id: postInvestmentTrack.friendly_id,
        card_title: 'Investment Support',
        contact_information: {
          scheduling_url: 'https://calendly.com/investment-support',
          headshot_url: investmentCat,
          phone_number: INVESTED_HOMEOWNER_SUPPORT_NUMBER,
          email: INVESTMENT_SUPPORT_EMAIL,
        },
        card_description:
          'After your investment is funded, Hometap’s Investment Support team can help you settle when you are ready, or answer any questions along the way.',
      });
    }

    // Add general contact if no managers or post-investment contact
    if (!uniqInvestmentManagers.length && !postInvestmentTrack) {
      contacts.push({
        card_title: 'Contact Us',
        contact_information: {
          headshot_url: investmentDog,
          phone_number: INVESTED_HOMEOWNER_SUPPORT_NUMBER,
        },
        card_description: 'Call us toll-free Monday - Friday: 9am - 5pm EST',
      });
    }

    return contacts;
  }, [tracks]);
};

export default useInvestmentSupportContacts;
