import { RouterPrompt } from 'apps/progressive-inquiry/components/RouterPrompt';
import PropTypes from 'prop-types';

import BackToDashBoardButton from './BackToDashboardButton';

const ProgressiveFormSlides = ({ shouldShowPrompt = false, canGoBackToDashboard = false, currentSlide }) => {
  return (
    <>
      <RouterPrompt when={shouldShowPrompt} />
      {canGoBackToDashboard && <BackToDashBoardButton />}
      {currentSlide}
    </>
  );
};

export default ProgressiveFormSlides;

ProgressiveFormSlides.propTypes = {
  canGoBackToDashboard: PropTypes.bool,
  shouldShowPrompt: PropTypes.bool.isRequired,
  currentSlide: PropTypes.node.isRequired,
};
