import { apiWithAuth } from 'utils/api';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';

export const fetchInvestmentSummary = id => {
  return apiWithAuth.v1.get(`/investments/${id}/`);
};

export const fetchScenarios = async (trackFriendlyId, data) => {
  return await apiWithAuth.v1.post(`/tracks/${trackFriendlyId}/actions/calculate-scenarios/`, data);
};

export const sendCalculatorFormEvent = (settlementExpectedDate, settlementExpectedAmount, settlementExpectedShare) => {
  browserTrack.homeownerCalculatorInteractionOccurred({
    calculator_name: 'settlement calculator',
    interaction_type: 'calculate settlement amount',
    settlement_expected_date: settlementExpectedDate,
    settlement_expected_amount: settlementExpectedAmount,
    settlement_expected_share: Math.round(settlementExpectedShare),
    version_number: getVersion(),
  });
};
