import { capitalizeFirstLetter, formatPhoneNumberSeparator } from 'utils/stringFormatters';
import { currency } from 'utils/numbers';
import { formatFullAddress } from 'utils/address';

const formatBool = val => (val ? 'Yes' : 'No');

const APPLICANT_ENUMS = {
  gender: { o: 'Other', m: 'Male', f: 'Female' },
  yesNoNotSure: { yes: 'Yes', no: 'No', not_sure: 'Not Sure' },
  relationshipToPrimary: {
    spouse: 'Spouse',
    significant_other: 'Significant Other',
    parent: 'Parent ',
    child: 'Child ',
    sibling: 'Sibling',
    grandparent: 'Grandparent',
    grandchild: 'Grandchild',
    parent_in_law: 'Parent in Law',
    child_in_law: 'Child in Law',
    roommate: 'Roommate',
    other: 'Other',
    self: 'Self',
  },
};

export const mapApplicantKeysAndValues = applicant => {
  const APPLICANT_DEROGATORY_ITEM_COMMENT = { yes: `Yes: ${applicant.derogatory_item_comment}`, no: 'No' };
  const applicantKeysAndValues = [
    { heading: 'Employment Status', value: capitalizeFirstLetter(applicant.employment_status) },
    { heading: 'Gender', value: APPLICANT_ENUMS.gender[applicant.gender] },
    { heading: 'Self-employed?', value: formatBool(applicant.job_self_employed) },
    {
      heading: 'Relationship to Applicant',
      value: APPLICANT_ENUMS.relationshipToPrimary[applicant.relationship_to_primary],
    },
    { heading: 'Company Name', value: applicant.job_company },
    { heading: 'Phone', value: formatPhoneNumberSeparator(applicant.phone_number) },
    { heading: 'Job Title', value: applicant.job_title },
    { heading: 'Email', value: applicant.invited_email },
    { heading: 'Company Phone', value: formatPhoneNumberSeparator(applicant.job_phone_number) },
    { heading: 'Marital Status', value: capitalizeFirstLetter(applicant.marital_status) },
    { heading: 'Total Annual Income', value: currency(applicant.total_annual_income) },
    { heading: 'SSN', value: applicant.ssn },
    { heading: 'On Title', value: APPLICANT_ENUMS.yesNoNotSure[applicant.on_title] },
    {
      heading: 'Have you been involved in a Felony, Bankruptcy, Foreclosure or Short Sale?',
      value: APPLICANT_DEROGATORY_ITEM_COMMENT[applicant.declaration_derogatory_item],
    },
  ];
  return applicantKeysAndValues;
};

export const mapApplicantKeysAndValuesV2 = applicant => {
  const address = applicant?.mailing_address;
  const applicantKeysAndValues = [
    { heading: 'Social Security Number', value: applicant.ssn },
    { heading: 'Marital Status', value: capitalizeFirstLetter(applicant.marital_status) },
    {
      heading: 'Relationship to Applicant',
      value: APPLICANT_ENUMS.relationshipToPrimary[applicant.relationship_to_primary],
    },
    { heading: 'Email Address', value: applicant.invited_email },
    { heading: 'Phone Number', value: formatPhoneNumberSeparator(applicant.phone_number) },
    { heading: 'Preferred Contact Method', value: capitalizeFirstLetter(applicant.preferred_contact_method) },
    {
      heading: 'Mailing Address',
      value: formatFullAddress(address?.street, address?.unit, address?.city, address?.state, address?.zip_code),
    },
    { heading: 'Employment Status', value: capitalizeFirstLetter(applicant.employment_status) },
    { heading: 'Total Annual Income', value: currency(applicant.total_annual_income) },
  ];
  return applicantKeysAndValues;
};

export const mapApplicantV2DerogatoryData = applicant => {
  return [
    { heading: 'Had a bankruptcy filing?', value: formatBool(applicant.has_had_bankruptcy_filing) },
    { heading: 'Been convicted of a felony? ', value: formatBool(applicant.has_been_convicted_of_felony) },
    {
      heading: 'Received a pre-foreclosure notice for a property you owned?',
      value: formatBool(applicant.has_received_pre_foreclosure_notice),
    },
    {
      heading: 'Completed a pre-foreclosure sale or short sale on a property?',
      value: formatBool(applicant.has_completed_pre_foreclosure_sale),
    },
    {
      heading: 'Transferred a title to any property in lieu of foreclosure?',
      value: formatBool(applicant.has_transferred_title_due_to_foreclosure),
    },
  ];
};
