import { useEffect, useState } from 'react';
import useCurrentUser from './useCurrentUser';

/**
 * useGliaIdentification - identify user for glia, it will assign the current user's email as externalId
 * @returns
 */
export const useGliaIdentification = () => {
  const { user } = useCurrentUser();
  const [gliaStatus, setGliaStatus] = useState();
  const { sm } = global;

  useEffect(() => {
    if (sm?.getApi && user) {
      sm.getApi({ version: 'v1' }).then(function (glia) {
        glia
          .updateInformation({
            externalId: user.email.toLowerCase(),
          })
          .then(() => {
            setGliaStatus('success');
          })
          .catch(e => {
            console.error('Error updating information for Glia', e);
            setGliaStatus('error');
          });
      });
    }
  }, [sm, user]);

  return gliaStatus;
};

/**
 * useEnableGlia - hook to enable a certain page to show on Glia cobrowsing/live session
 * @param {boolean} enable - is Glia enabled for this page?
 * @param {boolean} interactive - is the page interactive for co-browsing session?
 * @returns
 */
export const useEnableGlia = (enable = false, interactive = true) => {
  useEffect(() => {
    // Had to set the class on #App container as we're using bodyAttributes to set light/dark theme for the page
    const appContainer = document.querySelector('#App');
    appContainer.classList.toggle('sm_cobrowsing_hidden_field', !enable); // !enable because we hide the class when we want to enable Glia
    appContainer.classList.toggle('sm_cobrowsing_disabled_field', !interactive);

    return () => {
      appContainer.classList.toggle('sm_cobrowsing_hidden_field', true); // By default it should have the class, so when we unmount we add the class back
      appContainer.classList.toggle('sm_cobrowsing_disabled_field', false);
    };
  }, [enable, interactive]);

  return [enable, interactive];
};
