import { React, useMemo } from 'react';
import PropTypes from 'prop-types';

// tracking pixel for Audacy. place in pages <body> - https://hometap.atlassian.net/browse/HOOT-779

const AudacyPixel = ({ propertyId, goalName, goalId }) => {
  // random number to make sure this pixel doesn't get cached
  const uuid = useMemo(() => new Date().getTime() + parseInt(Math.random() * 10_000_000_000_000), []);

  return (
    <img
      src={`https://trkn.us/pixel/conv/ppt=${propertyId};g=${goalName};gid=${goalId};ord=${uuid}`}
      height="0"
      width="0"
      alt=""
      border="0"
      style={{ position: 'absolute' }}
    />
  );
};

AudacyPixel.propTypes = {
  propertyId: PropTypes.string,
  goalName: PropTypes.string.isRequired,
  goalId: PropTypes.string.isRequired,
};

AudacyPixel.defaultProps = {
  propertyId: '18544',
};

export default AudacyPixel;
