import { twMerge } from 'tailwind-merge';

import cx from 'classnames';

const PageTitle = ({ className, title }) => {
  return (
    <p className={twMerge(cx('col-span-full m-0 mb-2 text-sm font-bold text-neutral-dark-75', className))}>{title}</p>
  );
};

export default PageTitle;
