"use strict";
// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toastShown = exports.tappableEquityCardEligible = exports.smartCardsCompleted = exports.smartCardViewed = exports.skipBuildProfile = exports.pageViewed = exports.onboardingCTAHigher = exports.maritalStateTodoViewDetailsClicked = exports.maritalStateTodoDismissed = exports.maritalStateTodoCompleted = exports.houseCanaryUpdateLienInformation = exports.homeownerTrackCreatedBrowser = exports.homeownerPreQualificationOutcomeFormSubmitted = exports.homeownerOfferOutcomeSetAcceptedBrowser = exports.homeownerInvestmentRundownViewed = exports.homeownerInvestmentRundownInteractionOccurred = exports.homeownerCalculatorInteractionOccurred = exports.homeownerApplicationCompletedBrowser = exports.homeownerAccountCreatedBrowser = exports.googleAddressClicked = exports.featuredOffersClick = exports.errorShown = exports.equityDashboardAccountCreatedBrowser = exports.engagementFormCompleted = exports.calculatorInteractionOccurred = exports.cTAEnablementChanged = exports.cTAClicked = exports.setTypewriterOptions = exports.defaultValidationErrorHandler = void 0;
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
var defaultValidationErrorHandler = function (message, violations) {
    var msg = JSON.stringify({
        type: 'Typewriter JSON Schema Validation Error',
        description: "You made an analytics call (".concat(message.event, ") using Typewriter that doesn't match the ") +
            'Tracking Plan spec.',
        errors: violations,
    }, undefined, 2);
    console.warn(msg);
};
exports.defaultValidationErrorHandler = defaultValidationErrorHandler;
var analytics = function () {
    return window.analytics;
};
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
function setTypewriterOptions(options) {
    analytics = options.analytics ? function () { return options.analytics || window.analytics; } : analytics;
}
exports.setTypewriterOptions = setTypewriterOptions;
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message) {
    if (message === void 0) { message = {}; }
    return __assign(__assign({}, message), { context: __assign(__assign({}, (message.context || {})), { typewriter: {
                language: 'typescript',
                version: '8.1.0',
            } }) });
}
/**
 * Fires a 'CTAClicked' track call.
 *
 * @param CTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function cTAClicked(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('CTA Clicked', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.cTAClicked = cTAClicked;
/**
 * Fires a 'CTAEnablementChanged' track call.
 *
 * @param CTAEnablementChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function cTAEnablementChanged(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('CTA Enablement Changed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.cTAEnablementChanged = cTAEnablementChanged;
/**
 * Fires a 'CalculatorInteractionOccurred' track call.
 *
 * @param CalculatorInteractionOccurred props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function calculatorInteractionOccurred(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Calculator Interaction Occurred', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.calculatorInteractionOccurred = calculatorInteractionOccurred;
/**
 * Fires a 'EngagementFormCompleted' track call.
 *
 * @param EngagementFormCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function engagementFormCompleted(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Engagement Form Completed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.engagementFormCompleted = engagementFormCompleted;
/**
 * Fires a 'EquityDashboardAccountCreatedBrowser' track call.
 *
 * @param EquityDashboardAccountCreatedBrowser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function equityDashboardAccountCreatedBrowser(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Equity Dashboard Account Created Browser', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.equityDashboardAccountCreatedBrowser = equityDashboardAccountCreatedBrowser;
/**
 * Fires a 'ErrorShown' track call.
 *
 * @param ErrorShown props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function errorShown(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Error Shown', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.errorShown = errorShown;
/**
 * Fires a 'FeaturedOffersClick' track call.
 *
 * @param FeaturedOffersClick props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function featuredOffersClick(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Featured Offers Click', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.featuredOffersClick = featuredOffersClick;
/**
 * Fires a 'GoogleAddressClicked' track call.
 *
 * @param GoogleAddressClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function googleAddressClicked(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Google Address Clicked', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.googleAddressClicked = googleAddressClicked;
/**
 * Fires a 'HomeownerAccountCreatedBrowser' track call.
 *
 * @param HomeownerAccountCreatedBrowser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerAccountCreatedBrowser(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Account Created Browser', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerAccountCreatedBrowser = homeownerAccountCreatedBrowser;
/**
 * Fires a 'HomeownerApplicationCompletedBrowser' track call.
 *
 * @param HomeownerApplicationCompletedBrowser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerApplicationCompletedBrowser(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Application Completed Browser', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerApplicationCompletedBrowser = homeownerApplicationCompletedBrowser;
/**
 * Fires a 'HomeownerCalculatorInteractionOccurred' track call.
 *
 * @param HomeownerCalculatorInteractionOccurred props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerCalculatorInteractionOccurred(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Calculator Interaction Occurred', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerCalculatorInteractionOccurred = homeownerCalculatorInteractionOccurred;
/**
 * Fires a 'HomeownerInvestmentRundownInteractionOccurred' track call.
 *
 * @param HomeownerInvestmentRundownInteractionOccurred props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerInvestmentRundownInteractionOccurred(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Investment Rundown Interaction Occurred', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerInvestmentRundownInteractionOccurred = homeownerInvestmentRundownInteractionOccurred;
/**
 * Fires a 'HomeownerInvestmentRundownViewed' track call.
 *
 * @param HomeownerInvestmentRundownViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerInvestmentRundownViewed(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Investment Rundown Viewed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerInvestmentRundownViewed = homeownerInvestmentRundownViewed;
/**
 * Fires a 'HomeownerOfferOutcomeSetAcceptedBrowser' track call.
 *
 * @param HomeownerOfferOutcomeSetAcceptedBrowser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerOfferOutcomeSetAcceptedBrowser(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Offer Outcome Set Accepted Browser', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerOfferOutcomeSetAcceptedBrowser = homeownerOfferOutcomeSetAcceptedBrowser;
/**
 * Fires a 'HomeownerPreQualificationOutcomeFormSubmitted' track call.
 *
 * @param HomeownerPreQualificationOutcomeFormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerPreQualificationOutcomeFormSubmitted(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Pre-Qualification Outcome Form Submitted', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerPreQualificationOutcomeFormSubmitted = homeownerPreQualificationOutcomeFormSubmitted;
/**
 * Fires a 'HomeownerTrackCreatedBrowser' track call.
 *
 * @param HomeownerTrackCreatedBrowser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function homeownerTrackCreatedBrowser(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Homeowner Track Created Browser', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.homeownerTrackCreatedBrowser = homeownerTrackCreatedBrowser;
/**
 * Fires a 'HouseCanaryUpdateLienInformation' track call.
 *
 * @param HouseCanaryUpdateLienInformation props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function houseCanaryUpdateLienInformation(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('HouseCanary Update Lien Information', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.houseCanaryUpdateLienInformation = houseCanaryUpdateLienInformation;
/**
 * Fires a 'MaritalStateTodoCompleted' track call.
 *
 * @param MaritalStateTodoCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function maritalStateTodoCompleted(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Marital State Todo Completed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.maritalStateTodoCompleted = maritalStateTodoCompleted;
/**
 * Fires a 'MaritalStateTodoDismissed' track call.
 *
 * @param MaritalStateTodoDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function maritalStateTodoDismissed(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Marital State Todo Dismissed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.maritalStateTodoDismissed = maritalStateTodoDismissed;
/**
 * Fires a 'MaritalStateTodoViewDetailsClicked' track call.
 *
 * @param MaritalStateTodoViewDetailsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function maritalStateTodoViewDetailsClicked(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Marital State Todo View Details Clicked', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.maritalStateTodoViewDetailsClicked = maritalStateTodoViewDetailsClicked;
/**
 * Fires a 'OnboardingCTAHigher' track call.
 *
 * @param OnboardingCTAHigher props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function onboardingCTAHigher(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Onboarding CTA Higher', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.onboardingCTAHigher = onboardingCTAHigher;
/**
 * Fires a 'PageViewed' track call.
 *
 * @param PageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function pageViewed(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Page Viewed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.pageViewed = pageViewed;
/**
 * Fires a 'SkipBuildProfile' track call.
 *
 * @param SkipBuildProfile props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function skipBuildProfile(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Skip Build Profile', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.skipBuildProfile = skipBuildProfile;
/**
 * Fires a 'SmartCardViewed' track call.
 *
 * @param SmartCardViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function smartCardViewed(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Smart Card Viewed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.smartCardViewed = smartCardViewed;
/**
 * Fires a 'SmartCardsCompleted' track call.
 *
 * @param SmartCardsCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function smartCardsCompleted(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Smart Cards Completed', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.smartCardsCompleted = smartCardsCompleted;
/**
 * Fires a 'TappableEquityCardEligible' track call.
 *
 * @param TappableEquityCardEligible props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function tappableEquityCardEligible(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Tappable Equity Card Eligible', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.tappableEquityCardEligible = tappableEquityCardEligible;
/**
 * Fires a 'ToastShown' track call.
 *
 * @param ToastShown props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function toastShown(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Toast Shown', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.toastShown = toastShown;
var clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnalyticsJS} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions: setTypewriterOptions,
    /**
     * Fires a 'CTAClicked' track call.
     *
     * @param CTAClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cTAClicked: cTAClicked,
    /**
     * Fires a 'CTAEnablementChanged' track call.
     *
     * @param CTAEnablementChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cTAEnablementChanged: cTAEnablementChanged,
    /**
     * Fires a 'CalculatorInteractionOccurred' track call.
     *
     * @param CalculatorInteractionOccurred props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    calculatorInteractionOccurred: calculatorInteractionOccurred,
    /**
     * Fires a 'EngagementFormCompleted' track call.
     *
     * @param EngagementFormCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    engagementFormCompleted: engagementFormCompleted,
    /**
     * Fires a 'EquityDashboardAccountCreatedBrowser' track call.
     *
     * @param EquityDashboardAccountCreatedBrowser props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    equityDashboardAccountCreatedBrowser: equityDashboardAccountCreatedBrowser,
    /**
     * Fires a 'ErrorShown' track call.
     *
     * @param ErrorShown props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    errorShown: errorShown,
    /**
     * Fires a 'FeaturedOffersClick' track call.
     *
     * @param FeaturedOffersClick props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    featuredOffersClick: featuredOffersClick,
    /**
     * Fires a 'GoogleAddressClicked' track call.
     *
     * @param GoogleAddressClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    googleAddressClicked: googleAddressClicked,
    /**
     * Fires a 'HomeownerAccountCreatedBrowser' track call.
     *
     * @param HomeownerAccountCreatedBrowser props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerAccountCreatedBrowser: homeownerAccountCreatedBrowser,
    /**
     * Fires a 'HomeownerApplicationCompletedBrowser' track call.
     *
     * @param HomeownerApplicationCompletedBrowser props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerApplicationCompletedBrowser: homeownerApplicationCompletedBrowser,
    /**
     * Fires a 'HomeownerCalculatorInteractionOccurred' track call.
     *
     * @param HomeownerCalculatorInteractionOccurred props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerCalculatorInteractionOccurred: homeownerCalculatorInteractionOccurred,
    /**
     * Fires a 'HomeownerInvestmentRundownInteractionOccurred' track call.
     *
     * @param HomeownerInvestmentRundownInteractionOccurred props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerInvestmentRundownInteractionOccurred: homeownerInvestmentRundownInteractionOccurred,
    /**
     * Fires a 'HomeownerInvestmentRundownViewed' track call.
     *
     * @param HomeownerInvestmentRundownViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerInvestmentRundownViewed: homeownerInvestmentRundownViewed,
    /**
     * Fires a 'HomeownerOfferOutcomeSetAcceptedBrowser' track call.
     *
     * @param HomeownerOfferOutcomeSetAcceptedBrowser props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerOfferOutcomeSetAcceptedBrowser: homeownerOfferOutcomeSetAcceptedBrowser,
    /**
     * Fires a 'HomeownerPreQualificationOutcomeFormSubmitted' track call.
     *
     * @param HomeownerPreQualificationOutcomeFormSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerPreQualificationOutcomeFormSubmitted: homeownerPreQualificationOutcomeFormSubmitted,
    /**
     * Fires a 'HomeownerTrackCreatedBrowser' track call.
     *
     * @param HomeownerTrackCreatedBrowser props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homeownerTrackCreatedBrowser: homeownerTrackCreatedBrowser,
    /**
     * Fires a 'HouseCanaryUpdateLienInformation' track call.
     *
     * @param HouseCanaryUpdateLienInformation props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    houseCanaryUpdateLienInformation: houseCanaryUpdateLienInformation,
    /**
     * Fires a 'MaritalStateTodoCompleted' track call.
     *
     * @param MaritalStateTodoCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    maritalStateTodoCompleted: maritalStateTodoCompleted,
    /**
     * Fires a 'MaritalStateTodoDismissed' track call.
     *
     * @param MaritalStateTodoDismissed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    maritalStateTodoDismissed: maritalStateTodoDismissed,
    /**
     * Fires a 'MaritalStateTodoViewDetailsClicked' track call.
     *
     * @param MaritalStateTodoViewDetailsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    maritalStateTodoViewDetailsClicked: maritalStateTodoViewDetailsClicked,
    /**
     * Fires a 'OnboardingCTAHigher' track call.
     *
     * @param OnboardingCTAHigher props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    onboardingCTAHigher: onboardingCTAHigher,
    /**
     * Fires a 'PageViewed' track call.
     *
     * @param PageViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pageViewed: pageViewed,
    /**
     * Fires a 'SkipBuildProfile' track call.
     *
     * @param SkipBuildProfile props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    skipBuildProfile: skipBuildProfile,
    /**
     * Fires a 'SmartCardViewed' track call.
     *
     * @param SmartCardViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    smartCardViewed: smartCardViewed,
    /**
     * Fires a 'SmartCardsCompleted' track call.
     *
     * @param SmartCardsCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    smartCardsCompleted: smartCardsCompleted,
    /**
     * Fires a 'TappableEquityCardEligible' track call.
     *
     * @param TappableEquityCardEligible props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    tappableEquityCardEligible: tappableEquityCardEligible,
    /**
     * Fires a 'ToastShown' track call.
     *
     * @param ToastShown props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    toastShown: toastShown,
};
exports.default = new Proxy(clientAPI, {
    get: function (target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method];
        }
        return function () {
            console.warn("\u26A0\uFE0F  You made an analytics call (".concat(String(method), ") that can't be found. Either:\n         a) Re-generate your typewriter client: `npx typewriter`\n         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE"));
            var a = analytics();
            if (a) {
                a.track('Unknown Analytics Call Fired', {
                    method: method,
                }, withTypewriterContext());
            }
        };
    },
});
