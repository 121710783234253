import usePrimaryNav from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';
import PropTypes from 'prop-types';
import cx from 'classnames';

const NavImageAndTitle = ({ titleIndicator, title }) => {
  const { isSidebarMovingOrClosed, isMobile } = usePrimaryNav();

  return (
    <div className="relative flex w-full items-center gap-3 transition-all duration-500">
      <div className="-translate-x-px">{titleIndicator}</div>
      {title && (
        <div
          className={cx('w-full leading-[26px]', {
            'whitespace-nowrap': !isMobile && isSidebarMovingOrClosed,
          })}
        >
          {title}
        </div>
      )}
    </div>
  );
};

NavImageAndTitle.propTypes = {
  titleIndicator: PropTypes.node,
  title: PropTypes.node,
};

export default NavImageAndTitle;
