import React from 'react';
import { Container } from '@hometap/htco-components';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import InquiryOutcomeUnsuccessfulRiskBandAA from 'apps/progressive-inquiry/components/outcome/adverseAction/InquiryOutcomeUnsuccessfulRiskBandAA';
import InquiryOutcomeUnsuccessfulFicoScoreAA from 'apps/progressive-inquiry/components/outcome/adverseAction/InquiryOutcomeUnsuccessfulFicoScoreAA';

const InquiryAdverseActionRouter = () => {
  const location = useLocation();
  const { ficoScore, riskFailFactors = [], firstName, lastName } = location.state || {};
  const match = useRouteMatch();

  return (
    <Container align="center">
      <Container grid={7}>
        <Switch>
          <Route path={`${match.path}/risk-band`}>
            <InquiryOutcomeUnsuccessfulRiskBandAA riskFailFactors={riskFailFactors} />
          </Route>
          <Route path={`${match.path}/fico`}>
            <InquiryOutcomeUnsuccessfulFicoScoreAA
              ficoScore={ficoScore}
              firstName={firstName}
              lastName={lastName}
              riskFailFactors={riskFailFactors}
            />
          </Route>
        </Switch>
      </Container>
    </Container>
  );
};

export default InquiryAdverseActionRouter;
