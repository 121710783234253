import { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';
import AccessHomeEquityCTA from 'components/AccessHomeEquityCTA/AccessHomeEquityCTA';
import { useReferFriendCTA } from 'components/ReferFriendCTAButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

const usePrimaryNavItems = ({ isMobile, onLocationChange, home, userCanAccessHome }) => {
  const location = useLocation();
  const { showOverviewPage } = useFlags();
  const { shouldBeShown: showReferFriend, path: referFriendPath } = useReferFriendCTA();

  // There are certain pages where we want to show Home Equity Forecast as active
  const homeEquityBaseURL = `/dashboard/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`;

  const getActiveHomeEquityChildPages = () =>
    !!matchPath(location.pathname, {
      path: `${homeEquityBaseURL}/${HOMEOWNER_DASHBOARD.EDIT_PROFILE}`,
      exact: true,
    }) ||
    !!matchPath(location.pathname, {
      path: `${homeEquityBaseURL}/accept-liens`,
      exact: true,
    });

  const navLinks = [
    {
      path: '/dashboard/overview',
      title: 'Overview',
      icon: 'house-multifamily',
      visible: showOverviewPage,
      active: !!matchPath(location.pathname, { path: '/dashboard/overview', exact: true }),
    },
    {
      path: '/dashboard/investments',
      title: 'Investments',
      icon: 'compensation',
      visible: !!home.tracks?.length || !userCanAccessHome,
      active: !!matchPath(location.pathname, { path: '/dashboard/investments', exact: true }),
    },
    {
      path: '/dashboard/home-equity',
      title: 'Home equity forecast',
      icon: 'normal-appreciation',
      visible: userCanAccessHome,
      pageTitleHidden: getActiveHomeEquityChildPages(),
      active:
        !!matchPath(location.pathname, {
          path: homeEquityBaseURL,
          exact: true,
        }) || getActiveHomeEquityChildPages(),
    },
  ];

  const scenarioPlannerSection = {
    section: 'Scenario planner',
    icon: 'renovate',
    disabled: !home.hed_debts_confirmed,
    visible: userCanAccessHome,
    items: [
      {
        pageTitleClassName: 'mb-6',
        path: '/dashboard/home-equity/renovations',
        title: 'Renovation',
        active: !!matchPath(location.pathname, { path: '/dashboard/home-equity/renovations' }),
      },
      {
        pageTitleClassName: 'mb-6',
        path: '/dashboard/home-equity/goals',
        title: 'Financing',
        active:
          !!matchPath(location.pathname, { path: '/dashboard/home-equity/goals' }) ||
          !!matchPath(location.pathname, { path: '/dashboard/home-equity/finance' }),
      },
    ],
  };

  const resourcesSection = {
    section: 'Resources',
    icon: 'bookmark',
    items: [
      {
        path: '/dashboard/maintenance',
        title: 'Maintenance checklist',
        visible: userCanAccessHome,
        active: !!matchPath(location.pathname, { path: '/dashboard/maintenance', exact: true }),
      },
      {
        path: '/dashboard/resources',
        title: 'Articles',
        active: !!matchPath(location.pathname, { path: '/dashboard/resources', exact: true }),
      },
      {
        pageTitleClassName: 'mb-6',
        path: '/dashboard/featured-offers',
        title: 'Featured Offers',
        active: !!matchPath(location.pathname, { path: '/dashboard/featured-offers', exact: true }),
      },
    ],
  };

  const mobileAccountSection = {
    section: 'Account',
    visible: isMobile,
    extraContent: () => (
      <AccessHomeEquityCTA
        onClick={onLocationChange}
        className="m-0 mt-3 w-full p-0 !text-base xs:mx-auto xs:w-1/2"
        size="large"
      />
    ),
    icon: 'avatar-1',
    items: [
      {
        path: '/profile',
        title: 'Account settings',
        active: !!matchPath(location.pathname, { path: '/profile', exact: true }),
      },
      {
        path: '/support',
        title: 'Get help',
        active: !!matchPath(location.pathname, { path: '/support', exact: true }),
      },
      {
        visible: showReferFriend,
        path: referFriendPath,
        title: 'Refer a friend',
        active: !!matchPath(location.pathname, { path: referFriendPath, exact: true }),
      },
      {
        path: '/logout',
        title: 'Log out',
        active: false,
      },
    ],
  };

  const isNavItemVisible = item => item.visible ?? true;
  const getActiveSection = section => section?.items.some(item => item.active);
  const filterInvisibleSectionsAndLinks = section =>
    section
      .filter(isNavItemVisible)
      .map(section => ({ ...section, items: section.items.filter(isNavItemVisible) }))
      .filter(({ items }) => items.length);
  const visibleNavLinks = navLinks.filter(isNavItemVisible);

  const navSectionGroups = [[scenarioPlannerSection, resourcesSection], [mobileAccountSection]];
  const visibleGroups = navSectionGroups.map(filterInvisibleSectionsAndLinks).filter(item => item.length);

  const activeSection = visibleGroups.flat().find(getActiveSection);

  const [openSection, setOpenSection] = useState(activeSection?.section || null);
  const previousLocation = useRef(null);

  useEffect(() => {
    if (location.pathname !== previousLocation.current) {
      onLocationChange(location.pathname);

      if (!activeSection) {
        setOpenSection(null);
      }
    }

    previousLocation.current = location.pathname;
  }, [location, onLocationChange, activeSection]);

  const activePage = activeSection?.items.find(item => item.active) || navLinks.find(link => link.active);

  return {
    navLinks: visibleNavLinks,
    navSectionGroups: visibleGroups,
    openSection,
    onToggleOpenSection: setOpenSection,
    activePage,
  };
};

export default usePrimaryNavItems;
