import { DQ_PAGES } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';

const PROPERTY_LOCATION_DQ_HEADER = {
  [DQ_PAGES.LOCATION]: 'We’re not making investments in your area, yet',
  [DQ_PAGES.COOP]: 'We’re not making investments in co-ops, yet',
  [DQ_PAGES.MOBILE_HOME]: 'We’re not making investments in mobile homes, yet',
};

const PropertyLocationFailHeader = ({ reason }) => {
  return (
    <h1 className="DQHeader" data-testid="dq-header">
      {PROPERTY_LOCATION_DQ_HEADER[reason]}
    </h1>
  );
};

export default PropertyLocationFailHeader;
