import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';

import ResourcesSection from './ResourcesSection';
import {
  FEATURED_RESOURCE,
  FEATURED_RESOURCES_LIST,
  FINANCING_MY_GOALS_LIST,
  GROW_MY_HOME_VALUE_AND_EQUITY_LIST,
  PROTECT_MY_INVESTMENT_LIST,
} from 'apps/dashboard/content/resourceArticles';
import FeaturedResources from './FeaturedResources';
import Footer from 'components/Footer/Footer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import './ResourcesController.scss';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

const ResourcesController = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path={getNewDashboardUrl(HOMEOWNER_DASHBOARD.RESOURCES)}>
          <PrimaryLayoutGrid className="ResourcesControllerFeaturedResources">
            <div className="col-span-full">
              <h2 className="ResourcesControllerHeader"> Featured resources </h2>
              <FeaturedResources featuredResource={FEATURED_RESOURCE} resourcesList={FEATURED_RESOURCES_LIST} />
            </div>
          </PrimaryLayoutGrid>

          <div className="ResourcesControllerSectionWrapper bg-blue-dark-100">
            <ResourcesSection title="Financing my goals" resources={FINANCING_MY_GOALS_LIST} />
            <ResourcesSection title="Grow my home value and equity" resources={GROW_MY_HOME_VALUE_AND_EQUITY_LIST} />
            <ResourcesSection title="Protect my investment" resources={PROTECT_MY_INVESTMENT_LIST} />
            <PrimaryLayoutGrid>
              <Footer theme="dark" className="col-span-full mt-26" />
            </PrimaryLayoutGrid>
          </div>
        </Route>
      </Switch>
    </ScrollToTop>
  );
};

export default ResourcesController;
