import React, { useEffect } from 'react';
import { NotFoundBlock } from '@hometap/htco-components';
import { Switch, Route } from 'react-router-dom';
import { Routes, CompatRoute, Route as RouteV6, useLocation, Navigate, Outlet } from 'react-router-dom-v5-compat';
import { getDashboardUrl, getLogoutUrl, getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import RundownController from './apps/rundowns/RundownController';
import PartnerInquiryController from './apps/partner/PartnerInquiryController';
import OfferController from './apps/offers/OfferController';
import SettlementCalculatorController from './apps/settlement/SettlementCalculatorController';
import { browserTrackPage } from 'utils/segment';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import PrivateRouteV6 from 'components/PrivateRoute/PrivateRouteV6';
import PrimaryLayout from 'components/Layouts/PrimaryLayout/PrimaryLayout';
import ReInquiryRouter from 'apps/re-inquiry/ReInquiryRouter';
import InquiryAdverseActionRouter from 'apps/progressive-inquiry/components/outcome/adverseAction/InquiryAdverseActionRouter';
import TrackDetailsRouter from 'components/TrackDetailsRouter/TrackDetailsRouter';
import { TrackProvider } from 'hooks/useTrack';
import FitQuiz from './apps/htdc/fit-quiz/FitQuiz';
import FitQuizResults from './apps/htdc/fit-quiz/FitQuizResults';
import DebtCalculator from 'apps/htdc/debt-calculator/DebtCalculator';
import DashboardProfile from 'apps/dashboard/components/dashboard-profile/DashboardProfile';
import DashboardSupport from 'apps/dashboard/components/dashboard-support-page/DashboardSupport';
import EquityPlannerRouter from 'apps/dashboard/components/equity-planner/EquityPlannerRouter';
import CommonLayout from 'components/Layouts/CommonLayout';
import ProgressiveInquiryController from 'apps/progressive-inquiry/ProgressiveInquiryController';
import ModalPageLayout from 'components/Layouts/ModalPageLayout/ModalPageLayout';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import { HomeValuationProvider } from 'apps/dashboard/hooks/useHomeValuation';

import { PrimaryNavProvider } from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';
import OverviewController from 'apps/dashboard/components/overview-controller/OverviewController';
import InvestmentController from 'apps/dashboard/components/investment-controller/InvestmentController';
import HomeEquityController from 'apps/dashboard/components/home-equity-controller/HomeEquityController';
import FeaturedOffersController from 'apps/dashboard/components/featured-offers-controller/FeaturedOffersController';
import { HedInvestmentProvider } from 'apps/dashboard/hooks/useHedInvestmentContext';
import { EquityScenarioContextProvider } from 'apps/dashboard/hooks/useEquityScenarioContext';

import MaintenanceController from 'apps/dashboard/components/maintenance-controller/MaintenanceController';
import ResourcesController from './apps/dashboard/components/resources-controller/mainTab/ResourcesController';
import TrackDetailsRouterV6 from './components/TrackDetailsRouter/TrackDetailsRouterV6';
import TrackDetailsProviderWrapper from './components/TrackDetailsRouter/TrackDetailsProviderWrapper';
import TrackSecondaryLayout from 'components/Layouts/SecondaryLayout/TrackSecondaryLayout';
import ResourcesPost from 'apps/dashboard/components/resources-controller/post/ResourcesPost';
import SecondaryLayout from 'components/Layouts/SecondaryLayout/SecondaryLayout';
import useHEDRedirect from 'hooks/useHEDRedirect';
import { useFlags } from 'launchdarkly-react-client-sdk';
const AppRouter = () => {
  const location = useLocation();
  const { showOverviewPage } = useFlags();

  useEffect(() => {
    browserTrackPage();
  }, [location]);

  useHEDRedirect();

  return (
    <Switch>
      <Route path="/dashboard">
        <Routes>
          <RouteV6
            path={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}/:postTitle`}
            element={
              <PrivateRouteV6>
                <SecondaryLayout prevUrl={`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.RESOURCES}`}>
                  <ResourcesPost />
                </SecondaryLayout>
              </PrivateRouteV6>
            }
          />
        </Routes>
        <HomeValuationProvider>
          <HedInvestmentProvider>
            <PrimaryNavProvider>
              <Routes>
                <RouteV6
                  path="dashboard"
                  element={
                    <PrivateRouteV6>
                      <PrimaryLayout>
                        <Outlet />
                      </PrimaryLayout>
                    </PrivateRouteV6>
                  }
                >
                  <RouteV6
                    index
                    element={
                      <Navigate
                        to={getNewDashboardUrl(
                          showOverviewPage ? HOMEOWNER_DASHBOARD.OVERVIEW : HOMEOWNER_DASHBOARD.INVESTMENTS,
                        )}
                      />
                    }
                  />
                  <RouteV6
                    path="overview"
                    element={
                      <EquityScenarioContextProvider>
                        <OverviewController />
                      </EquityScenarioContextProvider>
                    }
                  />
                  <RouteV6 path="investments" element={<InvestmentController />} />
                  <RouteV6
                    path="home-equity/*"
                    element={
                      <EquityScenarioContextProvider>
                        <HomeEquityController />
                      </EquityScenarioContextProvider>
                    }
                  />
                  <RouteV6
                    path="maintenance"
                    element={
                      <PrimaryLayoutGrid>
                        <MaintenanceController />
                      </PrimaryLayoutGrid>
                    }
                  />
                  <RouteV6 path="featured-offers" element={<FeaturedOffersController />} />
                  <RouteV6 path="resources" element={<ResourcesController />} />
                </RouteV6>
              </Routes>
            </PrimaryNavProvider>
          </HedInvestmentProvider>
        </HomeValuationProvider>
      </Route>

      {/* IMPORTANT: Routes with custom layouts need remain above the CommonLayout routes (below). */}

      <PrivateRoute path="/track/:trackId">
        {
          // If there is a route that is track based it probably best to put it in the track details
          // router. This will allow useTrack() to be used without any further configuration
        }
        <TrackDetailsProviderWrapper>
          <TrackDetailsRouter />
          <TrackDetailsRouterV6 />
        </TrackDetailsProviderWrapper>
      </PrivateRoute>

      {
        // IMPORTANT: Whenever a Homeowner views a Rundown/Offer, make sure we make a POST to
        // `/api/v1/{rundowns|offers}/{id}/actions/view/`. This is necessary for legal audit trail
        // reasons.
      }
      <PrivateRoute path="/rundown/:id">
        <TrackProvider>
          <ModalPageLayout>
            <RundownController />
          </ModalPageLayout>
        </TrackProvider>
      </PrivateRoute>
      <PrivateRoute path="/offer/:id?">
        <TrackProvider>
          <ModalPageLayout>
            <OfferController />
          </ModalPageLayout>
        </TrackProvider>
      </PrivateRoute>

      <PrivateRoute exact path="/profile">
        <SecondaryLayout prevUrl={HOMEOWNER_DASHBOARD.ROOT} allowAccountDropdown={true}>
          <DashboardProfile />
        </SecondaryLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/support">
        <SecondaryLayout prevUrl={HOMEOWNER_DASHBOARD.ROOT} allowAccountDropdown={true}>
          <DashboardSupport />
        </SecondaryLayout>
      </PrivateRoute>
      <PrivateRoute path="/investments/:id/settlement-calculator">
        <TrackProvider>
          <TrackSecondaryLayout prevTrackUrl={`/`}>
            <SettlementCalculatorController />
          </TrackSecondaryLayout>
        </TrackProvider>
      </PrivateRoute>
      <PrivateRoute path="/re-inquiry/:identifier">
        <SecondaryLayout prevUrl={HOMEOWNER_DASHBOARD.ROOT}>
          <ReInquiryRouter />
        </SecondaryLayout>
      </PrivateRoute>

      <Route path="/">
        <Route
          path="/logout"
          render={() => {
            window.location.href = getLogoutUrl();
            return null;
          }}
        />
        <CommonLayout>
          <Switch>
            <CompatRoute path="/inquiries/:id/convert" exact>
              <PartnerInquiryController />
            </CompatRoute>
            <CompatRoute path="/inquiry">
              <ProgressiveInquiryController />
            </CompatRoute>
            <Route path="/adverse-action">
              <InquiryAdverseActionRouter />
            </Route>
            <CompatRoute path="/equity-planner">
              <EquityPlannerRouter />
            </CompatRoute>
            <CompatRoute path="/fit-quiz-results">
              <FitQuizResults />
            </CompatRoute>
            <CompatRoute path="/fit-quiz" exact>
              <FitQuiz />
            </CompatRoute>
            <CompatRoute path="/debt-calculator" exact>
              <DebtCalculator />
            </CompatRoute>
            <CompatRoute path="/refer-a-friend-hed" exact>
              {/* Friendbuy injects the iframe content into the div by id. (PAT-758) */}
              <div id="friendbuyhedlandingpage" />
            </CompatRoute>
            <CompatRoute path="/refer-a-friend" exact>
              {/* Friendbuy injects the iframe content into the div by id. (HOOT-1612) */}
              <div id="friendbuylandingpage" />
            </CompatRoute>
            <CompatRoute path="/friends" exact>
              {/* Friendbuy injects the iframe content into the div by id. (HOOT-1612) */}
              <div id="friendbuyfriendpage" />
            </CompatRoute>
            <CompatRoute path="/" exact>
              {() => {
                const currentParams = window.location.search;
                const redirectUrl = `${getDashboardUrl()}${currentParams}`;
                return <Navigate to={redirectUrl} />;
              }}
            </CompatRoute>
            <CompatRoute path="*" exact>
              <div style={{ paddingTop: '50px' }}>
                <NotFoundBlock buttonLink="/" />
              </div>
            </CompatRoute>
          </Switch>
        </CommonLayout>
      </Route>
    </Switch>
  );
};

export default AppRouter;
