import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { NotFoundBlock, useAsync } from '@hometap/htco-components';

import { fetchInquiries } from '../data/reInquiryRequests';
import ReInquiryContentContainer from './ReInquiryContentContainer';
import ReInquiryReviewConsentForm from './ReInquiryReviewConsentForm';
import ReInquiryReviewError from './ReInquiryReviewError';

import { formatFullName } from 'utils/stringFormatters';
import { scrollTop } from 'utils/domScripts';
import ReInquiryReviewFields from './ReInquiryReviewFields';

const ReInquiryReview = () => {
  const [errorStatus, setErrorStatus] = useState();
  const { identifier: addressKey } = useParams();
  const { loading, results: inquiries = [] } = useAsync(fetchInquiries, {
    immediate: true,
  });

  useEffect(() => {
    if (errorStatus) {
      scrollTop();
    }
  }, [errorStatus]);

  let inquiryToReview = { address: {} };
  const foundInquiry = [...inquiries]
    // inquiries are sorted by "created_at" asc and we want the most recent
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .find(inquiry => inquiry.address.business_key === addressKey);
  if (foundInquiry) {
    inquiryToReview = {
      first_name: foundInquiry.first_name,
      last_name: foundInquiry.last_name,
      email: foundInquiry.email,
      phone_number: foundInquiry.phone_number,
      primary_use_case: foundInquiry.primary_use_case,
      when_interested: foundInquiry.when_interested,
      residence_type: foundInquiry.residence_type,
      consent_given: foundInquiry.consent_given,
      property_type: foundInquiry.property_type,
      address: {
        street: foundInquiry.address?.street,
        unit: foundInquiry.address?.unit,
        city: foundInquiry.address?.city,
        state: foundInquiry.address?.state,
        zip_code: foundInquiry.address?.zip_code,
      },
      mortgage_balance: foundInquiry.mortgage_balance,
    };
  }

  let reviewContentOr404;
  if (!loading && !Object.keys(inquiryToReview.address).length) {
    reviewContentOr404 = <NotFoundBlock buttonLink="/dashboard" buttonLabel="Back to Dashboard" />;
  } else {
    reviewContentOr404 = (
      <div className="ReInquiryReview">
        <div>
          <div>
            <h3>Please review the following information</h3>
            {errorStatus && <ReInquiryReviewError status={errorStatus} />}
            <div className="ReInquiryReviewSubHeading">
              <p>
                Please make sure the following information is correct. We’ll use these details to reopen your
                application.
              </p>
            </div>
          </div>

          <h4 className="ReInquiryReviewFullName">{formatFullName(inquiryToReview)}</h4>
          <hr />

          <div className="ReInquiryReviewDataContainer">
            <ReInquiryReviewFields inquiry={inquiryToReview} />
          </div>
          <ReInquiryReviewConsentForm
            originalInquiryId={foundInquiry?.id}
            initialFormData={inquiryToReview}
            onError={setErrorStatus}
          />
        </div>
      </div>
    );
  }

  return (
    <ReInquiryContentContainer loading={loading} results={inquiries} className="ReInquiryReview">
      {reviewContentOr404}
    </ReInquiryContentContainer>
  );
};

export default ReInquiryReview;
