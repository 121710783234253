import React from 'react';
import { Helmet } from 'react-helmet-async';
import ModalPageHeaderLite from './ModalPageHeaderLite';

// TODO: (PAT-430) consolidate modal page layouts
const ModalPageLayoutLite = ({ onModalClose, headerContent, children }) => {
  return (
    <>
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} />
      <ModalPageHeaderLite onClose={onModalClose}>{headerContent}</ModalPageHeaderLite>
      <main>{children}</main>
    </>
  );
};

export default ModalPageLayoutLite;
