const useStackedBarGraph = data => {
  const valuesTotal = Object.values(data).reduce((acc, { value, hidden }) => (hidden ? acc : acc + Number(value)), 0);

  const formattedData = Object.entries(data)
    .map(([key, data]) => [
      key,
      {
        ...data,
        chartKey: key,
        percent: valuesTotal ? (Number(data.value) / valuesTotal) * 100 : null,
      },
    ])
    .reduce((accum, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});

  const isSliceHidden = key => {
    return !!(!data[key] || data[key]?.hidden);
  };

  const getSliceHeight = (key, chartHeight) => {
    return (chartHeight * formattedData[key].percent) / 100;
  };

  const getSliceData = (key, chartHeight) => {
    if (isSliceHidden(key)) {
      return { hidden: true, chartKey: key };
    }

    if (chartHeight) {
      return { ...formattedData[key], height: getSliceHeight(key, chartHeight) };
    }

    return formattedData[key];
  };

  return { valuesTotal, getSliceData, getSliceHeight, isSliceHidden };
};

export default useStackedBarGraph;
