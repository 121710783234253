import React from 'react';
import ContactInfo from './contact-info/ContactInfo';

const InvestmentSupportCards = ({ contacts = [] }) => {
  return contacts.map(contact => (
    <ContactInfo
      key={contact.friendly_id || contact.email || contact.phone_number}
      friendly_id={contact.friendly_id || ''}
      card_title={contact.card_title}
      contact_information={contact.contact_information}
      card_description={contact.card_description}
    />
  ));
};

export default InvestmentSupportCards;
