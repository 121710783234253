import TrackDetailsCardLinks from 'components/TrackDetailsCard/TrackDetailsCardLinks';
import TrackDetailsProgressStepper from 'apps/track-details/components/TrackDetailsProgressStepper/TrackDetailsProgressStepper';
import { capitalize } from 'lodash';
import { isTrackFunded } from 'apps/track-details/utils/helpers';
import { currency } from 'utils/numbers';
import { getCurrentUIStageData } from 'components/TrackDetailsCard/uiStageModel';
import TrackDetailsKeyValues from '../TrackDetailsCard/TrackDetailsKeyValues';

import useInvestmentStatementList from 'apps/quarterly-statement/hooks/useInvestmentStatementList';
import useTrackDocuments from 'apps/documents/hooks/useTrackDocuments';
import useTrackInvestment from 'hooks/useTrackInvestment';

const TrackDetailCardMainContent = ({ track }) => {
  const { config, lastActiveStageIndex, allUIProgressStepperStages, canShowProgressStepper } =
    getCurrentUIStageData(track);
  const { statements } = useInvestmentStatementList({ track, investmentId: track.active_investment_id });
  const { data: investment } = useTrackInvestment({ investmentId: track.active_investment_id });
  const { hasUnreadDocs } = useTrackDocuments({ trackId: track?.id });

  const { id, friendly_id, investment_amount, membership } = track;
  const cardData = config.getCardData({ track, investmentSummary: investment, statements, hasUnreadDocs });

  const { links } = cardData;

  const isPrimaryMember = membership.role === 'primary';
  const showLinksAndAvatarSection = isPrimaryMember && !!links.length;

  const cta = {
    copy: isTrackFunded(track) ? 'View details' : 'Check status',
    link: isPrimaryMember ? `/track/${id}` : `/track/${id}/co-applicant`,
  };

  const { label: timeLabel, date } = cardData.timeStatus;
  const { label: stageLabel, theme } = cardData.badge;

  const trackKeyValues = [
    { key: timeLabel, value: date },
    {
      key: config.investmentAmountLabel,
      value: investment_amount ? currency(investment_amount) : 'More info needed',
    },
  ];

  return (
    <>
      {canShowProgressStepper && (
        <TrackDetailsProgressStepper
          isCardView
          stepIndicatorClassName="!p-1"
          containerClassName="mb-6"
          stepLabelClassName="hidden"
          steps={allUIProgressStepperStages}
          activeIndex={lastActiveStageIndex}
          tooltipData={step => ({
            content: capitalize(step),
            position: 'bottom',
            action: 'hover',
          })}
        />
      )}

      <TrackDetailsKeyValues
        cta={cta}
        badge={{ label: stageLabel, theme }}
        keyValues={trackKeyValues}
        header={{ key: 'Investment ID:', value: friendly_id }}
      />

      {showLinksAndAvatarSection && <TrackDetailsCardLinks links={links} />}
    </>
  );
};

export default TrackDetailCardMainContent;
