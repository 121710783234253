import React from 'react';
import { Icon } from '@hometap/htco-components';
import { Link } from 'react-router-dom-v5-compat';

const QuarterlyStatementResourceCard = ({ resource, investmentId }) => {
  const resourceLink = typeof resource.linkUrl === 'function' ? resource.linkUrl(investmentId) : resource.linkUrl;

  return (
    <div className="flex flex-col items-center gap-6 rounded-[12px] bg-white p-10 pb-6 text-center">
      <img className="h-[144px] w-[144px]" src={resource.image} alt={resource.title} />
      <div>
        <h4 className="mb-2">{resource.title}</h4>
        <div>{resource.subTitle}</div>
      </div>
      <div className="mt-auto w-full border-0 border-t border-solid border-neutral-light-100 pt-6">
        <Link className="flex items-center justify-center gap-2 font-bold" to={resourceLink}>
          <div>{resource.linkTitle}</div>
          <span className="no-underline">
            <Icon name="arrow-forward" />
          </span>
        </Link>
      </div>
    </div>
  );
};

const QuarterlyStatementResourcesSection = ({ title = '', subTitle = '', resources = [], investmentId }) => {
  return (
    <div className="py-20 md:py-26">
      {title && <h2 className="mb-2 text-3xl/[1.5] text-white">{title}</h2>}
      {subTitle && <div className="text-white">{subTitle}</div>}
      <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {resources.map((resource, i) => (
          <QuarterlyStatementResourceCard
            key={`${resource.title}-${i}`}
            resource={resource}
            investmentId={investmentId}
          />
        ))}
      </div>
    </div>
  );
};

export default QuarterlyStatementResourcesSection;
