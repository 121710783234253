import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';
import moment from 'moment';
import { Container, Grid, Paper, useBoolState } from '@hometap/htco-components';
import HomeEquityCard from 'components/HomeEquityCard/HomeEquityCard';
import HomeEquityCardBlank from 'components/HomeEquityCard/HomeEquityCardBlank';
import { Content } from 'apps/dashboard/components/home-equity-controller/visx-chart/ValueTooltipInfo';
import EditHomeValueModal from 'apps/dashboard/components/home-equity-controller/EditHomeValueModal';
import ScenarioEmptyPanel from 'apps/dashboard/components/home-equity-controller/visx-chart/ScenarioEmptyPanel';
import { TOOLTIP_VALUES } from 'apps/dashboard/constants';
import { getNewHEDUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { currency } from 'utils/numbers';
import { ValueChartPanel, ValueMissingPanel } from './visx-chart';
import PropertyDetailsPanel from 'apps/dashboard/components/home-equity-controller/userPanel/PropertyDetailsPanel';
import EmptyStatePanel from 'apps/dashboard/components/home-equity-controller/userPanel/EmptyStatePanel';
import EquityViewSelector from './equity-view-selector/EquityViewSelector';
import { useEquityScenarioContext } from 'apps/dashboard/hooks/useEquityScenarioContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAccessHomeEquityCTA from 'apps/dashboard/utils/useAccessHomeEquityCTA';
import { buildHomeProfileUrl } from 'utils/links';
import EquityScenarioCard from './EquityScenarioCard';
import { buildNewHEDUrl } from 'utils/links';
import { getRenovationSum } from 'apps/dashboard/utils/dataUtils';
import NewValueAvailableModal from './NewValueAvailableModal';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import { showRenovation, hideRenovation, getVisibleRenovations } from 'apps/dashboard/utils/renovationUtils';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';

const HomeEquityHedData = ({ currentEquity, tappableEquity, homeValueMissing, updateSmartFacts }) => {
  const { home } = useCurrentHome();
  const { displayedHomeValuation, homeValuation, selfReportedHomeValuation } = useHomeValuation();
  const homeValue = displayedHomeValuation?.value;
  const navigate = useNavigate();

  const { showTappableEquityCard, tappableEquityPosition } = useFlags();
  const editHomeValueModalOn = useBoolState(false);

  const { currentScenario, chartForecast, isFutureView, hideChartPanel, futureScenario, initialScenario } =
    useEquityScenarioContext();
  const { investment } = useHedInvestmentContext();
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const [showHEI, setShowHEI] = useState(true);
  const [showFutureHEI, setShowFutureHEI] = useState(true);

  // Total equity including sum of visible renovations
  const renovations = currentScenario?.renovations;
  const [totalEquity, setTotalEquity] = useState(currentEquity + getRenovationSum(renovations));

  // Total home value including sum of visible renovations
  const [totalHomeVal, setTotalHomeVal] = useState(homeValue + getRenovationSum(renovations));
  const currentYear = new Date().getFullYear();
  const forecastYear = parseInt(currentYear) + parseInt(chartForecast);

  const forecastedData =
    currentScenario.valuations?.forecastedValues?.find(valuation => valuation?.year === forecastYear) ?? {};
  const { forecastedHomeVal, forecastedEquity } = forecastedData;

  const emptyFutureState = isFutureView && !futureScenario?.id;
  const showHomeEquityCards = !emptyFutureState && !homeValueMissing;
  const liens = currentScenario?.liens;
  const [futureInvestment, setFutureInvestment] = useState();
  const { hasTappableEquity, openAccessEquityWindow } = useAccessHomeEquityCTA();

  useEffect(() => {
    const futureHEI = liens?.find(lien => lien.lien_type === 'home_equity_investment');
    if (futureHEI) {
      const originationDate = new Date();
      const maturityDate = new Date(futureHEI.origination_date);
      maturityDate.setMonth(maturityDate.getMonth() + futureHEI.term_length_in_months);
      const heiData = {
        effective_date: originationDate.toISOString().slice(0, 10),
        maturity_date: maturityDate.toISOString().slice(0, 10),
        investment_amount: futureHEI?.original_balance,
        beginning_home_value: homeValue,
        share_up_fraction: futureHEI?.share_up_fraction,
        share_down_fraction: futureHEI.share_down_fraction,
      };
      setFutureInvestment(heiData);
    }
  }, [liens, homeValue]);

  useEffect(() => {
    const filteredRenos = getVisibleRenovations(currentScenario);
    if (currentEquity) {
      setTotalEquity(currentEquity + getRenovationSum(filteredRenos));
    }

    if (homeValue) {
      setTotalHomeVal(homeValue + getRenovationSum(filteredRenos));
    }
  }, [currentScenario, currentEquity, homeValue, renovations]);

  useEffect(() => {
    if (hasTappableEquity && showTappableEquityCard) {
      // Fire a segment event to track which position the card is in when the page loads
      browserTrack.tappableEquityCardEligible({
        position: tappableEquityPosition,
        version: getVersion(),
      });
    }
  }, [hasTappableEquity, showTappableEquityCard, tappableEquityPosition]);

  const handleNavToRenovations = () => {
    navigate(buildNewHEDUrl({ renovations: true }));
  };

  const financeMyGoalsCtaClick = (e, financeOption) => {
    if (financeOption) {
      // If editing a specific finance option, skip the goals page and route to the finance calculator
      return navigate('/dashboard/home-equity/finance', { option: financeOption });
    }

    navigate(getNewHEDUrl('/goals'));
  };

  const isSelfReportedHomeValuation = displayedHomeValuation?.home_valuation_type === 'SelfReportedHomeValuation';
  const tooltipContentKey = isFutureView ? 'future' : 'today';

  const getHomeValTooltips = () => {
    const { header, content } = isSelfReportedHomeValuation
      ? TOOLTIP_VALUES.HOME_VAL_MANUAL[tooltipContentKey]
      : TOOLTIP_VALUES.HOME_VAL_AVM[tooltipContentKey];
    // TODO: AVM isn't the only source of home value, so this should be updated to reflect that
    const valuationType = isSelfReportedHomeValuation ? 'Manual value' : 'AVM';
    const footer = `${valuationType} last updated: ${moment(asOfDate).format('MM/DD/YYYY')}`;

    return { header, content, footer };
  };

  const asOfDate = displayedHomeValuation?.as_of_date || moment();
  const homeValToolTips = getHomeValTooltips();
  const equityToolTips = TOOLTIP_VALUES.EQUITY[tooltipContentKey];

  const renderChartView = () => {
    const visibleRenovations = getVisibleRenovations(futureScenario);
    if (emptyFutureState) {
      return <ScenarioEmptyPanel />;
    }

    if (setupProfileLater) {
      return (
        <Container className="ValueScenario" row respondAt="sm">
          <ValueChartPanel />
          <EmptyStatePanel />
        </Container>
      );
    }

    if (hideChartPanel) {
      // if its today's view and the user has no liens do not render PropertyDetailsPanel
      return (
        <Container className="ValueScenario" row respondAt="sm">
          <ValueChartPanel />
        </Container>
      );
    }

    return (
      <Container className="ValueScenario" row respondAt="sm">
        <ValueChartPanel
          futureInvestment={futureInvestment}
          panelHidden={hideChartPanel}
          showRenovations={visibleRenovations?.length > 0}
          showHEI={isFutureView ? showFutureHEI : showHEI}
        />
        <PropertyDetailsPanel
          liens={liens}
          renovations={renovations}
          investment={investment}
          toggleChartRenovations={toggleRenovationCard}
          toggleChartHEI={show => (isFutureView ? setShowFutureHEI(show) : setShowHEI(show))}
          showHEI={isFutureView ? showFutureHEI : showHEI}
          exploreOptionsCtaClick={financeMyGoalsCtaClick}
        />
      </Container>
    );
  };

  const toggleRenovationCard = renovation => {
    const action = renovation.hidden ? showRenovation : hideRenovation;
    action(renovation);

    const currentScenario = isFutureView ? futureScenario : initialScenario;
    const visibleRenovations = getVisibleRenovations(currentScenario);

    const homeValWithReno = homeValue + getRenovationSum(visibleRenovations);
    const equityWithReno = currentEquity + getRenovationSum(visibleRenovations);

    setTotalEquity(equityWithReno);
    setTotalHomeVal(homeValWithReno);
  };

  const TappableEquityCard = () => {
    const showHomeEquityCard = !isFutureView && hasTappableEquity && showTappableEquityCard;
    return setupProfileLater ? (
      <HomeEquityCardBlank
        title="Tappable Equity"
        description="Add your debts to find out how much equity you can access"
        showLinkButton={false}
      />
    ) : (
      showHomeEquityCard && (
        <HomeEquityCard
          className="flex-shrink-0 flex-grow-0 basis-auto"
          theme="darkBlue"
          title="Tappable Equity"
          value={`Up to ${currency(tappableEquity)}`}
          ctaText="Learn how to access equity"
          toolTipContent={
            <Content header={TOOLTIP_VALUES.TAPPABLE_EQUITY.header} content={TOOLTIP_VALUES.TAPPABLE_EQUITY.content} />
          }
          onLinkButtonClick={openAccessEquityWindow}
        />
      )
    );
  };

  return (
    <div data-testid="dashboard-home-equity">
      <NewValueAvailableModal
        home={home}
        homeValuation={homeValuation}
        selfReportedHomeValuation={selfReportedHomeValuation}
      />
      {!homeValueMissing && <EquityViewSelector />}
      {showHomeEquityCards && (
        // negative margins are a hack to make this hit the edge of the screen on smaller screens
        // this is a temporary solution until we can refactor the layout to use a bleed out style
        <div className="-mr-4 flex flex-nowrap gap-2 overflow-x-auto py-6 pr-2 xs:gap-4 sm:-mr-6">
          <HomeEquityCard
            className="flex-shrink-0 flex-grow-0 basis-auto"
            theme="brightBlue"
            title={`Est. ${isFutureView ? 'future' : ''} Home Value`}
            value={currency(totalHomeVal)}
            forecastLabel={`${chartForecast}y forecast:`}
            forecastValue={currency(forecastedHomeVal)}
            ctaText="Edit home value"
            toolTipContent={
              <Content
                header={homeValToolTips.header}
                content={homeValToolTips.content}
                footer={homeValToolTips.footer}
              />
            }
            showLinkButton={!!selfReportedHomeValuation.is_editable}
            onLinkButtonClick={editHomeValueModalOn.setTrue}
          />
          {tappableEquityPosition && <TappableEquityCard />}
          {setupProfileLater ? (
            <HomeEquityCardBlank
              title="Est. Current Equity"
              description="Complete your profile to see your equity"
              showLinkButton={true}
              linkText="Finish set up"
              onLinkButtonClick={() => {
                navigate(buildHomeProfileUrl());
              }}
            />
          ) : (
            <HomeEquityCard
              className="flex-shrink-0 flex-grow-0 basis-auto"
              theme="purple"
              title={`Est. ${isFutureView ? 'future' : 'Current'} Equity`}
              value={currency(totalEquity)}
              forecastLabel={`${chartForecast}y forecast:`}
              forecastValue={currency(forecastedEquity)}
              ctaText="Protect my home"
              toolTipContent={<Content header={equityToolTips.header} content={equityToolTips.content} />}
              onLinkButtonClick={() => navigate(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.FEATURED_OFFERS}`)}
            />
          )}
          {!tappableEquityPosition && <TappableEquityCard />}
        </div>
      )}
      <div>
        <EditHomeValueModal
          on={editHomeValueModalOn.value}
          onClose={editHomeValueModalOn.setFalse}
          updateSmartFacts={updateSmartFacts}
        />
        <div className={`PlannerContainer DashboardPage  ${showHomeEquityCards ? '' : 'isHomeEquityCardsHidden'}`}>
          <Paper className="AppreciationSection">{homeValueMissing ? <ValueMissingPanel /> : renderChartView()}</Paper>
          {!homeValueMissing && (
            <>
              <h4 className="pb-4 pt-6 sm:pt-10">Explore home equity scenarios</h4>
              <Grid container rowSpacing={{ xs: 2, sm: 4 }} columnSpacing={3}>
                <Grid xs={12} sm={6}>
                  <EquityScenarioCard
                    title="Plan a renovation"
                    description="Learn how the quality and grade of certain types of renovations can increase your home’s value."
                    icon="paintbrush"
                    onClick={handleNavToRenovations}
                    data-testid="renovation-card"
                    disabled={!!setupProfileLater}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <EquityScenarioCard
                    title="Finance my goals"
                    description="Access the equity you have today, and find the right option to help you meet your goals."
                    icon="hand-money"
                    iconTheme="dark"
                    onClick={financeMyGoalsCtaClick}
                    data-testid="finance-goals-card"
                    disabled={!!setupProfileLater}
                  />
                </Grid>
              </Grid>
              <div className="AppreciationLegal">
                <p>
                  {investment
                    ? "The home value and Hometap's Share above are estimates and are not based on an appraisal of the home. The estimate of your equity is based on the home value estimate and information you provided, is to be used for illustrative purposes only, and is not intended as financial advice. Projected home value and Hometap Share are not guaranteed."
                    : "The home value above is an estimate of the home's market value; it is not an appraisal. The estimate of your equity is based on the home value estimate and information you provided, is to be used for illustrative purposes only, and is not intended as financial advice. Information regarding your mortgage is based on data you provided. Projected value is not guaranteed."}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

HomeEquityHedData.propTypes = {
  currentEquity: PropTypes.number,
};

export default HomeEquityHedData;
