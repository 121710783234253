import CardWrapper from '../CardWrapper';
import TrackDetailsCardMainContent from '../TrackDetailsCard/TrackDetailsCardMainContent';

const InvestmentCard = ({ track, className }) => {
  return (
    <CardWrapper className={className}>
      <TrackDetailsCardMainContent track={track} />
    </CardWrapper>
  );
};

export default InvestmentCard;
