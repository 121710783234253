import useCurrentUser from 'hooks/useCurrentUser';
import useSearchParams from 'hooks/useSearchParams';
import { getLoginUrl } from 'utils/links';

const PrivateRouteV6 = ({ children, redirect }) => {
  const query = useSearchParams();
  const { error } = useCurrentUser();

  const redirectTo = redirect || getLoginUrl();

  if (error?.response?.status === 403) {
    if (!query.has('next')) {
      query.append('next', window.location.pathname);
    }
    const queryString = query.toString() ? `?${query.toString()}` : '';
    const redirectWithQuery = `${redirectTo}${queryString}`;

    window.api.redirect(redirectWithQuery);
    return null;
  }

  return children;
};

export default PrivateRouteV6;
