import cx from 'classnames';
import CardWrapper from 'components/CardWrapper';
import TrackDetailCardMainContent from 'components/TrackDetailsCard/TrackDetailsCardMainContent';
import HomeValKeyValue from './HomeValKeyValue';

import HomeValueDisclaimer from './HomeValueDisclaimer';

import HomeValueAsOf from './HomeValueAsOf';
import FinancialSnapshotTypography from './FinancialSnapshotTypography';
import { getCurrentStageConfig } from 'apps/track-details/utils/helpers';
import { getHomeValuationDisclaimer } from 'utils/home';
import { USER_ROLES } from 'utils/globalConstants';

const OverviewTrackDetailsCard = ({ track, homeValuation }) => {
  const isPrimary = track.membership.role === USER_ROLES.PRIMARY;
  const disclaimer = getHomeValuationDisclaimer({ hasTracks: true, homeValuation }).short;

  const heading = isPrimary ? (
    <>
      <HomeValKeyValue homeValue={homeValuation.value} />
      <HomeValueAsOf date={homeValuation.as_of_date} />
    </>
  ) : (
    <>
      <FinancialSnapshotTypography />
      <HomeValueAsOf date={homeValuation.as_of_date} />
    </>
  );

  const subheading = isPrimary ? (
    <HomeValueDisclaimer disclaimer={disclaimer} hasTracks />
  ) : (
    <>
      <div>
        <HomeValKeyValue homeValue={homeValuation.value} />
      </div>
      <HomeValueDisclaimer disclaimer={disclaimer} />
    </>
  );

  const { canShowProgressStepper } = getCurrentStageConfig(track);

  return (
    <CardWrapper>
      <div className={cx({ 'mb-6 md:mb-8': canShowProgressStepper })}>
        <div className="mb-2 flex flex-wrap items-center justify-between gap-1">{heading}</div>
        {subheading}
      </div>

      {!canShowProgressStepper && <hr className="my-6" />}

      <TrackDetailCardMainContent track={track} />
    </CardWrapper>
  );
};

export default OverviewTrackDetailsCard;
