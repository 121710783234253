export const COLORS = {
  TEAL: 'teal',
  LIGHT_BLUE: 'lightBlue',
  PURPLE: 'purple',
  BLUE: 'blue',
};

export const COLOR_ORDER = [COLORS.TEAL, COLORS.PURPLE, COLORS.LIGHT_BLUE, COLORS.BLUE];

export const themeMap = {
  [COLORS.TEAL]: 'isTeal',
  [COLORS.LIGHT_BLUE]: 'isLightBlue',
  [COLORS.PURPLE]: 'isPurple',
  [COLORS.BLUE]: 'isBlue',
};

export const themeHighlightTextMap = {
  [COLORS.TEAL]: 'SmartCardGreenText',
  [COLORS.LIGHT_BLUE]: 'SmartCardAccessibleGreenText',
  [COLORS.PURPLE]: 'SmartCardPurpleText',
  [COLORS.BLUE]: 'SmartCardBlueText',
};
