import { useForm } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';

import { getApplicantFormKey } from '../utils';
import useApplicationContext from './useApplicationContext';
import { useEffect } from 'react';
import { APPLICANT_MODEL_FIELD } from '../constants/applicationDataFields';
import { omitBy, isEmpty } from 'lodash';

const getDirtyFields = (applicant, field, value) => {
  if (!applicant) return;
  if (!applicant?._dirtyFields) {
    applicant._dirtyFields = new Set();
  }

  const hasFieldChanged = value !== applicant[field];
  const watchedFields = Object.values(APPLICANT_MODEL_FIELD);
  hasFieldChanged && watchedFields.includes(field) && applicant?._dirtyFields.add(field);
  return [...applicant._dirtyFields];
};

const getUpdatedApplicantObj = (applicant, field, value) => {
  const changes = getDirtyFields(applicant, field, value);

  const updatedApplicant = {
    ...applicant,
    ...{ [field]: value },
    dirtyFields: changes?.length ? Object.freeze(changes) : null,
  };
  return updatedApplicant;
};

/**
 * Hook to be used to propagate applicant data to an application form using `registerApplicantField`.
 * This allows applicant data stored on the form from `ApplicationContext` to be stored with a key
 * of `applicant:<applicantId>` and a value of the running form data. Also runs validation for the
 * current form and propagates that data back up to the application form
 */
export const useApplicantForm = () => {
  const {
    registerField,
    handleFieldChange: handleApplicantFormFieldChange,
    isFormValid,
    updateFormData,
    errors,
    setErrors,
  } = useForm();
  const { applicationFormData, handleApplicationFormFieldChange } = useApplicationContext();
  const { applicantId } = useParams();

  const applicantFormKey = getApplicantFormKey(applicantId);
  const applicant = applicationFormData[applicantFormKey];
  const isApplicantFormValid = applicant?.isCurrentFormValid;

  const handleUpdateApplicantFormObject = (field, value) => {
    const updatedApplicant = getUpdatedApplicantObj(applicant, field, value);
    handleApplicationFormFieldChange(updatedApplicant, applicantFormKey);
  };

  useEffect(() => {
    const updatedApplicant = getUpdatedApplicantObj(applicant, 'isCurrentFormValid', isFormValid);
    const errorMsg = `This applicant has errors ${Object.keys(errors).join(',')}`;

    handleApplicationFormFieldChange(updatedApplicant, applicantFormKey, !isFormValid && errorMsg);
    updateFormData({ ...applicant });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid, isApplicantFormValid]);

  return {
    setErrors,
    applicantFormData: applicant,
    handleUpdateApplicantForm: (data = {}) => {
      const newApplicant = { ...data, ...omitBy(applicant, isEmpty) };
      updateFormData(newApplicant);
      handleApplicationFormFieldChange(newApplicant, applicantFormKey);
    },
    registerApplicantField: (name, valueProp = 'value') => {
      const baseRegisterField = registerField(name);
      return {
        ...baseRegisterField,
        onChange: value => {
          // set the data to the global application form
          handleUpdateApplicantFormObject(name, value);
          // set the form data for the current applicant form
          handleApplicantFormFieldChange(value, name);
        },
        [valueProp]: applicationFormData[applicantFormKey]?.[name],
      };
    },
    handleFieldChange: (value, name) => {
      // set the data to the global application form
      handleUpdateApplicantFormObject(name, value);
      // set the form data for the current applicant form
      handleApplicantFormFieldChange(value, name);
    },
  };
};

export default useApplicantForm;
