import sentry from 'utils/sentry';
import { useAsync } from '@hometap/htco-components';
import { createInquiry, patchInquiry } from '../api/inquiryRequests';
import { showNotification } from 'utils/toasts';

export const buildNotificationFieldErrorFromResponse = (data, notifications = []) => {
  let currentNotifications = notifications;
  if (typeof data == 'object') {
    Object.keys(data).forEach(key => {
      const value = data[key];
      if (Array.isArray(value)) {
        currentNotifications.push(`${key}: ${value.join(' ')}`);
        return;
      }
      if (typeof value === 'object') {
        currentNotifications = buildNotificationFieldErrorFromResponse(value, currentNotifications);
      }
    });
  }

  return currentNotifications;
};

const useAsyncWithErrorNotification = (axiosRequest, defaultSentryMessage, options) => {
  const useAsyncInstance = useAsync(axiosRequest, {
    onError: error => {
      if (error.response?.status === 400) {
        const { data } = error.response;
        const messages = buildNotificationFieldErrorFromResponse(data);

        if (messages?.length > 0) {
          showNotification({
            type: 'error',
            title: 'Error',
            description: messages.join(' '),
          });
        }
        return;
      }
      showNotification({
        type: 'error',
        title: 'Error',
        description: 'Something went wrong. Please refresh and try again.',
      });
      sentry.logErrorWrapper(defaultSentryMessage, error);
    },
    ...options,
  });

  return useAsyncInstance;
};

export const useAsyncCreateInquiry = (defaultSentryErrorMessage = 'Failed to create inquiry', options = {}) =>
  useAsyncWithErrorNotification(createInquiry, defaultSentryErrorMessage, options);

export const useAsyncPatchInquiry = (defaultSentryErrorMessage = 'Failed to patch inquiry', options = {}) =>
  useAsyncWithErrorNotification(patchInquiry, defaultSentryErrorMessage, options);
