const ADDRESS_ORDER = ['street', 'city', 'state', 'zip_code'];

/*
  utility to format google address string to object format:

  "2484 E Stella Dr, Eagle, ID 83616, USA"     ---->

  {
    "street": "2811 E Stella Dr",
    "city": "Eagle",
    "state": "ID",
    "zip_code": "83616"
  }
*/
export const parseGoogleAutocompleteString = address => {
  return address
    .split(',')
    .map(stateZip => stateZip.trim())
    .reduce((obj, cur, i) => {
      if (i === 2) {
        const [state, zip] = cur.split(' ');

        obj[ADDRESS_ORDER[i]] = state;
        obj[ADDRESS_ORDER[i + 1]] = zip;
        return obj;
      } else if (i < 2) {
        obj[ADDRESS_ORDER[i]] = cur;
        return obj;
      }
      return obj;
    }, {});
};

/*
  utility to format google address components to object format
*/
export const parseGoogleAutocompleteComponents = addressComponents => {
  const addressMap = {
    street_number: '',
    street: '',
    city: '',
    state: '',
    zip_code: '',
  };

  // Map of Google Places API types to our address object keys
  // The order of the keys in the map determines the order of the address object
  const typeToKeyMap = new Map([
    ['street_number', 'street_number'],
    ['route', 'street'],
    ['locality', 'city'],
    ['sublocality_level_1', 'city'],
    ['administrative_area_level_3', 'city'],
    ['administrative_area_level_1', 'state'],
    ['postal_code', 'zip_code'],
  ]);

  // Loop through the typeToKeyMap and addressComponents to populate the addressMap
  // If the addressMap already has a value for a key, don't overwrite it
  // This is particulary important for the city key, as the Google Places API has multiple types for city
  typeToKeyMap.forEach((value, key) => {
    addressComponents.forEach(address => {
      if (address.types.includes(key) && !addressMap[value]) {
        addressMap[value] = address.short_name;
      }
    });
  });

  // Right now there is no available option to limit the usePlacesWidget to only 5-digit zip codes.
  // The API returns a mix of 5-digit and 9-digit postal codes.
  const shortenedZipCode = addressMap.zip_code.slice(0, 5);
  addressMap.zip_code = shortenedZipCode;

  // If the street number is provided, prepend it to the street name
  if (addressMap.street_number && addressMap.street) {
    addressMap.street = `${addressMap.street_number} ${addressMap.street}`;
  }

  return addressMap;
};

// TODO: We can probably drop this as it is the same as utils/address.js formatInlineAddress
export const parseDataToAddressString = ({ street, city, state, zip_code }) => {
  return `${street}, ${city}, ${state} ${zip_code}`;
};
