import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Badge, Tooltip } from '@hometap/htco-components';
import { getFormattedPercent } from '../HomeGraph/utils';
import HomeGraphHatchOverlay from '../HomeGraph/HomeGraphHatchOverlay';

const StackedBarGraph = ({ children, className }) => {
  return (
    <div className={cx('flex', className)} data-testid="stacked-graph-container">
      {children}
    </div>
  );
};

const Chart = ({ children, className }) => {
  return (
    <div className={className} data-testid="stacked-graph-chart">
      <div className="mt-1.5 flex h-full flex-col overflow-hidden rounded-md">{children}</div>
    </div>
  );
};

const ChartSlice = ({ chartKey, percent, colorClassName, overlay, hidden }) => {
  if (hidden) return null;
  return (
    <div style={{ flex: percent }} className={colorClassName} data-testid={`stacked-graph-chart-slice-${chartKey}`}>
      {overlay && <HomeGraphHatchOverlay height="100%" />}
    </div>
  );
};

const Legend = ({ children, className }) => {
  return (
    <div className={className} data-testid="stacked-graph-legend">
      {children}
    </div>
  );
};

const LegendSliceTitle = ({
  chartKey,
  className = 'flex items-center mt-4',
  colorClassName,
  overlay,
  hidden,
  title,
  titleClassName = 'm-0 leading-2 text-sm text-neutral-dark-100 font-bold uppercase tracking-[1px] font-sans',
  tooltipHeader,
  tooltipContent,
  tooltipComponent,
}) => {
  if (hidden) return null;
  return (
    <div className={className} data-testid={`home-graph-legend-title-${chartKey}`}>
      <div className={cx('relative ml-1 mr-3 mt-0.5 h-4 w-4 self-start rounded', colorClassName)}>
        {overlay && <HomeGraphHatchOverlay height={16} />}
      </div>

      <div data-testid="home-graph-legend-description">
        <h4 className={titleClassName}>
          {title}
          {tooltipContent && (
            <Tooltip
              className="ml-2 normal-case tracking-normal"
              content={
                <>
                  {tooltipHeader && <h4>{tooltipHeader}</h4>}
                  <p className="m-0 mb-1 mt-3">{tooltipContent}</p>
                </>
              }
            >
              {tooltipComponent}
            </Tooltip>
          )}
        </h4>
      </div>
    </div>
  );
};

const LegendSliceValue = ({
  chartKey,
  className = 'flex items-center ml-8 mt-2',
  hidden,
  empty,
  value,
  valueFormatter,
  valueClassName,
  percent,
  placeHolder = '--',
  sliceFractionFormatter = getFormattedPercent,
  badgeTheme = 'neutralLight50',
}) => {
  if (hidden) return null;
  return (
    <div className={className} data-testid={`stacked-graph-legend-value-${chartKey}`}>
      <h3 data-testid="formatted-value" className={valueClassName}>
        {empty ? placeHolder : valueFormatter(value)}
      </h3>
      {!empty && <Badge data-testid="formatted-percent" label={sliceFractionFormatter(percent)} theme={badgeTheme} />}
    </div>
  );
};

StackedBarGraph.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
Chart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
ChartSlice.propTypes = {
  chartKey: PropTypes.string.isRequired,
  colorClassName: PropTypes.string,
  overlay: PropTypes.bool,
  hidden: PropTypes.bool,
};
Legend.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
LegendSliceTitle.propTypes = {
  chartKey: PropTypes.string.isRequired,
  colorClassName: PropTypes.string,
  overlay: PropTypes.bool,
  hidden: PropTypes.bool,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipComponent: PropTypes.node,
};
LegendSliceValue.propTypes = {
  chartKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  percent: PropTypes.number,
  value: PropTypes.number,
  isShareCapped: PropTypes.bool,
  valueFormatter: PropTypes.func.isRequired,
  valueClassName: PropTypes.string,
  badgeFormatter: PropTypes.func,
};

StackedBarGraph.ChartSlice = ChartSlice;
StackedBarGraph.Chart = Chart;
StackedBarGraph.Legend = Legend;
StackedBarGraph.LegendSliceTitle = LegendSliceTitle;
StackedBarGraph.LegendSliceValue = LegendSliceValue;
export default StackedBarGraph;
