import React from 'react';
import NavNestedItems from 'components/nav/NavNestedItems/NavNestedItems';
import NavItemLink from 'components/nav/NavItemLink/NavItemLink';

import cx from 'classnames';
import PropTypes from 'prop-types';
import usePrimaryNav from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';

const NavSectionGroup = ({ groupIndex, section, active, onToggle, onNavItemClick, open }) => {
  const { isMobileNavOpen } = usePrimaryNav();

  return (
    <>
      <div
        className={cx(
          'relative w-full before:absolute before:-left-6 before:-right-6 before:top-0 before:h-px before:bg-neutral-light-100',
          {
            'pt-6 before:content-[""]': groupIndex !== 0 && isMobileNavOpen,
          },
        )}
      >
        <NavNestedItems
          title={section.section}
          icon={section.icon}
          active={active}
          open={open}
          onToggle={onToggle}
          navItems={section.items}
          onNavItemClick={onNavItemClick}
          disabled={section.disabled}
        />
      </div>
      {section.extraContent && section.extraContent()}
    </>
  );
};

const NavPrimaryMenu = ({
  openSection,
  onToggleOpenSection,
  navLinks,
  navSectionGroups,
  className,
  onNavItemClick,
  dataTestId,
}) => {
  const getActiveSection = section => section.items.some(item => item.active);
  const {
    navItemsExpanded,
    isSidebarOpeningOrOpen,
    isMobile,
    isSidebarClosingOrClosed,
    onChangeIsDesktopNavOpen,
    onChangeIsHomePickerOpen,
  } = usePrimaryNav();

  const handleNavSectionClick = (section, isSectionOpen) => {
    if (isSectionOpen || !navItemsExpanded) {
      onToggleOpenSection(section);
    } else {
      onToggleOpenSection(null);
    }
    onChangeIsDesktopNavOpen(true);
    onChangeIsHomePickerOpen(false);
  };

  return (
    <div className={cx('relative flex w-full flex-col items-center bg-white', className)} data-testid={dataTestId}>
      <div
        className={cx('flex w-full flex-col items-center gap-3 pt-6 transition-all duration-500', {
          'max-w-[calc(100%-theme(spacing.6))]': isSidebarOpeningOrOpen,
          'max-w-[calc(100%-theme(spacing.4))]': isMobile || isSidebarClosingOrClosed,
        })}
      >
        {navLinks.map(link => (
          <NavItemLink
            key={link.title}
            icon={link.icon}
            title={navItemsExpanded && link.title}
            hoverTitle={link.title}
            to={link.path}
            active={link.active}
            onClick={onNavItemClick}
          />
        ))}
        {navSectionGroups.map((sectionGroup, groupIndex) => {
          return (
            <div key={`nav-sectionGroup-${groupIndex}`} className="flex w-full flex-col items-center gap-3 pb-3">
              {sectionGroup.map(section => {
                return (
                  <NavSectionGroup
                    key={section.section}
                    groupIndex={groupIndex}
                    active={getActiveSection(section)}
                    open={openSection === section.section}
                    section={section}
                    onToggle={isSectionOpen => handleNavSectionClick(section.section, isSectionOpen)}
                    onNavItemClick={onNavItemClick}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

NavPrimaryMenu.propTypes = {
  openSection: PropTypes.string,
  onToggleOpenSection: PropTypes.func,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ),
  navSectionGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        section: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            active: PropTypes.bool,
          }),
        ).isRequired,
        extraContent: PropTypes.func,
      }),
    ),
  ),
  className: PropTypes.string,
};

export default NavPrimaryMenu;
