import ApplicationFormWrapper from './ApplicationFormWrapper/ApplicationFormWrapper';
import useApplicationContext from '../hooks/useApplicationContext';
import { buildDataForApplicationUpdate, onSaveError } from '../utils';
import useApplicationNavContext from '../hooks/useApplicationNavContext';
import { homeownerApplicationCompletedBrowserTrack } from 'utils/segment';

const ApplicationFormSectionWrapper = ({ children, isSavePage }) => {
  const {
    track,
    applicants,
    primaryApplicant,
    isCurrentFormValid,
    applicationFormData,
    updateApplication,
    consentApplication,
    isLoading,
  } = useApplicationContext();

  const { isLastPageOfApplication, navigateToNextPage } = useApplicationNavContext();

  const handleProgressButtonClick = async () => {
    if (isLastPageOfApplication) {
      return await consentApplication()
        .then(() => {
          const { enabled_rollout_flags: flags, friendly_id: friendlyId } = track;
          const { invited_email } = primaryApplicant;
          const numberOfCoApplicants = applicants.length - 1;
          homeownerApplicationCompletedBrowserTrack({
            flags,
            invited_email,
            friendlyId,
            numberOfCoApplicants,
          });
          navigateToNextPage();
        })
        .catch(onSaveError());
    } else if (isSavePage) {
      return await updateApplication(buildDataForApplicationUpdate(applicationFormData))
        .then(() => navigateToNextPage())
        .catch(e => {
          onSaveError(e.response?.data?.non_field_errors)(e);
        });
    }
  };

  return (
    <ApplicationFormWrapper
      onProgressButtonClick={isSavePage && handleProgressButtonClick}
      isSavePage={isSavePage}
      isLoading={isLoading}
      buttonDisabled={!isCurrentFormValid}
      buttonText={isLastPageOfApplication && 'Submit Application'}
    >
      {children}
    </ApplicationFormWrapper>
  );
};

export default ApplicationFormSectionWrapper;
