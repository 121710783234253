import AccessHomeEquityCTA from 'components/AccessHomeEquityCTA/AccessHomeEquityCTA';
import AccountDropdown from 'components/AccountDropdown/AccountDropdown';
import { ProgressBar } from 'components/ProgressProvider/ProgressProvider';

const PrimaryDesktopHeader = ({ title }) => {
  return (
    <div className="sticky top-0 z-10 hidden sm:block">
      <header className="flex h-[87px] items-center justify-between border-0 border-b border-solid border-neutral-light-100 bg-white">
        <div className="flex items-center">
          <strong className="p-1 pl-[20px] leading-[26px]">{title}</strong>
        </div>

        <div className="flex items-center gap-4 pr-12">
          <AccessHomeEquityCTA className="m-0" />
          <AccountDropdown />
        </div>
      </header>

      <ProgressBar className="-mt-[1px]" />
    </div>
  );
};

export default PrimaryDesktopHeader;
