import React, { useEffect, useState, useContext } from 'react';
import { useAsync } from '@hometap/htco-components';
import {
  fetchAndFormatAppreciationData,
  fetchChartData,
  getEquityScenarioLiens,
  postScenarioAndLiens,
  deleteEquityScenarioLien,
  deleteEquityScenario,
  postEquityScenarioLien,
  patchEquityScenarioLien,
} from 'apps/dashboard/data';
import { formatAppreciationDataWithRenovations } from 'apps/dashboard/utils/dataUtils';
import { saveRenovationToScenario } from 'apps/dashboard/components/home-equity-controller/equity-renovations/data/requests';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { showNotification } from 'utils/toasts';
import sentry from 'utils/sentry';
import { useQuery } from '@tanstack/react-query';
import { useHomeValuation } from './useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';

/*
  This file handles the state logic for the home equity portion of the dashboard.
  equityScenarioRequests.js handles a lot of the API logic.
*/

const EquityScenarioDefaultContext = {
  currentScenario: {},
  futureScenario: {},
  initialScenario: {},
  getEquityScenarioLiensAsync: {},
  chartForecast: '10',
  isLoading: false,
  isFutureView: false,
  hideChartPanel: false,
  setIsFutureView: () => {},
  createFutureScenario: () => {},
  changeScenarioRenovations: () => {},
  changeScenarioRate: () => {},
  deleteLien: async () => {},
  addLien: () => {},
  updateLien: () => {},
  hideLien: () => {},
  unhideLien: () => {},
  deleteFutureScenario: () => {},
  setChartForecast: () => {},
};

const Context = React.createContext(EquityScenarioDefaultContext);

export const useEquityScenarioContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useEquityScenarioContext must be used within a HomePropertyProvider');
  }
  return context;
};

const useChartData = () => {
  const { home } = useCurrentHome();
  const { displayedHomeValuation } = useHomeValuation();

  const { investment, loading: investmentLoading } = useHedInvestmentContext();
  return useQuery({
    queryFn: () => fetchChartData({ homeId: home?.id, homeValuation: displayedHomeValuation, investment }),
    queryKey: [
      'hed-chart-data',
      { homeId: home?.id, homeValue: displayedHomeValuation?.value, investmentId: investment?.id },
    ],
    enabled: !!home && !investmentLoading,
  });
};

export const EquityScenarioContextProvider = ({ children }) => {
  const [chartForecast, setChartForecast] = useState('10');

  const [initialScenario, setInitialScenario] = useState({});
  const [futureScenario, setFutureScenario] = useState({});

  const { home, loading: homeLoading } = useCurrentHome();
  const { investment } = useHedInvestmentContext();

  const { homeValuation, displayedHomeValuation, isLoading: homeValuationLoading } = useHomeValuation();
  const { data: chartData, isLoading: chartDataLoading } = useChartData();

  const fetchAppreciationDataAsync = useAsync(fetchAndFormatAppreciationData);
  const postScenarioAsync = useAsync(postScenarioAndLiens, { executeThrow: true });
  const postRenoToScenarioAsync = useAsync(saveRenovationToScenario);
  const getEquityScenarioLiensAsync = useAsync(getEquityScenarioLiens);
  const deleteEquityScenarioLiensAsync = useAsync(deleteEquityScenarioLien);
  const deleteEquityScenarioAsync = useAsync(deleteEquityScenario);
  const postEquityScenarioLienAsync = useAsync(postEquityScenarioLien);
  const patchEquityScenarioLienAsync = useAsync(patchEquityScenarioLien);

  useEffect(() => {
    const initialScenario = chartData?.scenarios?.find(scenario => scenario.initial_scenario);
    setInitialScenario(initialScenario || {});
    const futureScenario = chartData?.scenarios?.find(scenario => scenario.initial_scenario_future) || {};
    setFutureScenario(futureScenario || {});
  }, [chartData]);

  // "Today's View" and "Plan for the future" toggle
  const [isFutureView, setIsFutureView] = useState(false);

  const currentScenario = isFutureView ? futureScenario : initialScenario;

  // Remove scenario from the list and switch current scenario if deleted.
  const deleteFutureScenario = async scenarioId => {
    if (scenarioId === futureScenario?.id) {
      await deleteEquityScenarioAsync.execute(home?.id, scenarioId);
      setFutureScenario({});
    }
  };

  // Edit scenario appreciation rate and get new appreciation data. (Slider values)
  const changeScenarioRate = async newRate => {
    try {
      const scenario = { ...currentScenario, appreciation_rate: newRate };
      // using execute to access loading state on slider
      const scenarioWithAppreciationData = await fetchAppreciationDataAsync.execute(
        scenario,
        displayedHomeValuation,
        investment,
        false,
      );
      if (isFutureView) {
        setFutureScenario(scenarioWithAppreciationData);
      } else {
        setInitialScenario(scenarioWithAppreciationData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Edit renovations and get new appreciation data.
  // `renovations` argument is an array of the new renovations (does not edit current list).
  const changeScenarioRenovations = async renovations => {
    try {
      const scenario = { ...futureScenario, renovations };
      const scenarioWithAppreciationData = await fetchAppreciationDataAsync.execute(
        scenario,
        displayedHomeValuation,
        investment,
        false,
      );

      scenarioWithAppreciationData.annualAppreciation = formatAppreciationDataWithRenovations(
        scenarioWithAppreciationData.annualAppreciation,
      );
      setFutureScenario({ ...scenarioWithAppreciationData, renovations });
      setIsFutureView(true);
    } catch (error) {
      console.error(error);
    }
  };

  const removeLienFetchAppreciationData = async (lienId, isInitialScenario) => {
    const scenarioToUpdate = isInitialScenario ? { ...initialScenario } : { ...futureScenario };
    const newLiens = scenarioToUpdate.liens.filter(lien => lien?.id !== lienId);
    scenarioToUpdate.liens = newLiens;
    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  const deleteLien = async (scenarioId, lienId) => {
    try {
      const isInitialScenario = scenarioId === initialScenario?.id;
      await deleteEquityScenarioLiensAsync.execute(home?.id, scenarioId, lienId);
      const scenarioWithAppreciationData = await removeLienFetchAppreciationData(lienId, isInitialScenario);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleHideLienFetchAppreciationData = async (lien, isInitialScenario, hide) => {
    const scenarioToUpdate = isInitialScenario ? { ...initialScenario } : { ...futureScenario };

    const newLiens = scenarioToUpdate.liens.map(existingLien => {
      const toggleLien = existingLien?.lien_type === lien?.lien_type;
      if (toggleLien && hide) {
        return { ...existingLien, hidden: true };
      } else if (toggleLien && !hide) {
        return { ...existingLien, hidden: false };
      }
      return existingLien;
    });

    scenarioToUpdate.liens = newLiens;

    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  const hideLien = async (scenarioId, lien) => {
    try {
      const isInitialScenario = scenarioId === initialScenario?.id;
      const scenarioWithAppreciationData = await toggleHideLienFetchAppreciationData(lien, isInitialScenario, true);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const unhideLien = async (scenarioId, lien) => {
    try {
      const isInitialScenario = scenarioId === initialScenario?.id;
      const scenarioWithAppreciationData = await toggleHideLienFetchAppreciationData(lien, isInitialScenario, false);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addLienToAppreciationData = async (scenario, lien) => {
    const scenarioToUpdate = { ...scenario };
    scenarioToUpdate.liens.push(lien);
    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  const addLien = async (scenario, lien, onSuccess) => {
    try {
      const isInitialScenario = scenario?.id === initialScenario?.id;
      const newLien = await postEquityScenarioLienAsync.execute(home?.id, scenario?.id, lien);
      const scenarioWithAppreciationData = await addLienToAppreciationData(scenario, newLien);
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }

      onSuccess && onSuccess();
    } catch (error) {
      sentry.logErrorWrapper(`Unable to create lien for home: ${home.id}`, error);
      return showNotification({
        type: 'error',
        title: "Sorry we couldn't save your lien details. Please try again.",
        description: 'If the problem persists, please contact support.',
      });
    }
  };

  const updateLienAndGetAppreciationData = async (scenario, lien) => {
    const scenarioToUpdate = { ...scenario };
    scenarioToUpdate.liens = [...scenarioToUpdate.liens].map(existingLien =>
      existingLien?.id === lien?.id ? lien : existingLien,
    );
    return await fetchAppreciationDataAsync.execute(scenarioToUpdate, displayedHomeValuation, investment, false);
  };

  const updateLien = async (scenario, existingLienId, lienData) => {
    try {
      const isInitialScenario = scenario?.id === initialScenario?.id;
      const patchedLien = await patchEquityScenarioLienAsync.execute(home?.id, scenario?.id, existingLienId, lienData);
      const scenarioWithAppreciationData = await updateLienAndGetAppreciationData(
        scenario,
        patchedLien,
        isInitialScenario,
      );
      if (isInitialScenario) {
        setInitialScenario(scenarioWithAppreciationData);
      } else {
        setFutureScenario(scenarioWithAppreciationData);
        setIsFutureView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Create future scenario from initial scenario
  const createFutureScenario = async renovation => {
    const initialScenarioCopy = { ...initialScenario, initial_scenario: false, initial_scenario_future: true };
    delete initialScenarioCopy.id;

    const newFutureScenario = await postScenarioAsync
      .execute(home?.id, initialScenarioCopy)
      .catch(error => console.error(error));

    // get appreciation data for future scenario
    if (renovation) {
      const reno = await postRenoToScenarioAsync.execute(home.id, newFutureScenario?.id, renovation);
      newFutureScenario.renovations = [reno];
    }

    try {
      const futureScenarioWithAppreciationData = await fetchAppreciationDataAsync.execute(
        newFutureScenario,
        homeValuation,
        investment,
        false,
      );
      setFutureScenario(futureScenarioWithAppreciationData);
      setIsFutureView(true);
      return futureScenarioWithAppreciationData;
    } catch (error) {
      console.error(error);
    }
  };

  // this should be changed when we add more detailed error states
  const isLoadingHed = !initialScenario?.id || homeLoading || homeValuationLoading;

  const hideInitialScenarioPanel = !isFutureView && !initialScenario?.liens?.length && !investment;

  const hideFutureScenarioPanel =
    isFutureView && !futureScenario?.liens?.length && !futureScenario?.renovations?.length;

  const hideChartPanel = hideInitialScenarioPanel || hideFutureScenarioPanel;

  const data = {
    currentScenario,
    chartDataLoading,
    getEquityScenarioLiensAsync,
    futureScenario,
    initialScenario,
    chartForecast,
    isLoadingHed,
    isFutureView,
    hideChartPanel,
    setIsFutureView,
    createFutureScenario,
    changeScenarioRenovations,
    changeScenarioRate,
    deleteLien,
    addLien,
    updateLien,
    hideLien,
    unhideLien,
    deleteFutureScenario,
    setChartForecast,
  };

  return <Context.Provider value={data}>{children}</Context.Provider>;
};
