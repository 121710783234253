const SettlingHeiWithRefinance = `<p>Whether you&rsquo;re planning on settling your Hometap Investment soon or down the road, it&rsquo;s important to understand all of your potential options. One of those options is to pay off the Investment with a cash-out refinance.</p>
<p>Before you make your decision, you&rsquo;ll want to consider all of your choices for settlement. These are:</p>
<ul>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-home-sale" rel="noreferrer noopener">A home sale</a></li>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-home-loan-or-heloc" rel="noreferrer noopener">A home equity loan or home equity line of credit (HELOC)</a></li>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-cash-savings" rel="noreferrer noopener">A buyout with savings</a></li>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-refinance" rel="noreferrer noopener">A refinance</a></li>
</ul>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F313824c49b97ddcbfc9ec183b499a31cce4e2870-1000x295.png&w=2048&q=75"/>
<h2>Things to Consider</h2>
<p>With a refinance, you&rsquo;re taking out a new mortgage &mdash; meaning a new interest rate and new monthly payment &mdash;&nbsp;for which the balance is greater than your current one. You&rsquo;ll receive the difference between the two mortgages in cash. To settle with a refinance, you&rsquo;ll use the proceeds of the refinance to pay off your Hometap Investment.</p>
<p>There are a few questions to ask yourself before you decide to refinance.</p>
<ul>
<li><em>Are you primarily refinancing to secure a better interest rate on your mortgage?</em></li>
</ul>
<p>You might be able to decrease your interest rate with a refinance, but it&rsquo;s worth weighing the potential downsides below before you take the leap.</p>
<ul>
<li><em>Will your savings in rate change cover the fees associated with your refinance?</em></li>
</ul>
<p>Keep in mind that a refinance has fees attached, and&nbsp;consider whether or not the lower interest rate will make up for these added costs.</p>
<ul>
<li><em>Is the new mortgage extending your mortgage timeline?</em></li>
</ul>
<p>It&rsquo;s important to remember that because you&rsquo;re essentially taking out a new mortgage with a refinance, this route will reset your mortgage timeline.</p>
<p>You should also think about your timeline if you&rsquo;re up against the end of your Investment&rsquo;s 10-year effective period. While the settlement process typically takes about three weeks, we recommend starting the process at least 45 days before this date to give yourself ample time.</p>
<p>If you plan to settle at the end of the ten year term, you can find your settlement date by selecting the Settlement Calculator (more on that below).</p>
<h2>Determine the Amount You Owe</h2>
<p>Before moving forward with any settlement method, you&rsquo;ll want to find out how much you&rsquo;ll pay out to Hometap &mdash;&nbsp;this is also called the &ldquo;Hometap Share.&rdquo; The quickest way to do this is to access our Settlement Calculator through your Hometap account, which will also allow you to check on your settlement amount.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F4aeec4632c743b1e88e110fd8d1bdb46ea4e73f6-1024x746.gif&w=2048&q=75" />
<h2>Start the Settlement Process</h2>
<p>Once you&rsquo;ve landed on a refinance as your settlement method, you can begin the process of initiating a refinance with your lender.</p>
<p>The next step is to contact our Investment Support team via email at homeowners@hometap.com or by calling 1 (617) 604-6985. When you contact us, make sure you have the following information:</p>
<ul>
<li>A good-through date for your settlement statement (we suggest at least 30 days out)</li>
<li>The method of settlement &mdash; in this case, a refinance.</li>
</ul>
<p>We&rsquo;ll assess the current fair market value (FMV) of your home with the help of an automated valuation model (AVM) or in-person appraisal. Please note that in the case of an in-person appraisal you&rsquo;ll be responsible for the appraisal cost, as detailed in your contract.</p>
<p>Next, Investment Support will reach out to you via email with your final payoff amount. This will include directions for submitting payment. You have some options to choose from here:</p>
<ul>
<li>A&nbsp;<a href="https://www.consumerfinance.gov/ask-cfpb/what-is-a-wire-transfer-en-1163/" target="_blank" rel="noreferrer noopener"><strong>wire transfer</strong></a>, which can be done at a cash office or bank, allows you to send money electronically. The fast turnaround time makes this a popular choice for many homeowners.</li>
<li>A<strong>&nbsp;<a href="https://www.pnc.com/insights/personal-finance/spend/cashiers-check-vs-certified-check.html" target="_blank" rel="noreferrer noopener">certified check</a>&nbsp;</strong>is written from your personal funds and &mdash;&nbsp;after the amount is verified &mdash;&nbsp;it&rsquo;s guaranteed by a bank.</li>
<li>A&nbsp;<strong><a href="https://www.cnn.com/cnn-underscored/money/what-is-a-cashiers-check" target="_blank" rel="noreferrer noopener">cashier&rsquo;s check</a>&nbsp;</strong>is also a certified form of payment, but the funds are held in escrow by the bank and they&rsquo;ll issue a check.</li>
<li>A<strong>&nbsp;<a href="https://www.experian.com/blogs/ask-experian/what-is-money-order/" target="_blank" rel="noreferrer noopener">money order</a></strong>&nbsp;is an option<strong>&nbsp;if you have a settlement amount of $1,000 or less.</strong>&nbsp;This secure method has a short turnaround time and won&rsquo;t bounce.</li>
</ul>
<p>After we&rsquo;ve confirmed your payment, a member of our team will connect with you and file to remove the lien in your home. This process usually takes between 7 to 30 days. As a final confirmation, we&rsquo;ll send you an official letter that notifies you that your Investment has been settled.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F54faaf3cf7b24b04dfb3774b477059c7facd3c77-1000x413.png&w=2048&q=75"/>
<p>Now let's recap. Here are the steps for settling your home equity investment via a refinance:</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F899c9cfa37ffa2a46fcec19fc2eecf01f25849ec-1000x1265.png&w=2048&q=75"/>
<div>
<h2>Stay Tapped In After Settling</h2>
<p>Even after you&rsquo;ve settled your Investment, we&rsquo;re here for you throughout your homeownership journey. You have free access to the Home Equity Dashboard, a digital platform where you can keep track of your home&rsquo;s equity and value, calculate average renovation costs and return on investment for improvements, and get access to best-in-class home service partners.</p>
</div>`;

export default SettlingHeiWithRefinance;
