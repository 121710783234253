import { PROFILE_INSTANCES, PROFILE_STAGES } from 'apps/dashboard/constants';
import { useNavigate } from 'react-router-dom-v5-compat';
import { HOMEOWNER_DASHBOARD } from 'utils/links';

import Debt from './Debt';

const DebtSlider = ({ instance, noConfirm, updateStages, form, onDebtNext, initialEditConfig }) => {
  const navigate = useNavigate();

  const onBack = () => {
    const singleLien = form?.formData.liens?.length === 1;

    if (instance === PROFILE_INSTANCES.EDIT_PROFILE && noConfirm) {
      return null;
    }

    if (instance === PROFILE_INSTANCES.EDIT_PROFILE && !noConfirm && singleLien) {
      return () => updateStages(PROFILE_STAGES.EDIT_YOUR_PROFILE);
    }

    // Only the back button during the setup flow
    return () => updateStages(PROFILE_STAGES.DEBT_SELECTION_STEP);
  };

  const onNext = () => {
    const singleLien = form?.formData.liens?.length === 1;

    // When editing a single lien, we only need to save and go back to the dashboard
    if (instance === PROFILE_INSTANCES.EDIT_PROFILE && singleLien && noConfirm) {
      return () => navigate(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`);
    }
    return onDebtNext;
  };

  return (
    <Debt
      onNext={initialEditConfig?.lienType ? onNext() : onDebtNext}
      onBack={onBack()}
      onConfirm={() => localStorage.setItem(`is_confirmed_mortgage_loan`, 'true')}
      form={form}
      instance={instance}
    />
  );
};

export default DebtSlider;
