export const ANIMATION_VARIANT = {
  sidebarOpen: 'sidebarOpen',
  sidebarClosed: 'sidebarClosed',
  show: 'show',
  hidden: 'hidden',
};

export const sidebarVariants = {
  [ANIMATION_VARIANT.sidebarOpen]: {
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
    },
  },
  [ANIMATION_VARIANT.sidebarClosed]: {
    x: '-100%',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
    },
  },
};

export const headerVariants = {
  [ANIMATION_VARIANT.show]: {
    top: 0,
    transition: { ease: [0.17, 0.67, 0.83, 0.67] },
  },
  [ANIMATION_VARIANT.hidden]: {
    top: -150,
    transition: { ease: [0.17, 0.67, 0.83, 0.67] },
  },
};
