import { ModalConfirm } from '@hometap/htco-components';
import React, { useState } from 'react';
import { useSelfReportedHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';

const NewValueAvailableModal = ({ home, homeValuation, selfReportedHomeValuation }) => {
  const { mutateAsync: mutateSelfReportedHomeValuation } = useSelfReportedHomeValuationMutation(home);
  const checkShouldShowModal = () => {
    const hasMissingObjects = !homeValuation || !selfReportedHomeValuation;
    if (hasMissingObjects) return false;

    const hasNothingToCompare = !homeValuation.as_of_date || !selfReportedHomeValuation.valuation?.as_of_date;
    if (hasNothingToCompare) return false;

    if (!selfReportedHomeValuation.is_editable) return false;

    if (homeValuation.as_of_date >= selfReportedHomeValuation.valuation.as_of_date) return true;
  };

  const [showNewValueModal, setShowNewValueModal] = useState(checkShouldShowModal);
  const handleKeepSelfReportedHomeValuation = async () => {
    setShowNewValueModal(false);

    return await mutateSelfReportedHomeValuation(home?.homeowner_estimated_home_value);
  };
  const handleClose = () => {
    setShowNewValueModal(false);
  };

  const handleUseNewValue = async () => {
    setShowNewValueModal(false);
    return await mutateSelfReportedHomeValuation(null);
  };

  return (
    <ModalConfirm
      className="PlannerDashboardConfirmNewValueModal"
      data-testid="hed-confirm-new-value-modal"
      width={580}
      open={showNewValueModal}
      header="New home value available!"
      confirmText="Use new value"
      cancelText="Keep existing value"
      onConfirm={handleUseNewValue}
      onCancel={handleKeepSelfReportedHomeValuation}
      onClose={handleClose}
    >
      <div className="PlannerDashboardShowValueModalBody">
        <p>Your home value has recently changed. The new value is:</p>
        <h2>
          {homeValuation?.value !== undefined
            ? `$${new Intl.NumberFormat('en-US').format(homeValuation.value.toFixed(0))}`
            : 'N/A'}
        </h2>
      </div>
    </ModalConfirm>
  );
};

export default NewValueAvailableModal;
