const SettlingHeiWithHomeLoanOrHeloc = `<p>Thinking about settling your Hometap Investment with a home equity loan or line or credit? Knowing your options and the steps involved with each is a critical first part of the planning process, ensuring that you make the best decision possible &mdash; and many homeowners find that a home equity loan or line of credit is the right option for them.</p>
<p>Before we get into the steps for settling your investment, it&rsquo;s important that you understand the nuances between these two financial products.</p>
<p><strong>A home equity loan</strong>&nbsp;is a method of accessing equity that provides you with cash up front, along with a fixed interest rate and predictable monthly payments. However, you&rsquo;ll have to pay off the loan alongside your mortgage payments and the application and approval processes can have strict requirements.</p>
<p><strong>A home equity line of credit (HELOC)</strong>&nbsp;is a flexible financing option that allows you to draw funds from your equity over time, similar to a credit card. You only pay interest on the amount you&rsquo;ve drawn, but HELOC interest rates are variable, meaning your payments may differ from month to month. Like a home equity loan, the process for application and approval can be strict. Also keep in mind that if your credit score decreases too much, lenders can freeze your account at any time.</p>
<p>No matter which settlement method you choose, our Investment Support team is here to ensure the process is as smooth and transparent as possible.</p>
<h2>3 Things to Consider Before Settling</h2>
<p>Whether you&rsquo;re settling your Investment with a home equity loan or HELOC, keep these 3 things in mind. It&rsquo;s important to pick the settlement option best suited to your needs and circumstances.</p>
<ul>
<li><strong>Timeframe:</strong>&nbsp;Consider how close you are to the end of the 10-year effective period of your Hometap Investment. We recommend beginning the settlement process at least 45 days before the end of the effective period to ensure enough time to close and for us to receive the funds and release the lien we placed on your property.</li>
<li><strong>Costs/fees:</strong>&nbsp;There are&nbsp;<a href="https://www.discover.com/home-loans/articles/heloc-costs/" target="_blank" rel="noreferrer noopener">costs</a>&nbsp;associated with a home equity loan or line of credit. Before finalizing your decision, make sure you understand all the details that come with your chosen option.</li>
<li><strong>Other options:&nbsp;</strong>Ensure that you&rsquo;ve explored all other options available to you. There may be an alternative settlement method that&rsquo;s a better fit for you.
<ul>
<li><strong><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-home-sale" rel="noreferrer noopener">Home sale</a>:</strong>&nbsp;If you don&rsquo;t plan to remain in your home, selling it may be the best choice, since you&rsquo;ll use the proceeds of the home sale to settle.</li>
<li><strong><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-refinance" rel="noreferrer noopener">Cash-out refinance</a>:&nbsp;</strong>You can also settle your Investment by refinancing your mortgage. A cash-out&nbsp;<a href="https://www.hometap.com/blog/when-is-a-good-time-to-refinance-your-home/" rel="noreferrer noopener">refinance</a>&nbsp;involves replacing your current mortgage loan with a larger one, allowing you to get the difference between the two in cash. This can be a good option if you&rsquo;re able to lower your interest rate with the new mortgage.</li>
<li><strong><a href="/dashboard/resources/settling-your-home-equity-investment-with-cash-savings" rel="noreferrer noopener">Buyout with savings</a>:</strong>&nbsp;If you have enough savings built up to buy out Hometap&rsquo;s share of your home, this may be the way to go since it doesn&rsquo;t involve adding a new debt.</li>
</ul>
</li>
</ul>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F313824c49b97ddcbfc9ec183b499a31cce4e2870-1000x295.png&w=2048&q=75"/>
<h2>Determine What You Owe</h2>
<p>Once you&rsquo;ve decided to settle your Investment, the first step is to determine how much you owe; this amount is also known as the Hometap Share. You can check your estimated balance at any time by logging into your Hometap account and using our Settlement Calculator.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F4aeec4632c743b1e88e110fd8d1bdb46ea4e73f6-1024x746.gif&w=2048&q=75"/>
<h2>Initiating Your Settlement</h2>
<p>After you&rsquo;ve reviewed all your options, picked the best fit, and applied for funding, it&rsquo;s time to initiate the settlement process. Start by reaching out to our Investment Support team at&nbsp;<a href="mailto:homeowners@hometap.com" target="_blank" rel="noreferrer noopener">homeowners@hometap.com</a>&nbsp;or calling +1 (617) 604-6985. When doing so, provide the following information:</p>
<ul>
<li>A good-through date for your Settlement Statement &mdash; this should be at least 30 days out.</li>
<li>The method of settlement &mdash; in this case, a home equity loan or HELOC.</li>
</ul>
<p>From there, we&rsquo;ll determine the current&nbsp;<a href="https://www.rocketmortgage.com/learn/fair-market-value" target="_blank" rel="noreferrer noopener">fair market value</a>&nbsp;(FMV) of the property using an&nbsp;<a href="https://www.rocketmortgage.com/learn/automated-valuation-model" target="_blank" rel="noreferrer noopener">automated valuation model</a>&nbsp;(AVM) and the Investment Support team will share your final payoff amount with you via email. This email will also include directions for how to submit your payment, so make sure to read it closely.</p>
<h2>Completing Your Settlement</h2>
<p>Once you&rsquo;ve received the funds from your home equity loan or HELOC, the next step is to pay off the Hometap Share. There are 4 payment methods we accept that will allow you to complete your settlement:</p>
<ul>
<li>A&nbsp;<strong><a href="https://www.consumerfinance.gov/ask-cfpb/what-is-a-wire-transfer-en-1163/" target="_blank" rel="noreferrer noopener">wire transfer</a>&nbsp;</strong>is an electronic method for sending money, and can be completed at a bank or cash office. This is often the preferred choice for homeowners because it&rsquo;s the quickest.</li>
<li>A<strong>&nbsp;<a href="https://www.pnc.com/insights/personal-finance/spend/cashiers-check-vs-certified-check.html" target="_blank" rel="noreferrer noopener">certified check</a>&nbsp;</strong>is a check drawn from your personal funds that is guaranteed by a bank after they confirm you have a sufficient amount of money.</li>
<li>A&nbsp;<strong><a href="https://www.cnn.com/cnn-underscored/money/what-is-a-cashiers-check" target="_blank" rel="noreferrer noopener">cashier&rsquo;s check</a>&nbsp;</strong>is also guaranteed by a bank, but in this case, the bank takes the funds, holds them in escrow, and prints a check against those funds.</li>
<li>A<strong>&nbsp;<a href="https://www.experian.com/blogs/ask-experian/what-is-money-order/" target="_blank" rel="noreferrer noopener">money order</a></strong>&nbsp;is a secure way to send a payment up to $1,000, so this is an option&nbsp;<strong>only if you have a settlement amount under $1,000</strong>. Money orders also won&rsquo;t bounce and have a quick timeline.</li>
</ul>
<p>After you&rsquo;ve submitted payment via your chosen method, a member of Investment Support will contact you confirming receipt of the funds. Next, we will file to remove the lien Hometap placed on your property at the time of the Investment. Typically, it takes 7-30 days for the lien to be released.</p>
<p>Keep an eye out for a letter from Hometap indicating that the funds were received and your Investment has been settled. Below, you&rsquo;ll find an example of what this letter looks like.</p> 
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F54faaf3cf7b24b04dfb3774b477059c7facd3c77-1000x413.png&w=2048&q=75"/>
<p>We aim for the settlement process to feel as seamless as your initial investment process. In summary, here&rsquo;s what that entails:</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F899c9cfa37ffa2a46fcec19fc2eecf01f25849ec-1000x1265.png&w=2048&q=75"/>
<h2><strong>After the Investment</strong></h2>
<p>Even after you settle your Hometap Investment, you still have free access to your Home Equity Dashboard (HED). Your Dashboard is always at your fingertips, ready to help you protect and grow your home&rsquo;s value and equity throughout your homeownership journey.</p>`;

export default SettlingHeiWithHomeLoanOrHeloc;
