import { apiWithAuth } from 'utils/api';

/*
  HOME ENDPOINTS 
*/

export const fetchHomes = () => {
  return apiWithAuth.v1.get(`/homes/`);
};

export const fetchHome = async home_id => {
  const home = await apiWithAuth.v1.get(`/homes/${home_id}`);
  return home;
};

export const updateHome = (homeId, data) => {
  return apiWithAuth.v1.put(`/homes/${homeId}/`, data);
};

export const patchHome = (homeId, data) => {
  return apiWithAuth.v1.patch(`/homes/${homeId}/`, data);
};

export const addNewProperty = data => {
  return apiWithAuth.v1.post('/homes/', data);
};

export const fetchHomeValuation = homeId => apiWithAuth.v1.post(`/homes/${homeId}/get-or-request-home-valuation/`);
export const getSelfReportedHomeValuation = homeId => apiWithAuth.v1.get(`/homes/${homeId}/self-reported-valuation/`);

/*
  EQUITY SCENARIO ENDPOINTS
*/

export const postEquityScenario = (homeId, data) => {
  return apiWithAuth.v1.post(`/homes/${homeId}/equity-scenarios/`, data);
};

export const getEquityScenario = (homeId, equityScenarioId) => {
  return apiWithAuth.v1.get(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/`);
};

export const getEquityScenarios = homeId => {
  return apiWithAuth.v1.get(`/homes/${homeId}/equity-scenarios/`);
};

export const patchEquityScenario = (homeId, equityScenarioId, data) => {
  return apiWithAuth.v1.patch(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/`, data);
};

export const deleteEquityScenario = (homeId, equityScenarioId) => {
  return apiWithAuth.v1.delete(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/`);
};

/*
  SELF REPORTED LIEN ENDPOINTS
*/

export const postSelfReportedLien = (homeId, data) => {
  return apiWithAuth.v1.post(`/homes/${homeId}/self-reported-liens/`, data);
};

export const getSelfReportedLiens = homeId => {
  return apiWithAuth.v1.get(`/homes/${homeId}/self-reported-liens/`);
};

export const patchSelfReportedLien = (homeId, selfReportedLienId, data) => {
  return apiWithAuth.v1.patch(`/homes/${homeId}/self-reported-liens/${selfReportedLienId}/`, data);
};

export const deleteSelfReportedLien = (homeId, selfReportedLienId) => {
  return apiWithAuth.v1.delete(`/homes/${homeId}/self-reported-liens/${selfReportedLienId}/`);
};

/*
  EQUITY SCENARIO LIEN ENDPOINTS
*/

export const postEquityScenarioLien = (homeId, equityScenarioId, data) => {
  return apiWithAuth.v1.post(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/`, data);
};

export const getEquityScenarioLiens = (homeId, equityScenarioId) => {
  return apiWithAuth.v1.get(`/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/`);
};

export const patchEquityScenarioLien = (homeId, equityScenarioId, equityScenarioLienId, data) => {
  return apiWithAuth.v1.patch(
    `/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/${equityScenarioLienId}/`,
    data,
  );
};

export const deleteEquityScenarioLien = (homeId, equityScenarioId, equityScenarioLienId) => {
  return apiWithAuth.v1.delete(
    `/homes/${homeId}/equity-scenarios/${equityScenarioId}/equity-scenario-liens/${equityScenarioLienId}/`,
  );
};

/*
  PRICING ENDPOINTS
*/

export const postAnnualAppreciation = async requestBody => {
  const annualAppreciation = await apiWithAuth.v1.post(`/pricing/annual-appreciation/`, requestBody);
  return annualAppreciation;
};

/*
  PRODUCT ENDPOINTS
*/

export const getDefaultProduct = async () => {
  return await apiWithAuth.v1.get(`/products/default/`);
};

/*
  TRACK ENDPOINTS
*/

export const fetchTrack = async id => {
  return await apiWithAuth.v1.get(`/tracks/${id}`);
};
