import React from 'react';
import { convertDateStringToShortDate } from 'utils/date';
import { InquiryFormRow } from './outcomeTextComponents';

const InquiryFico = ({ firstName, lastName, riskFailFactors, ficoScore, experianDataPulledAt }) => {
  let ficoScoreCopy;
  if (ficoScore === 9002) {
    ficoScoreCopy = 'No activity in the past 6 months';
  } else if (ficoScore === 9003) {
    ficoScoreCopy = 'No account more than 6 months old';
  } else {
    ficoScoreCopy = ficoScore;
  }

  return (
    <>
      <InquiryFormRow>
        <div>
          We also obtained your credit score from the consumer credit reporting agency and used it in making our
          decision. Your credit score is a number that reflects the information in your consumer report. Your credit
          score can change, depending on how the information in your consumer report changes.
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div data-testid="inquiry-fico-homeowner-name">
          {firstName} {lastName}
        </div>
        <div data-testid="inquiry-fico-score">Your credit score: {ficoScoreCopy}</div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div data-testid="inquiry-fico-date">Date: {convertDateStringToShortDate(experianDataPulledAt)}</div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div data-testid="inquiry-fico-row-1">Scores range from a low of 300 to 850</div>
      </InquiryFormRow>
      {riskFailFactors && riskFailFactors.length > 0 && (
        <>
          <InquiryFormRow>
            <div data-testid="inquiry-fico-row-2">Key factors that adversely affected your credit score</div>
          </InquiryFormRow>
          <InquiryFormRow>
            <ul className="bulleted-list">
              {riskFailFactors.map((reason, i) => {
                return (
                  <li data-testid={`inquiry-fico-reason-${i}`} key={i}>
                    {reason[0].toUpperCase() + reason.slice(1).toLowerCase()}
                  </li>
                );
              })}
            </ul>
          </InquiryFormRow>
        </>
      )}
      <InquiryFormRow>
        <div data-testid="inquiry-fico-row-3">
          If you have any questions regarding your credit score, please contact Experian at the phone number above.
        </div>
      </InquiryFormRow>
      <InquiryFormRow>
        <div data-testid="inquiry-fico-row-4">
          If you have any questions regarding this letter, please contact us at homeowners@hometap.com.
        </div>
      </InquiryFormRow>
    </>
  );
};

export default InquiryFico;
