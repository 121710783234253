import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ContactInfo from './contact-info/ContactInfo';
import FAQList from 'components/FAQList/FAQList';
import {
  aboutMyDashboardFAQs,
  aboutHomeEquityFAQs,
  aboutMyInvestmentFAQs,
  settlingMyInvestmentFAQs,
  accountManagementFAQs,
  HED_USER_SUPPORT_NUMBER,
  PRODUCT_SUPPORT_EMAIL,
} from 'apps/dashboard/constants';
import useUserTracksContext from 'hooks/useUserTracksContext';
import Footer from 'components/Footer/Footer';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import PageTitle from 'components/PageTitle';
import InvestmentSupportCards from './InvestmentSupportCards';
import useInvestmentSupportContacts from 'apps/dashboard/hooks/useInvestmentSupportContacts';
import generalDog from '../../../../images/dashboard/general-contact-dog.png';
import './DashboardSupport.scss';

const DashboardSupport = () => {
  const { tracks, loading } = useUserTracksContext();
  const flags = useFlags();

  const contacts = useInvestmentSupportContacts(tracks);

  // On initial page load, loading is false before tracks are grabbed.
  // We don't want to redirect users before the call is fired.
  const userRole = tracks?.[0]?.membership.role || 'hed' || null;
  const userHasTracks = tracks?.length > 0;

  const hedUserContactInfo = {
    headshot_url: generalDog,
    email: PRODUCT_SUPPORT_EMAIL,
    phone_number: flags.showPhoneNumber ? HED_USER_SUPPORT_NUMBER : undefined,
  };

  const DashboardSupportContactTitle = (
    <h2>{contacts.length === 0 ? 'Need Support? We can help!' : 'My Hometap Team'}</h2>
  );

  return (
    <ScrollToTop>
      <SectionLoadingWrapper loading={loading || !userRole}>
        <div className="DashboardSupport">
          <div className="DashboardSupportContact">
            {userHasTracks && (
              <div className="DashboardSupportContactContainer PageBodyCenteredContainer">
                <PageTitle title="Get help" className="mt-8" />
                {DashboardSupportContactTitle}
                <InvestmentSupportCards contacts={contacts} />
              </div>
            )}
            {!userHasTracks && userRole === 'hed' && (
              <div className="DashboardSupportContactContainer PageBodyCenteredContainer">
                <PageTitle title="Get help" className="mt-8" />
                {DashboardSupportContactTitle}
                <ContactInfo
                  card_title="Contact Us"
                  contact_information={hedUserContactInfo}
                  card_description="Have questions about the Home Equity Dashboard? Reach out to us at the email below."
                />
              </div>
            )}
          </div>

          <div className="DashboardSupportFAQ">
            <div className="DashboardSupportFAQContainer PageBodyCenteredContainer">
              <h2>Frequently Asked Questions</h2>
              {userHasTracks && <FAQList heading="About my Investment" faqs={aboutMyInvestmentFAQs} />}
              {userHasTracks && <FAQList heading="Settling my Investment" faqs={settlingMyInvestmentFAQs} />}
              <FAQList heading="About Home Equity" faqs={aboutHomeEquityFAQs} />
              {userRole !== 'co-applicant' && <FAQList heading="About my Dashboard" faqs={aboutMyDashboardFAQs} />}
              <FAQList heading="Account management" faqs={accountManagementFAQs} />
              <Footer />
            </div>
          </div>
        </div>
      </SectionLoadingWrapper>
    </ScrollToTop>
  );
};

export default DashboardSupport;
