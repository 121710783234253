import React from 'react';
import { Button } from '@hometap/htco-components';
import { getDashboardUrl } from 'utils/links';
import { CONTINUE_BUTTON_ID } from '../../../../progressive-inquiry/constants/progressiveInquiryConstants';

const InquiryGetStartedButton = ({ isAdditionalTrack, onNext }) => (
  <>
    {isAdditionalTrack ? (
      <Button href={getDashboardUrl()}>Return to dashboard</Button>
    ) : (
      <>
        <Button onClick={() => onNext()} id={CONTINUE_BUTTON_ID} className="InquirySignInButton">
          Get started
        </Button>
      </>
    )}
  </>
);

export default InquiryGetStartedButton;
