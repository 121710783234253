import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Collapse, Fade } from '@mui/material';
import { useWindowSize, Button } from '@hometap/htco-components';
import { LienCard, RenoCard, InvestmentCard } from './HomeInfoCards';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { editHomeProfileUrl } from 'utils/links';
import { EQUITY_SCENARIO_LIEN_TYPE } from 'apps/dashboard/constants';
import './PropertyDetailsPanel.scss';

const PropertyDetailsPanel = ({
  liens = [],
  renovations = [],
  investment = {},
  toggleChartRenovations,
  toggleChartHEI,
  exploreOptionsCtaClick,
  showHEI,
}) => {
  const { deleteFutureScenario, isFutureView, futureScenario } = useEquityScenarioContext();
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const [activeCardId, setActiveCardId] = useState(null);

  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize('sm');

  const navigate = useNavigate();

  const handleDetailsCTAClick = () => {
    if (!isFutureView) {
      navigate(editHomeProfileUrl());
    } else {
      deleteFutureScenario(futureScenario?.id);
    }
  };

  const renderHeader = () => {
    return isMobile ? (
      <div
        className="PropertyDetailsMobileHeader"
        onClick={() => setIsMobileVisible(!isMobileVisible)}
        onKeyDown={() => setIsMobileVisible(!isMobileVisible)}
        role="button"
        tabIndex="0"
      >
        {isMobileVisible ? 'Hide Property Details -' : 'View Property Details +'}
      </div>
    ) : (
      <div className="PropertyDetailsTitle">Property details</div>
    );
  };

  const financeOptions = [
    EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_LOAN,
    EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_CREDIT,
    EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT,
  ];

  const scenarioContainsFinanceOptions = isFutureView && liens?.some(lien => financeOptions.includes(lien?.lien_type));

  return (
    <>
      <div className="PropertyDetailsPanel">
        {renderHeader()}
        <Collapse in={isMobileVisible || !isMobile}>
          <div>
            <Fade in timeout={600}>
              <div className="PropertyDetailsCardsWrapper">
                <div className="PropertyDetailsCards">
                  {liens.map(lien => {
                    const showCTA =
                      scenarioContainsFinanceOptions && lien?.lien_type !== EQUITY_SCENARIO_LIEN_TYPE.MORTGAGE;
                    const originationDate = new Date(lien.origination_date);
                    const maturityDate = new Date(lien.origination_date);
                    maturityDate.setMonth(originationDate.getMonth() + lien.term_length_in_months);

                    if (lien.lien_type === EQUITY_SCENARIO_LIEN_TYPE.HOME_EQUITY_INVESTMENT) {
                      const investment = {
                        investment_amount: lien.original_balance,
                        effective_date: originationDate.toISOString().slice(0, 10),
                        maturity_date: maturityDate.toISOString().slice(0, 10),
                      };
                      return (
                        <InvestmentCard
                          key={lien.id}
                          investment={investment}
                          cta={showCTA ? 'Explore other financing options' : null}
                          toggleChartHEI={toggleChartHEI}
                          exploreOptionsCtaClick={exploreOptionsCtaClick}
                          showHEI={showHEI}
                          cardId={lien.id}
                          activeCardId={activeCardId}
                          setActiveCardId={setActiveCardId}
                        />
                      );
                    }
                    return (
                      <LienCard
                        key={lien.id}
                        lien={lien}
                        cta={showCTA ? 'Explore other financing options' : null}
                        exploreOptionsCtaClick={exploreOptionsCtaClick}
                        cardId={lien.id}
                        activeCardId={activeCardId}
                        setActiveCardId={setActiveCardId}
                      />
                    );
                  })}
                  {isFutureView &&
                    renovations.map(renovation => {
                      return (
                        <RenoCard
                          key={renovation.id}
                          renovation={renovation}
                          toggleChartRenovations={toggleChartRenovations}
                          cta={scenarioContainsFinanceOptions ? null : 'Explore financing on your chart'}
                          exploreOptionsCtaClick={exploreOptionsCtaClick}
                          cardId={renovation.id}
                          activeCardId={activeCardId}
                          setActiveCardId={setActiveCardId}
                        />
                      );
                    })}
                  {investment && (
                    <InvestmentCard
                      investment={investment}
                      toggleChartHEI={toggleChartHEI}
                      cta={scenarioContainsFinanceOptions ? 'Explore other financing options' : null}
                      exploreOptionsCtaClick={exploreOptionsCtaClick}
                      cardId={investment.id}
                      activeCardId={activeCardId}
                      setActiveCardId={setActiveCardId}
                      showHEI={showHEI}
                    />
                  )}
                </div>
                <Button
                  className="PropertyDetailsCTA"
                  data-testid="edit-property-details-button"
                  theme="link"
                  onClick={handleDetailsCTAClick}
                >
                  {!isFutureView ? 'Edit property details' : 'Clear scenario'}
                </Button>
              </div>
            </Fade>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default PropertyDetailsPanel;
