import React from 'react';
import { Switch, Route, Redirect, BrowserRouter, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useCurrentUser from 'hooks/useCurrentUser';
import { getPortalsUrl } from 'utils/links';
import ProgressiveInquiryUI from './components/ProgressiveInquiryUI';
import { PROGRESSIVE_STEP_NAMES, PROGRESSIVE_UI_STEPS } from './constants/progressiveInquiryConstants';
import { TRUST_PILOT_SCRIPT } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';

const ProgressiveInquiryController = () => {
  const { isStaff } = useCurrentUser();

  if (isStaff) {
    window.location = getPortalsUrl('/staff/');
  }

  return (
    <>
      <Helmet>{TRUST_PILOT_SCRIPT}</Helmet>
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <Switch>
          <Route path={PROGRESSIVE_UI_STEPS[PROGRESSIVE_STEP_NAMES.PROPERTY]} exact>
            <ProgressiveInquiryUI />
          </Route>
          <Route path={'/inquiry'}>
            <Redirect
              push
              to={{
                pathname: PROGRESSIVE_UI_STEPS[PROGRESSIVE_STEP_NAMES.PROPERTY],
                search: useLocation().search, // Pass URL parameters
              }}
            />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default ProgressiveInquiryController;
