import { useEffect } from 'react';
import { NotFoundBlock, useWindowSize } from '@hometap/htco-components';
import { scrollTop } from 'utils/domScripts';
import { RENOVATION_GOAL_OPTIONS, getRenovationDetailsUI, getRenovationSummary } from './data/renovationUtils';
import RenovationCalculator from './RenovationCalculator';
import Insight from 'components/insight/Insight';
import { buildNewHEDUrl } from 'utils/links';

import './EquityRenovations.scss';

const EquityRenovationDetails = ({
  project,
  currentRenovation,
  renovationProjectOptions,
  projectTemplates,
  selectedTemplate,
  setSelectedTemplate,
  renovationSaveLoading,
  renovationSaveError,
  onChangeRenovation,
  onSaveRenovation,
  selectedGoal,
}) => {
  const { finishLevel } = getRenovationSummary(currentRenovation) || {};
  const uiData = getRenovationDetailsUI(currentRenovation);
  const { isScreenSize } = useWindowSize();
  const isImageResponsive = isScreenSize('md');

  useEffect(() => {
    scrollTop();
  }, []);

  if (!uiData) {
    return <NotFoundBlock buttonLabel="Return to Home Equity Dashboard" buttonLink={buildNewHEDUrl()} />;
  }

  const { image, copy } = uiData;

  const renovationImage = (
    <div className="RenovationImage">
      <img src={image} alt="renovation" />
    </div>
  );

  const goalCopy =
    selectedGoal !== 'other' ? RENOVATION_GOAL_OPTIONS.find(goal => goal.key === selectedGoal)?.label : undefined;

  return (
    <div>
      <div className="EquityRenovationDetailsContainer">
        <div className="EquityRenovationDetailsSidebar">
          <RenovationCalculator
            project={project}
            currentRenovation={currentRenovation}
            projectTemplates={projectTemplates}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            renovationProjectOptions={renovationProjectOptions}
            renovationSaveLoading={renovationSaveLoading}
            renovationSaveError={renovationSaveError}
            onChangeRenovation={onChangeRenovation}
            onSaveRenovation={onSaveRenovation}
          />
          {!isImageResponsive && renovationImage}
        </div>
        <div className="EquityRenovationDetailsDescription">
          <div className="EquityRenovationDetailsData">
            {goalCopy && (
              <div className="EquityRenovationDetailsGoalTitle">
                <span className="EquityRenovationDetailsGreen">{goalCopy}</span> WITH A
              </div>
            )}
            {copy.title && <h1>{copy.title}</h1>}
            {copy.description && <p dangerouslySetInnerHTML={{ __html: copy.description }}></p>}
          </div>
          {copy.subtitles[finishLevel]?.map((subtitle, i) => (
            <div key={i} className="EquityRenovationDetailsData">
              {subtitle.title && <h3>{subtitle.title}</h3>}
              {subtitle.descriptions?.map((description, j) => (
                <p
                  key={j}
                  className="EquityRenovationDetailsSubParagraph"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></p>
              ))}
              {subtitle.list?.title && <h6>{subtitle.list?.title}</h6>}
              {subtitle.list?.items && (
                <ul>
                  {subtitle.list?.items.map((item, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          {copy.tapToInsights && <Insight text={copy.tapToInsights} />}

          {isImageResponsive && renovationImage}
        </div>
      </div>
    </div>
  );
};

export default EquityRenovationDetails;
