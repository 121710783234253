import React from 'react';
import { Icon, Tooltip, useWindowSize } from '@hometap/htco-components';
import { currency } from 'utils/numbers';
import StackedBarGraph from 'components/StackedBarGraph/StackedBarGraph';
import useStackedBarGraph from 'components/StackedBarGraph/useStackedBarGraph';
import HomeGraphHatchOverlay from 'components/HomeGraph/HomeGraphHatchOverlay';
import cx from 'classnames';

const LegendHeader = ({ homeValue, homeValueDisclaimer }) => {
  return (
    <div className="mb-4 flex flex-row flex-wrap">
      <h4 className="mt-1 text-xl font-semibold sm:text-2xl">Home value:</h4>
      <div className="flex flex-row content-center items-center pr-4">
        <h4
          data-testid="legend-header-home-value"
          className={cx('ml-1 mt-1 text-xl font-semibold sm:text-2xl', {
            'text-green-medium': homeValue,
            'ml-2': !homeValue,
          })}
        >
          {homeValue ? currency(homeValue) : '--'}
        </h4>
        {homeValue && (
          <Tooltip className="ml-2 mt-1 text-sm normal-case" content={homeValueDisclaimer}>
            <Icon name="info" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const ChartLocked = () => {
  const { isScreenSize } = useWindowSize();
  return (
    <div className={'relative h-full bg-neutral-light-100'}>
      <HomeGraphHatchOverlay height="100%" />
      <Icon
        name="lock"
        size={isScreenSize('xs') ? '2x' : '3x'}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-neutral-dark-30"
      />
    </div>
  );
};
const StackedEquityGraph = ({ graphData, homeValue, locked, isInvestmentActive, legendDisclaimer }) => {
  const { getSliceData } = useStackedBarGraph(graphData);

  const getEquityTooltipContent = () =>
    isInvestmentActive
      ? 'We calculate your current equity by taking the estimated home value, then subtracting Hometap’s Share and the outstanding debt you still have on the property. The data is pulled from your latest home profile details, and this value may change if debts are added or removed from your home profile.'
      : 'We calculate your current equity by taking the estimated home value, then subtracting the outstanding debt you still have on the property. The data is pulled from your latest home profile details, and this value may change if debts are added or removed from your home profile.';

  return (
    <StackedBarGraph>
      <StackedBarGraph.Chart className="w-[50%] min-w-[54px] max-w-[110px] sm:max-w-[275px]">
        {locked ? (
          <ChartLocked />
        ) : (
          <>
            <StackedBarGraph.ChartSlice key="hometapShare" chartKey="hometapShare" {...getSliceData('hometapShare')} />
            <StackedBarGraph.ChartSlice key="equity" chartKey="equity" {...getSliceData('equity')} />
            <StackedBarGraph.ChartSlice key="debt" chartKey="debt" {...getSliceData('debt')} />
          </>
        )}
      </StackedBarGraph.Chart>
      <StackedBarGraph.Legend className="ml-4 mt-2 min-w-[250px] xs:ml-8">
        {/* TODO: PAT-1032 update conditional homeValueDisclaimer */}
        <LegendHeader homeValue={homeValue} homeValueDisclaimer={'Not a lot to say rn'} />
        <StackedBarGraph.LegendSliceTitle
          {...getSliceData('hometapShare')}
          title="Hometap's Share"
          tooltipHeader="Hometap's share"
          tooltipContent={
            'This is the portion of your home’s value that Hometap owns, and we’ll keep when you settle your Investment.'
          }
          tooltipComponent={<Icon name="info" />}
        />
        <StackedBarGraph.LegendSliceValue {...getSliceData('hometapShare')} valueFormatter={currency} />
        <StackedBarGraph.LegendSliceTitle
          {...getSliceData('equity')}
          title="Your Equity"
          tooltipHeader="Current equity"
          tooltipContent={getEquityTooltipContent()}
          tooltipComponent={<Icon name="info" />}
        />
        <StackedBarGraph.LegendSliceValue {...getSliceData('equity')} valueFormatter={currency} />
        <StackedBarGraph.LegendSliceTitle
          {...getSliceData('debt')}
          title="Debt"
          tooltipHeader="Debt"
          tooltipContent={
            'Debts on your home may include mortgages, home equity loans, and home equity lines of credit (HELOCs). We’ve pulled this value from your latest home profile details, and it may change if debts are added or removed from your home profile.'
          }
          tooltipComponent={<Icon name="info" />}
        />
        <StackedBarGraph.LegendSliceValue {...getSliceData('debt')} valueFormatter={currency} />
        <div className="mt-4 hidden xs:block">
          <p className="m-0 text-xs text-neutral-dark-75">{legendDisclaimer}</p>
        </div>
      </StackedBarGraph.Legend>
    </StackedBarGraph>
  );
};
export default StackedEquityGraph;
