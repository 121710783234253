import { Button } from '@hometap/htco-components';
import Banner from 'components/Banner/Banner';
import NewBadge from 'components/NewBadge/NewBadge';
import { Link } from 'react-router-dom-v5-compat';

const TrackDetailsCardLinks = ({ links = [] }) => {
  return (
    <Banner className="mt-6">
      <div className="align-center flex flex-col justify-center sm:flex-row">
        <div className="mb-2 mr-4 text-sm text-neutral-dark-75 sm:mb-0 sm:text-base">View:</div>
        <div className="flex flex-col pr-4 xs:flex-row">
          {links.filter(Boolean).map(({ label, link, hasUnreadDocs }) => (
            <div
              key={label}
              className="mb-2 after:mx-2 after:inline after:border-0 after:border-r after:border-solid after:border-neutral-light-100 after:content-none last:after:content-none xs:flex-row xs:after:content-[''] sm:mb-0"
            >
              <div className="inline-flex items-center">
                <Link to={link}>
                  <Button
                    theme="link"
                    className="[&.htco-isLink]:ml-0 [&.htco-isLink]:p-0 [&.htco-isLink]:text-sm sm:[&.htco-isLink]:text-base"
                    data-heap-id={hasUnreadDocs ? 'new-document-label' : undefined}
                  >
                    {label}
                  </Button>
                </Link>
                {hasUnreadDocs && <NewBadge className="ml-2" testId="new-document-label" />}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Banner>
  );
};

export default TrackDetailsCardLinks;
