import KeyValue from 'components/KeyValue/KeyValue';
import { currency } from 'utils/numbers';
import cx from 'classnames';

const HomeValKeyValue = ({ homeValue }) => {
  return (
    <KeyValue
      className="font-serif text-xl sm:text-2xl sm:leading-9"
      keyName="Home value"
      valueClassName={cx({ 'text-green-medium': homeValue, 'text-neutral-dark-100': !homeValue })}
      value={homeValue && currency(homeValue)}
    />
  );
};

export default HomeValKeyValue;
