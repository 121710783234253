import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, SelectionSet } from '@hometap/htco-components';
import { CallToAction, ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';

import './InquiryPropertyType.scss';
import {
  PAGE_TITLES,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PROGRESSIVE_STEP_NAMES,
  CONTINUE_BUTTON_ID,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import IMG_PROPERTY_TYPE from 'images/inquiry/property_type.png';
import { PREQUAL_OUTCOME, DQ_PAGES, DEQUALIFIED_PROPERTY_TYPES } from '../constants/progressiveInquiryConstants';
import { PREQUAL_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';
import { parsePrequalificationsToMap } from 'apps/progressive-inquiry/utils/parsePrequalificationOutcomes';
import env from 'utils/env';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';
import { useAsyncPatchInquiry } from '../hooks/inquiryMutations';

const PROP_TYPE_DEFAULTS = [
  {
    label: null,
    icon: 'house-singlefamily',
    value: 'sf',
  },
  {
    label: null,
    icon: 'house-multifamily',
    value: 'mf',
  },
  {
    label: null,
    icon: 'house-condo',
    value: 'co',
  },
  {
    label: null,
    description: 'A property owned by a corporation, where you own shares in the corporation.',
    icon: 'house-coop',
    value: 'cp',
  },
  {
    label: null,
    description: 'A home permanently affixed to a foundation or utilities, built off-site.',
    icon: 'house-manufactured',
    value: 'md',
  },
  {
    label: null,
    description: 'A home NOT permanently affixed to a foundation or utilities, built off-site.',
    icon: 'house-mobile',
    value: 'mh',
  },
];

const InquiryPropertyType = ({ inquiryId, form, step, onNext, propertyTypes, onDequalify, utmSource }) => {
  const { registerField, handleSubmit, formData } = form;
  const { street } = formData;
  const { results, loading, execute: updateInquiry } = useAsyncPatchInquiry();
  const [types, setTypes] = useState([]);

  const canContinueToTheNextPage = !!formData.property_type;
  canContinueToTheNextPage && scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
  const ctaText = 'Continue';
  useCTAChangedNotification(ctaText, canContinueToTheNextPage);

  useEffect(() => {
    if (results) {
      const { prequalifications } = results;
      const prequalMap = parsePrequalificationsToMap(prequalifications);
      const { PropertyTypePrequalification } = prequalMap?.prequalifications;
      const { outcome_code, additional_details: details } = PropertyTypePrequalification;

      if (outcome_code === PREQUAL_OUTCOME.FAIL) {
        const { property_type } = details;
        if (property_type === DEQUALIFIED_PROPERTY_TYPES.MOBILE) {
          onDequalify(DQ_PAGES.MOBILE_HOME);
        } else if (property_type === DEQUALIFIED_PROPERTY_TYPES.COOP) {
          onDequalify(DQ_PAGES.COOP);
        }
      } else {
        onNext(step + 1);
      }
    }
  }, [results, onNext, step, onDequalify]);

  /**
   * The list of home-property-types is pulled from the backend.
   * But the backend doesn't store all the necessary data about property types, like the icon names and descriptions.
   * Thus, we are merging the data here and only showing the property types that exist in the backend.
   * When a new property type removed from the backend it will be automatically removed from the list on frontend.
   * When a new property type added to the backend, we will need to adjust the code in this file.
   */
  useEffect(() => {
    if (propertyTypes) {
      const result = [];
      PROP_TYPE_DEFAULTS.forEach(item => {
        const { value: key, description, icon } = item;
        const foundType = propertyTypes.find(type => key === type.value);
        if (foundType) {
          const { label, value } = foundType;
          result.push({ label, description, icon, value });
        }
      });
      setTypes(result);
    }
  }, [propertyTypes]);

  const onSubmit = () => {
    const { property_type } = formData;
    const updateApi = handleSubmit(() => {
      updateInquiry(inquiryId, { property_type }, [PREQUAL_TYPES.PROPERTY_TYPE]);
    });
    updateApi();
  };

  return (
    <div className="InquiryPropertyType" data-testid="inquiry_property_type_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.PROPERTY_TYPE]}>
        {/* tvScientific script */}
        {env.isProd() && inquiryId && utmSource && (
          <script type="text/javascript">
            {(function () {
              // eslint-disable-next-line
              var p, s, d, w;
              d = document;
              w = window.location;
              p = d.createElement('IMG');
              s =
                // eslint-disable-next-line
                w.protocol +
                '//tvspix.com/t.png?&t=' +
                new Date().getTime() +
                '&l=tvscientific-pix-o-92deb6b2-a1c1-40f1-8dd6-03381eeedad7&u3=' +
                encodeURIComponent(w.href) +
                `&u1=trial_started&u4={${inquiryId}}&u5={${utmSource}}`;
              p.setAttribute('src', s);
              p.setAttribute('height', '0');
              p.setAttribute('width', '0');
              p.setAttribute('alt', '');
              p.style.setProperty('display', 'none');
              p.style.setProperty('position', 'absolute');
              p.style.setProperty('visibility', 'hidden');
              d.body.appendChild(p);
            })()}
          </script>
        )}
      </Helmet>
      <ProgressiveUITemplate>
        <ContentPanel>
          <h1>Tell us about your property</h1>
          <p className="InquiryPropertyTypeSubtitle">
            What type of property is <span>{street}?</span>
          </p>
          <div className="InquirySelectionSet">
            <SelectionSet
              required
              name="propertyUseOptions"
              labelWidth="100%"
              labelGrow={false}
              options={types}
              {...registerField(FIELDS.PROPERTY_TYPE)}
            />
          </div>
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} disabled={!canContinueToTheNextPage} onClick={onSubmit} loading={loading}>
              {ctaText}
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel
          imageSource={IMG_PROPERTY_TYPE}
          imgAlt="A house with people moving in"
          includeTrustPilot={true}
        />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryPropertyType;
