const KeyValue = ({
  keyName,
  separator = ':',
  value,
  valueClassName,
  keyClassName,
  className,
  testId,
  noValueDisplay = '--',
}) => {
  return (
    <div className={className} data-testid={testId}>
      <span className={keyClassName}>
        {keyName}
        {separator}
      </span>{' '}
      <span className={valueClassName}>{value ? value : noValueDisplay}</span>
    </div>
  );
};

export default KeyValue;
