import { useEffect } from 'react';
import usePrev from 'hooks/usePrev';
import { browserTrack } from 'utils/segment';

const useCTAChangedNotification = (ctaText, isEnabled) => {
  const prev = usePrev(isEnabled);

  useEffect(() => {
    // It does not make sense to fire this event if it's moving from undefined to disabled because it's not a change.
    if (prev === undefined && !isEnabled) {
      return;
    }
    if (prev !== isEnabled) {
      browserTrack.cTAEnablementChanged({ is_enabled: isEnabled, text: ctaText });
    }
  }, [isEnabled, prev, ctaText]);
};

export default useCTAChangedNotification;
