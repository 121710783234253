import NavAccordion from '../NavAccordion/NavAccordion';
import cx from 'classnames';
import useNavItem from '../hooks/useNavItem/useNavItem';
import { Button, IconButton } from '@hometap/htco-components';
import { triggerClickOnKeyPress } from 'utils/a11y';
import { twMerge } from 'tailwind-merge';
import usePrimaryNav from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';
import HometapLogo from 'components/HometapLogo';

const HomeTitle = ({ address, active, className, isHeader }) => {
  const streetClassName = cx('line-clamp-1', {
    'font-bold': active,
    'leading-[26px]': isHeader,
    'font-normal': !active,
    'text-sm': !isHeader,
  });

  const addressMetadataClassName = cx('m-0 line-clamp-1', {
    'text-xs': !isHeader,
    'text-sm text-neutral-dark-75 leading-6': isHeader,
    'font-normal text-neutral-dark-75': !active,
  });

  const showAddressMetadata = address.city && address.state && address.zip_code;

  return (
    <div className={className} data-testid="home-picker-menuitem">
      <div className="flex flex-col">
        <div className={streetClassName}>{address.street}</div>
        {showAddressMetadata && (
          <p className={addressMetadataClassName}>
            {address.city}, {address.state}, {address.zip_code}
          </p>
        )}
      </div>
    </div>
  );
};

const HomePickerMenuItem = ({ home, onSelectHome, selectedHome }) => {
  const active = home.id === selectedHome.id;
  const { className } = useNavItem({ active });

  return (
    <div
      role="menuitem"
      className="mb-1 cursor-pointer rounded-xl outline-none focus:outline-1 focus:outline-offset-[-6px] focus:outline-blue-100"
      tabIndex="0"
      onClick={() => onSelectHome(home.id)}
      onKeyDown={triggerClickOnKeyPress}
    >
      <HomeTitle address={home.address} active={active} className={cx(twMerge(className, 'px-4 py-3 font-normal'))} />
    </div>
  );
};

export const HomePickerMenu = ({ homes = [], onSelectHome, selectedHome, onAddNewProperty }) => {
  return (
    <div className="pb-[18px]" data-testid="home-picker-menu">
      <p className="mb-4 mt-0 whitespace-nowrap text-xs font-bold sm:mt-8">Switch homes</p>
      {homes.map(home => (
        <div className="mb-1 last-of-type:mb-0" key={home.id}>
          <HomePickerMenuItem home={home} onSelectHome={onSelectHome} selectedHome={selectedHome} />
        </div>
      ))}

      {homes.length < 10 && (
        <Button
          theme="link"
          className="!mt-2 text-sm font-bold !text-neutral-dark-75 hover:!text-blue-100 focus:!text-blue-medium-100 [&.htco-isLink]:hover:!no-underline"
          onClick={onAddNewProperty}
          data-testid="add-home-button"
        >
          + Add home
        </Button>
      )}
    </div>
  );
};

const HomePickerDropdownDesktop = ({
  homes = [],
  onSelectHome,
  selectedHome,
  onAddNewProperty,
  onToggleOpenNavSection,
}) => {
  const { navItemsExpanded, isSidebarMovingOrClosed, isHomePickerOpen, onChangeIsHomePickerOpen } = usePrimaryNav();

  const handleToggle = isOpening => {
    if (isOpening) {
      onChangeIsHomePickerOpen(true);
      onToggleOpenNavSection(null);
    } else {
      onChangeIsHomePickerOpen(false);
    }
  };

  const handleSelectHome = homeId => {
    onSelectHome(homeId);
    onChangeIsHomePickerOpen(false);
  };

  return (
    <NavAccordion
      open={isHomePickerOpen}
      onToggle={handleToggle}
      canExpand={navItemsExpanded}
      className="w-full"
      dataTestId="home-picker-desktop"
    >
      <div className="flex gap-3">
        <HometapLogo />
        <NavAccordion.Toggle
          title={
            <HomeTitle
              className={cx({ 'mr-26': isSidebarMovingOrClosed })}
              isHeader
              address={selectedHome.address}
              active
            />
          }
        />
      </div>

      <NavAccordion.Content>
        <HomePickerMenu
          homes={homes}
          onSelectHome={handleSelectHome}
          selectedHome={selectedHome}
          onAddNewProperty={onAddNewProperty}
        />
      </NavAccordion.Content>
    </NavAccordion>
  );
};

const HomePickerDropdownMobile = ({
  homes = [],
  onSelectHome,
  selectedHome,
  onAddNewProperty,
  onIconClick,
  onToggleOpenNavSection,
}) => {
  const { isHomePickerOpen, onChangeIsHomePickerOpen } = usePrimaryNav();

  const handleToggle = isOpening => {
    if (isOpening) {
      onChangeIsHomePickerOpen(true);
      onToggleOpenNavSection(null);
    } else {
      onChangeIsHomePickerOpen(false);
    }
  };

  const handleSelectHome = homeId => {
    onSelectHome(homeId);
    onChangeIsHomePickerOpen(false);
  };

  return (
    <NavAccordion dataTestId="home-picker-mobile" open={isHomePickerOpen} onToggle={handleToggle}>
      <div className="flex items-center">
        <div className="border-0 border-r border-solid border-neutral-light-100 p-6 outline-offset-[-1px]">
          <IconButton className="m-0 h-auto w-auto text-neutral-dark-75" icon="cancel" onClick={onIconClick} />
        </div>
        <div className="w-full p-4 pr-6">
          <NavAccordion.Toggle
            title={<HomeTitle isHeader address={{ street: selectedHome.address.street }} active className="p-1" />}
          />
        </div>
      </div>

      <NavAccordion.Content>
        <div className="relative -translate-y-px border-0 border-t border-solid border-neutral-light-100 p-4">
          <HomePickerMenu
            homes={homes}
            onSelectHome={handleSelectHome}
            selectedHome={selectedHome}
            onAddNewProperty={onAddNewProperty}
          />
        </div>
      </NavAccordion.Content>
    </NavAccordion>
  );
};

const dropdownVariants = { Desktop: HomePickerDropdownDesktop, Mobile: HomePickerDropdownMobile };

export default dropdownVariants;
