import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button } from '@hometap/htco-components';
import houseDesktopImg from 'images/inquiry/house_with_palm_trees.png';
import { showNotification } from 'utils/toasts';
import { sendPasswordInitializationLink } from 'apps/dashboard/data/sendPasswordInitializationLink';
import { PAGE_TITLES, PROGRESSIVE_STEP_NAMES } from '../constants/progressiveInquiryConstants';
import { ContentPanel, ImageContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import './InquirySetPassword.scss';

const InquirySetPassword = ({ form }) => {
  const { formData } = form;
  const { email } = formData;

  const onResendEmail = async () => {
    try {
      await sendPasswordInitializationLink(email);
      showNotification({
        type: 'success',
        title: `Email sent to ${email}`,
      });
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Unable to send email. Please try again.',
      });
    }
  };

  return (
    <div className="SetPassword" data-testid="inquiry_set_password_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.SET_PASSWORD]} />
      <ProgressiveUITemplate>
        <ContentPanel>
          <>
            <h1>Check your email</h1>
            <div className="SetPasswordSubtitle" data-testid="sign-up-email">
              Click the link sent to <span className="SetPasswordEmail">{email}</span> to create your account (and make
              sure your Investment Manager knows how to contact you).
            </div>
            <div className="SetPasswordSubtitle">
              Can’t find the link?{' '}
              <Button
                className="SetPasswordResendEmail"
                onClick={onResendEmail}
                data-testid="resend-email-button"
                theme="link"
              >
                Resend email
              </Button>
            </div>
          </>
        </ContentPanel>
        <ImageContentPanel imgAlt="yellow house" imageSource={houseDesktopImg} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquirySetPassword;
