import { twMerge } from 'tailwind-merge';
import cx from 'classnames';

const PrimaryLayoutGrid = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        cx(
          'relative m-auto grid w-full max-w-[1552px] grid-cols-4 gap-6 px-4 sm:grid-cols-8 sm:px-8 md:grid-cols-12 md:px-12',
          className,
        ),
      )}
    >
      {children}
    </div>
  );
};

export default PrimaryLayoutGrid;
