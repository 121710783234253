import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiWithAuth from 'utils/api';
import sentry from 'utils/sentry';

const useReadDocument = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ documentId }) => {
      return apiWithAuth.v2.post(`/documents/${documentId}/actions/read/`);
    },
    onError: error => {
      const {
        response: { status, statusText },
        message,
        config,
      } = error;
      const requestUrl = config?.url;

      sentry.logError(`Failed to read document.`, {
        tags: {
          statusCode: status,
          statusText: statusText,
        },
        extra: {
          errorMessage: message,
          requestUrl: requestUrl,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['track.documents']);
    },
  });
};

export default useReadDocument;
