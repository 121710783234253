import React, { useEffect } from 'react';

import {
  Button,
  Container,
  useForm,
  FormError,
  SlideDown,
  Paper,
  Icon,
  useAsync,
  useBoolState,
  SSNInput,
} from '@hometap/htco-components';

import LegalLink from 'components/LegalLink/LegalLink';

import { verifySSN } from '../data/coApplicantRequests';
import ExceededVerificationAttempts from './ExceededVerificationAttempts';
import { getPortalsUrl } from 'utils/links';

const SSNVerification = ({ trackId, onSubmitVerificationSuccess }) => {
  const { registerField, handleSubmit, formData, setErrors } = useForm();
  const { value: isWhyWeNeedVisible, toggle: toggleIsWhyWeNeedVisible } = useBoolState();
  const { error: ssnRequestError, loading, execute } = useAsync(verifySSN, { immediate: false });

  const hasExceededVerificationAttempts = ssnRequestError?.response?.data.key === 'exceeded';

  const onSubmit = async e => {
    const { ssn } = formData;
    const hasVerified = await execute(trackId, { ssn });

    if (hasVerified) {
      onSubmitVerificationSuccess();
    }
  };

  useEffect(() => {
    if (ssnRequestError?.response.status === 403 && !hasExceededVerificationAttempts) {
      window.api.redirect(getPortalsUrl());
      return () => null;
    }

    if (ssnRequestError) {
      setErrors('ssn', 'Please re-enter this information', { show: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssnRequestError]);

  return !hasExceededVerificationAttempts ? (
    <Container className="CoApplicantSSNVerification">
      <div className="CoApplicantSSNVerificationHeading">
        <h1>Please verify your identity</h1>
        <p className="mb-4">Please enter your social security number below so we can confirm your identity.</p>
      </div>
      <Button className="!mr-auto" theme="link" onClick={toggleIsWhyWeNeedVisible}>
        Why do we need this information?
      </Button>
      <SlideDown visible={isWhyWeNeedVisible}>
        <Paper pad={2} theme="medium" className="CoApplicantSSNVerificationSlideContent">
          As a co-applicant, your social security number was shared by the primary applicant when they filled out their
          application. Please help us make sure your information stays safe — and that you’re you! — by providing your
          social security number.
        </Paper>
      </SlideDown>
      {ssnRequestError?.response?.data?.ssn && <FormError standalone error={ssnRequestError.response.data.ssn[0]} />}
      <form onSubmit={handleSubmit(onSubmit)} className="CoApplicantSSNVerificationForm" autoComplete="off">
        <div className="CoApplicantSSNVerificationFormInputContainer">
          <SSNInput placeholder="e.g ###-##-####" label="Social Security Number" required {...registerField('ssn')} />
        </div>
        <div className="CoApplicantSSNVerificationFinePrint">
          <Icon name="lock" />
          <p className="CoApplicantSSNVerificationFinePrintCopy">
            All of your data is AES-256 encrypted and stored securely. We do not sell your personally identifiable
            information. For more information about our security, please check out our{' '}
            <LegalLink text="Privacy Policy" section="privacy-policy" />
          </p>
        </div>
        <Button type="submit" loading={loading}>
          Let's go!
        </Button>
      </form>
    </Container>
  ) : (
    <ExceededVerificationAttempts error={ssnRequestError} />
  );
};

export default SSNVerification;
