const SettlingHeiWithHomeSale = `<h1>Settling Your Home Equity Investment with a Home Sale</h1>
<p>While there are multiple ways to settle your Hometap Investment aside from selling your home, using the sale proceeds to settle the Investment is the most common method among our homeowners to date &mdash; some chose this option at the outset while others decide it&rsquo;s the best choice for them along the way. And, as with all of our settlement options, we want to ensure that your experience is as smooth as possible.</p>
<h2>Step 1: Weigh Your Options</h2>
<p>Before you make a decision, it&rsquo;s worth considering all of your settlement options to confirm that selling your home is the best choice. As a reminder, these are:</p>
<ul>
<li>A home sale</li>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-cash-savings" rel="noreferrer noopener">A buyout with savings</a></li>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-home-loan-or-heloc" rel="noreferrer noopener">A home equity loan or home equity line of credit (HELOC)</a></li>
<li><a href="/dashboard/resources/settling-your-home-equity-investment-with-a-refinance" rel="noreferrer noopener">A refinance</a></li>
</ul>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F313824c49b97ddcbfc9ec183b499a31cce4e2870-1000x295.png&w=2048&q=75"/>
<h2>Step 2: Find Out What You Owe &mdash; And Give Yourself Enough Time</h2>
<p>The first step in the settlement process is to find out the approximate amount you owe (known as the &ldquo;Hometap Share&rdquo;). You can check your settlement amount and the Hometap Share at any time via your Settlement Calculator, which you can access through your Hometap account.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F4aeec4632c743b1e88e110fd8d1bdb46ea4e73f6-1024x746.gif&w=2048&q=75"/>
<p>If you decide that a home sale is the way you want to settle, the most important thing to remember is that you&rsquo;ll want to give yourself plenty of time to complete the settlement process. Prior to your home&rsquo;s sale, we recommend getting in touch with us six weeks before you plan to settle your investment. Of course, delays can happen when it comes to selling your home, but the more time you provide, the more smoothly things will go.</p>
<p>If you plan to settle at the end of the ten year term, you can find your settlement date by selecting the Settlement Calculator.</p>
<p>The entire settlement process from start to finish can take about six weeks, but there are a lot of factors at play that can shorten or lengthen this timeline. The closing date and the method for submitting funds are just some of the ways this timeline can be impacted.</p>
<h2><strong>Step 3: Initiate Your Settlement</strong></h2>
<p>To begin settling your Investment, you&rsquo;ll first want to reach out to the Investment Support team by emailing&nbsp;<a href="mailto:homeowners@hometap.com" target="_blank" rel="noreferrer noopener">homeowners@hometap.com</a>&nbsp;or calling 1-617-604-6985 to request a Settlement Statement.</p>
<p>You&rsquo;ll need to provide the Investment Support team with a good-through date for your Settlement Statement &mdash; we suggest at least 30 days &mdash;&nbsp;along with your preferred settlement method (in this case, a home sale).</p>
<p>Next, we&rsquo;ll send you a &ldquo;Binding Offer Notice&rdquo; form to complete and return to us. This is also accessible to download in your account.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2Ff49d7e801d80d1fbfc0938a0c7b5af266ac41830-1000x413.png&w=2048&q=75"/>
<p>Note that we require the sale price of your home to be at least 90% of the fair market value (FMV). If it doesn&rsquo;t meet this threshold, our share will be based on the FMV amount rather than the sale amount. Determining the FMV is most often done virtually, but we may require a physical appraisal in some cases &mdash; these include scenarios when there&rsquo;s a discrepancy between the homeowner&rsquo;s value estimate and our automated valuation model figure, or the home isn&rsquo;t eligible for a virtual appraisal.</p>
<p>As a reminder, if a physical appraisal is required, you will responsible for the appraisal fee, as stated in your initial contract.</p>
<p>Once you&rsquo;ve accepted an offer, you&rsquo;ll need to send us a copy of the executed purchase and sale agreement. We&rsquo;ll generate a settlement statement for your Investment that includes a settlement amount good for 30 days, wiring instructions, and additional information about the lien release process.</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F54faaf3cf7b24b04dfb3774b477059c7facd3c77-1000x413.png&w=2048&q=75"/>
<p>Then, you&rsquo;ll receive your final payoff amount from our Investment Support team via email. This will include directions for submitting your payment &mdash; you have a variety of different options to choose from here:</p>
<ul>
<li>A&nbsp;<strong><a href="https://www.consumerfinance.gov/ask-cfpb/what-is-a-wire-transfer-en-1163/" target="_blank" rel="noreferrer noopener">wire transfer</a>&nbsp;</strong>is an electronic method for sending money, and can be completed at a bank or cash office. This is often the preferred choice for homeowners because it&rsquo;s the quickest.</li>
<li>A<strong>&nbsp;<a href="https://www.pnc.com/insights/personal-finance/spend/cashiers-check-vs-certified-check.html" target="_blank" rel="noreferrer noopener">certified check</a>&nbsp;</strong>is a check drawn from your personal funds that is guaranteed by a bank after they confirm you have a sufficient amount of money.</li>
<li>A&nbsp;<strong><a href="https://www.cnn.com/cnn-underscored/money/what-is-a-cashiers-check" target="_blank" rel="noreferrer noopener">cashier&rsquo;s check</a>&nbsp;</strong>is also guaranteed by a bank, but in this case, the bank takes the funds, holds them in escrow, and prints a check against those funds.</li>
<li>A<strong>&nbsp;<a href="https://www.experian.com/blogs/ask-experian/what-is-money-order/" target="_blank" rel="noreferrer noopener">money order</a></strong>&nbsp;is a secure way to send a payment up to $1,000, so this is an option&nbsp;<strong>only if you have a settlement amount under $1,000</strong>. Money orders also won&rsquo;t bounce and have a quick timeline.</li>
</ul>
<p>Once your payment has been received, a member of the Hometap team will reach out to you to confirm and file to remove the lien on your home. The time it takes to complete these releases varies, but ranges from 7 to 30 days. You&rsquo;ll also receive an official letter that notifies you that your Investment has been settled.</p>
<p>Let&rsquo;s recap. Once you&rsquo;ve determined you&rsquo;ll settle your home equity investment with the proceeds of your home sale, the steps are typically as follows:</p>
<img src="https://www.hometap.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fhmaiuprw%2Fproduction%2F0ffdd655de9d60097ade58dbf8d23f33ca47765f-1000x1425.png&w=2048&q=75"/>
<h2>Continue to Make the Most of Homeownership</h2>
<p>We&rsquo;re here for you during your entire homeownership journey,&nbsp;even after you&rsquo;ve settled your Investment. You&rsquo;ll continue to have free access to your Home Equity Dashboard so you can keep track of your home&rsquo;s equity and value, get maintenance tips, calculate renovations for your new home, and more &mdash; so you can get the most out of homeownership and grow your largest asset.</p>`;

export default SettlingHeiWithHomeSale;
