import { Badge } from '@hometap/htco-components';
import cx from 'classnames';

const NewBadge = ({ testId, className }) => {
  return (
    <Badge className={cx('[&.htco-Badge]:left-0', className)} label="NEW" theme="primaryGreen15" data-testid={testId} />
  );
};

export default NewBadge;
