import { useNavigate } from 'react-router-dom-v5-compat';
import { Toggle, useWindowSize } from '@hometap/htco-components';
import { EQUITY_VIEW } from '../../../constants';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { buildHomeProfileUrl } from 'utils/links';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import EquityViewSettings from '../equity-view-settings/EquityViewSettings';
import cx from 'classnames';
import './EquityViewSelector.scss';

const VIEW_OPTIONS = [
  { label: 'Today’s View', value: EQUITY_VIEW.TODAY },
  { label: 'Plan for the future', value: EQUITY_VIEW.FUTURE },
];

const EquityViewSelector = ({ className }) => {
  const { isFutureView, setIsFutureView, futureScenario } = useEquityScenarioContext();
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const navigate = useNavigate();

  const emptyFutureState = isFutureView && !futureScenario?.id;

  const onToggle = value => {
    if (value === 'today') {
      if (isFutureView) {
        setIsFutureView(false);
      }
    } else if (value === 'future') {
      // TODO: Once the Toggle component has diabled functionality, disable Toggle instead of redirecting.
      if (setupProfileLater) {
        navigate(buildHomeProfileUrl());
        return;
      }
      if (!isFutureView) {
        setIsFutureView(true);
      }
    }
  };

  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize('sm');

  return (
    <div className={cx('EquityViewSelectorContainer', className)}>
      <div className="EquityViewSelector">
        <Toggle
          options={VIEW_OPTIONS}
          onChange={onToggle}
          value={isFutureView ? EQUITY_VIEW.FUTURE : EQUITY_VIEW.TODAY}
          className="EquityViewSelectorToggle"
          labelWidth="50%"
        />

        {!isTablet && !emptyFutureState && <EquityViewSettings isInline />}
      </div>
    </div>
  );
};

export default EquityViewSelector;
