import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate, useNavigate } from 'react-router-dom-v5-compat';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import env from 'utils/env';
import { useCurrentHome } from 'hooks/useCurrentHome';
import useUserTracksContext from 'hooks/useUserTracksContext';

import { Loader } from '@hometap/htco-components';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import DynamicContentController from '../dynamic-content-controller/DynamicContentController';

const OverviewController = () => {
  const navigate = useNavigate();
  const { showOverviewPage } = useFlags();
  const { home, hasHEDAccessToCurrentHome } = useCurrentHome();
  const { tracks, loading } = useUserTracksContext();
  const { displayedHomeValuation } = useHomeValuation();

  const currentHomeTracks = tracks?.filter(track => {
    return track?.home.address.business_key === home?.address.business_key;
  });

  if (loading) return <Loader type="dot-pulse" overlay />;

  const currentHomeTracksLength = currentHomeTracks.length;

  const [track] = currentHomeTracks;

  // TODO: PAT-984 remove this check once overview page rolled out
  if (showOverviewPage === false && env.isProd()) {
    navigate(
      getNewDashboardUrl(
        currentHomeTracksLength > 0 || !hasHEDAccessToCurrentHome
          ? HOMEOWNER_DASHBOARD.INVESTMENTS
          : HOMEOWNER_DASHBOARD.HOME_EQUITY,
      ),
    );
    return <Navigate to={getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS)} />;
  }

  return (
    <PrimaryLayoutGrid>
      <div className="col-span-full">
        <DynamicContentController track={track} homeValuation={displayedHomeValuation} />
      </div>
    </PrimaryLayoutGrid>
  );
};
export default OverviewController;
