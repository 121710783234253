import React from 'react';
import CoApplicantController from 'apps/co-applicant/CoApplicantController';
import ResourcesTableController from 'apps/resources-table/ResourcesTableController';
import TrackDetailsController from 'apps/track-details/TrackDetailsController';
import QuarterlyStatementController from 'apps/quarterly-statement/QuarterlyStatementController';
import TrackSecondaryLayout from 'components/Layouts/SecondaryLayout/TrackSecondaryLayout';
import { Route, useRouteMatch } from 'react-router-dom';
import { Route as RouteV6, Routes } from 'react-router-dom-v5-compat';
import { getInvestmentDashboardUrl } from 'utils/links';
import CommonLayout from 'components/Layouts/CommonLayout';
import DocumentsUploadController from 'apps/documents/DocumentsUploadController';

const TrackDetailsRouterV6 = () => {
  const { path } = useRouteMatch();

  return (
    <Route>
      <Routes>
        <RouteV6
          exact
          path={`${path}/`}
          prev={getInvestmentDashboardUrl()}
          element={
            <TrackSecondaryLayout prevUrl={getInvestmentDashboardUrl()}>
              <TrackDetailsController />
            </TrackSecondaryLayout>
          }
        />
        <RouteV6
          exact
          path={`${path}/documents/`}
          element={
            <TrackSecondaryLayout prevTrackUrl="/">
              <ResourcesTableController />
            </TrackSecondaryLayout>
          }
        />
        <RouteV6 path={`${path}/co-applicant`} element={<CoApplicantController />} />
        <RouteV6
          path={`${path}/investments/:investmentId/statements/:statementId`}
          element={<QuarterlyStatementController />}
        />
        <RouteV6
          path={`${path}/documents/:kind`}
          element={
            <CommonLayout>
              <DocumentsUploadController />
            </CommonLayout>
          }
        />
      </Routes>
    </Route>
  );
};

export default TrackDetailsRouterV6;
