import { MuiTextInput, Button, Grid, InputMask } from '@hometap/htco-components';
import { useState } from 'react';
import chartImg from 'images/equity-planner/dashboard/missing-chart.png';
import chartImg2x from 'images/equity-planner/dashboard/missing-chart-2x.png';
import { useSelfReportedHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';

const ValueMissingPanel = () => {
  const { home } = useCurrentHome();
  const update = useSelfReportedHomeValuationMutation(home);
  const [value, setValue] = useState('');

  const onChange = value => setValue(parseInt(value));
  const onClick = async () => await update.mutateAsync(value);

  return (
    <div className="ValueMissing">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid sm={12} md={6} lg={6} className="MissingLeft">
          <h1>
            We’re having some trouble fetching your <span>home value</span>
          </h1>
          <p>
            We were unable to calculate your home value, but you still can use the Home Equity Dashboard! To continue,
            please provide us with an estimate of your current home value.
          </p>
          <div className="MissingInput">
            <MuiTextInput
              value={value}
              onChange={onChange}
              width="280px"
              label="Current Home Value"
              type="number"
              inputMode="decimal"
              data-testid="current-home-value"
              mask={InputMask.MONEY_USD_MASK}
              startAdornment="$"
              unmask="typed"
              required
            />
          </div>
          <div className="MissingAction">
            <Button width="280px" onClick={onClick} disabled={!value} data-testid="save-home-value-button">
              Save home value
            </Button>
          </div>
        </Grid>
        <Grid sm={12} md={6} lg={6} alignItems="center" className="MissingRight">
          <img src={chartImg} srcSet={`${chartImg2x} 2x`} alt="missing chart" className="MissingImg" />
        </Grid>
      </Grid>
    </div>
  );
};

export default ValueMissingPanel;
