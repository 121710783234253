import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

const CardWrapper = ({ children, className }) => {
  return (
    <div className={twMerge(cx('rounded-xl bg-white p-4 shadow-[0_2px_14px_rgba(32,53,104,0.08)] xs:p-6', className))}>
      {children}
    </div>
  );
};

export default CardWrapper;
