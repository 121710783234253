import React, { useContext, useEffect } from 'react';
import { useAsync } from '@hometap/htco-components';
import { fetchTracks, fetchPendingTracks } from 'apps/dashboard/data';
import useCurrentUser from './useCurrentUser';

const Context = React.createContext({});

// Hook to get the current users tracks, returns "contextData" below
const useUserTracksContext = () => useContext(Context);
export default useUserTracksContext;

// Provider which fetches the current users tracks and passes that down through context.
export const UserTracksProvider = ({ children }) => {
  const { user } = useCurrentUser();
  const { loading: isTracksLoading, results, error, execute } = useAsync(fetchTracks, { defaultLoading: true });
  const {
    execute: executeFetchPendingTracks,
    results: pendingTracks,
    loading: isPendingTracksLoading,
  } = useAsync(fetchPendingTracks, {
    defaultLoading: true,
  });
  const tracks = results;

  // get tracks
  useEffect(() => {
    if (user) {
      execute();
    }
  }, [user, execute]);

  // get pending tracks
  useEffect(() => {
    if (user) {
      executeFetchPendingTracks();
    }
  }, [user, executeFetchPendingTracks]);

  const contextData = {
    loading: isTracksLoading || isPendingTracksLoading,
    tracks,
    error,
    refetch: execute,
    results: tracks, // just adding here for legacy reference, use tracks for new data.
    pendingTracks,
    fetchPendingTracks: executeFetchPendingTracks,
    execute, // just adding here for legacy reference, use refetch for new data.
    getTrackById: id => tracks.find(track => track.id === id),
    getTracksByAddressKey: key => {
      return tracks?.filter(track => track.home?.address?.business_key === key);
    },
    getTracksByFriendlyId: friendly_id => tracks.filter(track => track.friendly_id === friendly_id),
    getTracksByStage: stage => tracks.filter(track => track.current_stage === stage),
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
