import { useEffect } from 'react';
import { scrollTop } from 'utils/domScripts';

const ScrollToTop = ({ children }) => {
  useEffect(() => {
    scrollTop();
  }, []);

  return children;
};

export default ScrollToTop;
