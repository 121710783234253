import { useEffect, useState } from 'react';
import { Grid, SelectionSet, Button } from '@hometap/htco-components';
import { difference } from 'lodash';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { LIEN_TYPE_SELECTIONS } from 'apps/dashboard/constants/buildYourProfile';
import buildProfileLandingImg from 'images/dashboard/build-profile-house.jpg';
import './SelectDebts.scss';
import { useCurrentHome } from 'hooks/useCurrentHome';

const SelectDebts = ({ onNext, onBack, form, headerText = 'Do you have any new debt attached to your property?' }) => {
  const { formData, registerField, updateFormData } = form;
  const { liens: selectedLiens } = formData;
  const fieldProps = registerField('liens');
  const [disableNextButton, setDisableNextButton] = useState(true);

  const { home } = useCurrentHome();
  const { currentScenario, getEquityScenarioLiensAsync, deleteLien } = useEquityScenarioContext();

  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;
  const getEquityScenarioLiens = getEquityScenarioLiensAsync?.execute;

  useEffect(() => {
    const getLiens = async () => {
      await getEquityScenarioLiens(home?.id, currentScenario?.id);
    };
    if (home?.id && currentScenario?.id) {
      getLiens();
    }
  }, [home?.id, currentScenario?.id, getEquityScenarioLiens]);

  useEffect(() => {
    setDisableNextButton(!selectedLiens?.length);
  }, [selectedLiens]);

  const removeLienFromFormData = () => {
    return Object.keys(formData).reduce((data, key) => {
      // If the field is for a lien that is currently selected, leave it in the form
      if (selectedLiens.some(lien => key.includes(lien)) || key === 'liens') {
        data[key] = formData[key];
      } else {
        // For any unselected liens, remove the associated field values form the form
        delete formData[key];

        if (!selectedLiens.includes('mortgage_loan')) {
          localStorage.setItem('is_confirmed_mortgage_loan', 'false');
        }
      }
      return data;
    }, {});
  };

  const onSelectDebtsNext = () => {
    // Determine which liens should be removed by comparing the selected liens to the liens saved to the scenario
    const scenarioLienTypes = equityScenarioLiens?.map(lien => lien.lien_type);
    const liensToRemove = difference(scenarioLienTypes, selectedLiens);

    if (!liensToRemove.length) {
      return onNext();
    }

    equityScenarioLiens?.forEach(async lien => {
      if (liensToRemove.includes(lien.lien_type)) {
        await deleteLien(currentScenario?.id, lien.id);
        const updatedFormData = removeLienFromFormData();
        updateFormData({ ...updatedFormData, liens: formData.liens });
        onNext();
        await getEquityScenarioLiensAsync.execute(home?.id, currentScenario?.id);
      }
    });
  };

  return (
    <Grid
      className="DashboardBuildProfileSelectDebts !mb-8 !mt-6"
      container
      rowSpacing={{ xs: 2 }}
      columnSpacing={{ xs: 2 }}
      data-testid="build-profile-debt-selection"
    >
      <Grid sm={12} md={6} className="DashboardBuildProfileLeftPanelWrapper">
        <div className="DashboardBuildProfileLeftPanel">
          <h3>{headerText}</h3>
          <div className="DashboardBuildProfileSubtitle">Select all that apply.</div>
          <SelectionSet
            name="liens"
            data-testid="liens"
            labelWidth="100%"
            options={LIEN_TYPE_SELECTIONS}
            align="left"
            onChange={(value, ...args) => {
              fieldProps.onChange(value, ...args);
            }}
            value={selectedLiens}
            multiple
          />
          <Grid className="SelectDebtsCTA" container alignItems="center">
            <Grid sm={12} md={6}>
              <div className="DashboardBuildProfileDebtBack">
                <Button theme="link" onClick={onBack} data-testid="back-button">
                  Back
                </Button>
              </div>
            </Grid>
            <Grid className="SelectDebtsNextGridWrapper" sm={12} md={6}>
              <div className="DashboardBuildProfileSelectDebtsNext">
                <Button size="large" disabled={disableNextButton} onClick={onSelectDebtsNext} data-testid="next-button">
                  Next
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        className="DashboardBuildProfileStepImage"
        sm={12}
        md={6}
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        <img className="DashboardBuildProfileImage" src={buildProfileLandingImg} alt="home-equity" />
      </Grid>
    </Grid>
  );
};

export default SelectDebts;
