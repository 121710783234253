import { COOKIES, STAGES } from 'apps/dashboard/constants';
import { getNewInquiryUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import { useCookies } from 'react-cookie';
import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import { useHistory } from 'react-router-dom';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { useEffect, useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { useCurrentHome } from 'hooks/useCurrentHome';

const useAccessHomeEquityCTA = () => {
  const [, setCookie] = useCookies();
  const { setSessionPropertyData } = useInitialInquiryData();
  const { tracks = [] } = useUserTracksContext();
  const history = useHistory();
  const { showTappableEquityCard } = useFlags();
  const { user } = useCurrentUser();
  const { home } = useCurrentHome();
  const { prequalRange } = useHedPrequalRange();

  const [hasTappableEquity, setHasTappableEquity] = useState(false);
  const [showCtaForInvestedUser, setShowCtaForInvestedUser] = useState(false);

  const currentHomeTracks = tracks?.filter(track => track?.home?.id === home?.id);

  useEffect(() => {
    if (!showTappableEquityCard || !home?.operationalState) {
      setHasTappableEquity(false);
      return;
    }
    const tappableEquityCardTracks = currentHomeTracks?.every(
      track =>
        track?.current_stage === STAGES.SETTLED ||
        track?.current_stage === STAGES.OFFER_DECLINED ||
        track?.current_stage === STAGES.CLOSED_LOST,
    );

    // If a user is invested, we want to show the CTA and send them to Re-Inquiry
    const isInvested = currentHomeTracks?.some(track => track?.current_stage === STAGES.INVESTED);
    if (isInvested) {
      setShowCtaForInvestedUser(true);
      return;
    }

    if (!currentHomeTracks?.length || tappableEquityCardTracks) {
      if (prequalRange?.max || 0) {
        setHasTappableEquity(true);
        return;
      }
    }
    setShowCtaForInvestedUser(false);
    setHasTappableEquity(false);
  }, [showTappableEquityCard, home, currentHomeTracks, prequalRange]);

  const showAccessEquityCta = hasTappableEquity || showCtaForInvestedUser;

  const openAccessEquityWindow = () => {
    // simplify original logic and just send users with tracks to investments tab
    if (currentHomeTracks?.length) {
      history.push(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.INVESTMENTS}`);
    }
    const ctaLink = showCtaForInvestedUser ? getNewInquiryUrl() : 'https://hometap.com/compare/hei-vs-loans';
    if (showAccessEquityCta) {
      const inquirySessionData = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        street: home?.address.street,
        unit: home?.address.unit,
        city: home?.address.city,
        state: home?.address.state,
        zip_code: home?.address.zip_code,
      };
      setCookie(COOKIES.DISABLE_PROPERTY_MODAL, true, { path: '/' });
      setSessionPropertyData(inquirySessionData);
      window.open(ctaLink, '_blank');
    }
  };

  return {
    hasTappableEquity,
    showAccessEquityCta,
    openAccessEquityWindow,
  };
};

export default useAccessHomeEquityCTA;
