import apiWithAuth from 'utils/api';
import { useQuery } from '@tanstack/react-query';
import { USER_ROLES } from 'utils/globalConstants';

const fetchInvestmentStatements = async investmentId => {
  return await apiWithAuth.v1.get(`/investments/${investmentId}/statements`);
};

const useInvestmentStatementList = ({ track, investmentId }) => {
  // There is an issue where co-app's getting a 403 because they don't have permission to view the statements
  // This is causing co-app's to get redirected to logout if thise query is made
  const isPrimary = track?.membership.role === USER_ROLES.PRIMARY;
  const statementsQuery = useQuery({
    queryFn: () => fetchInvestmentStatements(investmentId),
    queryKey: ['track.investment.statements', investmentId],
    enabled: !!investmentId && isPrimary,
  });

  return { statementsQuery, statements: statementsQuery?.data || [] };
};

export default useInvestmentStatementList;
