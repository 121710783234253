import cx from 'classnames';
import { Tooltip } from '@hometap/htco-components';

import './TrackDetailsProgressStepper.scss';

const TrackDetailsProgressStepper = ({
  steps,
  activeIndex,
  tooltipData,
  stepIndicatorClassName,
  containerClassName,
  stepLabelClassName,
}) => {
  const gradients = [
    ['#70DD7D', '#70DBFF'],
    ['#70DBFF', '#B770FF'],
    ['#B770FF', '#7070FF'],
    ['#7070FF', '#3C4CFF'],
  ];

  const hasTooltipData = typeof tooltipData === 'function';

  return (
    <div className={cx('TrackDetailsProgressStepperContainer', containerClassName)}>
      {steps.map((step, stepIndex) => {
        const isActive = stepIndex === activeIndex;
        const isDisabled = stepIndex > activeIndex;

        const formattedGradients = gradients[stepIndex % gradients.length]?.join(', ');
        const styleObj = {
          ...(!isDisabled && { backgroundImage: `linear-gradient(to right, ${formattedGradients})` }),
        };

        const stepComponent = (
          <>
            <div className={cx('TrackDetailsProgressStepperStepIndicator', stepIndicatorClassName)} style={styleObj} />
            <div className={cx('TrackDetailsProgressStepperStepLabel', stepLabelClassName)}>{step}</div>
          </>
        );

        const { content, action, position } = hasTooltipData ? tooltipData(step) : {};

        return (
          <Tooltip
            content={content}
            action={hasTooltipData ? action : 'none'}
            position={position}
            className={cx('TrackDetailsProgressStepperStepContainer', { isActive, isDisabled })}
            key={stepIndex}
          >
            {stepComponent}
          </Tooltip>
        );
      })}
    </div>
  );
};

export default TrackDetailsProgressStepper;
