import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { fetchHomeValuation, updateHome, getSelfReportedHomeValuation } from 'apps/dashboard/data/homeEquityApi';

import { useCurrentHome } from 'hooks/useCurrentHome';

const useHomeValuationQuery = home => {
  const queryKey = ['home.valuation', home?.id];
  return useQuery({
    queryFn: async () => (await fetchHomeValuation(home?.id)) || {},
    queryKey,
    enabled: !!home,
    retry: (_, error) => error?.response?.status !== 404,
  });
};

const useSelfReportedHomeValuationQuery = home => {
  const queryKey = ['home.self-reported-valuation', home?.id];
  return useQuery({
    queryFn: async () => await getSelfReportedHomeValuation(home.id),
    queryKey,
    enabled: !!home,
  });
};

const getUpdatedSelfValuationObj = value => {
  const numberValue = value !== null ? Number(value) : null;
  return { value: numberValue, as_of_date: value ? new Date().toISOString() : null };
};

const updateSelfReportedHomeValuation = async (homeId, value) => {
  const { value: updatedValue, as_of_date } = getUpdatedSelfValuationObj(value);

  return await updateHome(homeId, {
    homeowner_estimated_home_value: updatedValue,
    homeowner_estimated_home_value_as_of: as_of_date,
  });
};

export const useSelfReportedHomeValuationMutation = home => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async value => await updateSelfReportedHomeValuation(home.id, value),
    onSuccess: updatedSelfValue => {
      const { value, as_of_date } = getUpdatedSelfValuationObj(updatedSelfValue.homeowner_estimated_home_value);

      queryClient.setQueryData(['home.self-reported-valuation', home?.id], valuationData => {
        const valuation = value
          ? {
              ...valuationData.valuation,
              value,
              as_of_date,
            }
          : null;

        return {
          valuation,
          is_editable: valuationData.is_editable,
        };
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries(['home.self-reported-valuation', home?.id]);
    },
  });
};

const Context = React.createContext({});

export const useHomeValuation = () => React.useContext(Context);

export const HomeValuationProvider = ({ children }) => {
  const { home } = useCurrentHome();

  const homeValuationQuery = useHomeValuationQuery(home);
  const selfReportedValuationQuery = useSelfReportedHomeValuationQuery(home);

  const isLoading = homeValuationQuery.isLoading || selfReportedValuationQuery.isLoading;
  const selfReportedHomeValuation = selfReportedValuationQuery?.data || {};
  const homeValuation = homeValuationQuery?.data || {};

  const displayedHomeValuation = selfReportedHomeValuation?.valuation
    ? selfReportedHomeValuation.valuation
    : homeValuation;
  return (
    <Context.Provider value={{ homeValuation, selfReportedHomeValuation, displayedHomeValuation, isLoading }}>
      {children}
    </Context.Provider>
  );
};
