import React, { useMemo } from 'react';
import { Grid } from '@hometap/htco-components';
import { getNewInquiryUrl } from 'utils/links';
import { RENOVATIONS_FINANCES_OPTIONS } from './data/renovationUtils';
import RenovationFinanceCard from './RenovationFinanceCard';
import { useNavigate } from 'react-router-dom-v5-compat';
import './RenovationFinancingSection.scss';

const RenovationFinancingSection = ({ isEligibleForHEI, onSaveRenovation }) => {
  const navigate = useNavigate();

  const financeOptions = useMemo(
    () =>
      RENOVATIONS_FINANCES_OPTIONS.map(option => {
        const { tagText, ctaButtonText, secondaryButtonText, financeCalculatorOption, secondaryButtonLink } = option;

        const onCtaButtonClick = () => window.open(getNewInquiryUrl(), '_blank');
        const onSecondaryButtonClick =
          financeCalculatorOption && (financeCalculatorOption !== 'HEI' || isEligibleForHEI)
            ? async () => {
                try {
                  onSaveRenovation && (await onSaveRenovation());
                  navigate('/dashboard/home-equity/finance', { option: financeCalculatorOption });
                } catch {
                  // Error will be displayed using useAsync error field
                }
              }
            : () => window.open(secondaryButtonLink, '_blank');

        return {
          ...option,
          tagText: typeof tagText === 'function' ? tagText(isEligibleForHEI) : tagText,
          ctaButtonText: typeof ctaButtonText === 'function' ? ctaButtonText(isEligibleForHEI) : ctaButtonText,
          secondaryButtonText:
            typeof secondaryButtonText === 'function' ? secondaryButtonText(isEligibleForHEI) : secondaryButtonText,
          onCtaButtonClick,
          onSecondaryButtonClick,
        };
      }),
    [isEligibleForHEI, onSaveRenovation, navigate],
  );

  return (
    <div className="RenovationFinancingSection">
      <div className="px-4 sm:px-8 md:px-12">
        <h2 className="RenovationFinancingSectionTitle">
          How can you <span className="RenovationFinancingSectionTitleHighlight">finance</span> your renovation?
        </h2>
        <div className="RenovationFinancingSectionSubTitle">
          Explore the most common methods for covering the costs of home improvements and upgrades.
        </div>

        <div className="RenovationFinancingSectionOptions">
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 2, md: 2, lg: 3 }}
            columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 3 }}
            justifyContent="center"
          >
            {financeOptions.map((option, i) => (
              <Grid xs={12} sm={6} md={6} lg={4} key={`${option.title}-${i}`}>
                <RenovationFinanceCard
                  label={option.title}
                  description={option.description}
                  ctaButtonText={option.ctaButtonText}
                  secondaryButtonText={option.secondaryButtonText}
                  tag={option.tagText}
                  onCtaButtonClick={option.onCtaButtonClick}
                  onLearnMoreClick={option.onSecondaryButtonClick}
                  fees={option.fees}
                  timeToSettle={option.timeToSettle}
                  monthlyPayment={option.monthlyPayment}
                  interest={option.interest}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RenovationFinancingSection;
