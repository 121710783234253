import { Link } from 'react-router-dom/';
import cx from 'classnames';

import NavImageAndTitle from '../NavImageAndTitle/NavImageAndTitle';
import NavIcon from '../NavIcon/NavIcon';

import useNavItem from '../hooks/useNavItem/useNavItem';
import PropTypes from 'prop-types';

import { twMerge } from 'tailwind-merge';

const NavItemLink = ({ active, icon, title, to, onClick, hoverTitle }) => {
  const { className, isPressed, ...rest } = useNavItem({ active });

  return (
    <Link
      title={hoverTitle}
      onClick={e => {
        onClick && onClick(e);
      }}
      to={to}
      className={twMerge(
        cx(
          className,
          'inline-flex w-full outline-none hover:no-underline focus:outline-1 focus:outline-offset-[-6px] focus:outline-blue-100',
        ),
      )}
      data-testid="nav-item-link"
      {...rest}
    >
      <NavImageAndTitle titleIndicator={<NavIcon icon={icon} />} title={title} />
    </Link>
  );
};

export default NavItemLink;

NavItemLink.propTypes = {
  title: PropTypes.node,
  active: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
