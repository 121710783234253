/* eslint-disable testing-library/no-debugging-utils */
import typewriter from './analytics/segment';

/*
This is set up to help manage our segment tracking in one place.

More info can be found here:
https://github.com/HomeTapDev/client_portal/blob/develop/docs/Analytics.md

It will additionally log all segment calls if SEGMENT_LOGGING
is set to true in your .env - which is for easier development.
*/
const debug = (prefix, event, props) => {
  if (process.env.REACT_APP_SEGMENT_LOGGING) {
    console.log(prefix, event, JSON.stringify(props, null, 2));
  }
};

const getSegment = () => {
  if (window.analytics) {
    return window.analytics;
  }
  console.error('Segment Error: Cannot find window.analytics');
};

// Initialize Segment with the write key from environment variables
export const init = () => {
  return getSegment()?.load(process.env.REACT_APP_SEGMENT_KEY);
};

let DISABLED = false;
export const disable = () => {
  DISABLED = true;
};

export const browserTrackPage = props => {
  if (!DISABLED) {
    debug('SEGMENT PAGE |', 'Page Viewed', props);
    return getSegment()?.page('Page Viewed', props);
  }
};

// Explicitly send these flags along with browserIdentifyAnonymousFlag
// There are some instances where these flags might change might change and the user might
// experience two different things before and after login/signup.
// e.g. inquiry and re-inquiry for inquiryAddressUx
// We don't track this because the real fix we should be doing is getting the flag
// to stay the same for the user before and after login/signup.
export const ANONYMOUS_FLAGS = ['inquiryAddressUx', 'showInquirySignInMethods'];

// LaunchDarkly doesn't keep track of anonymous users being the same as the logged in users
// so we observed that the flags in Heap's (by way of Segment) user profile were incorrectly
// updating because LaunchDarkly was re-evaluating the flags for these users.
// This is particularly annoying for tracking flags that affect conversion to a logged in user
// because we are unable to measure the impact of the flag on the conversion.
// We exclude these flags from browserIdentify so they do not change post-login/signup.
// Instead we explicitly control sending these flags over with browserIdentifyAnonymousFlag
export const browserIdentifyAnonymousFlag = flagAndValue => {
  if (!DISABLED) {
    const traits = { flags: flagAndValue };
    debug('SEGMENT IDENTIFY |', undefined, traits);
    return getSegment()?.identify(traits);
  }
};

export const browserIdentify = (userID, traits) => {
  if (!DISABLED) {
    const traitsToSend = { ...traits };
    if (traitsToSend.flags) {
      const flags = { ...traitsToSend.flags };
      for (const flag of ANONYMOUS_FLAGS) {
        delete flags[flag];
      }
      traitsToSend.flags = flags;
    }
    debug('SEGMENT IDENTIFY |', userID, traitsToSend);
    if (userID) {
      return getSegment()?.identify(userID, traitsToSend);
    }
    return getSegment()?.identify(traitsToSend);
  }
};

/**
 * @typedef homeownerApplicationCompletedBrowserTrackProps
 * @type {object}
 * @property {string[]} flags
 * @property {string} email
 * @property {string} friendlyId
 * @property {number} numberOfCoApplicants
 */

/**
 * homeownerApplicationCompletedBrowserTrack
 * @param {homeownerApplicationCompletedBrowserTrackProps} props
 * @returns
 */
export const homeownerApplicationCompletedBrowserTrack = ({ flags, email, friendlyId, numberOfCoApplicants }) => {
  if (!DISABLED) {
    const props = {
      enabled_rollout_flags: flags,
      email,
      friendly_id: friendlyId,
      to_stage: 'applicationinreview',
      from_stage: 'applicationinprogress',
      'number_of_co-applicants': numberOfCoApplicants,
    };
    debug('SEGMENT TRACK |', 'Application Completed Browser', props);
    return getSegment()?.track('Homeowner Application Completed Browser', props);
  }
};

// Setup typewriter with debug logging and error handling.
const analyticsWithDebug = { ...window.analytics };
analyticsWithDebug.track = (...args) => {
  if (!DISABLED) {
    debug('SEGMENT TRACK | ', args[0], args[1]);
    return getSegment()?.track(...args);
  }
};

typewriter.setTypewriterOptions({
  analytics: analyticsWithDebug,
  onViolation: (message, violations) => {
    console.error(`SEGMENT VIOLATION | For - ${message.event}`, JSON.stringify(violations, null, 2));
  },
});

// Exporting this with the following exposes the jsDoc data on browserTrack
export { default as browserTrack } from './analytics/segment';

const methods = { init, disable };
export default methods;
