import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { intersection } from 'lodash';
import useTrackingData from 'apps/dashboard/hooks/useTrackingData';
import { useHomeEquityQueryParamData } from 'hooks/useQueryParamData';
import { PROFILE_INSTANCES, PROFILE_STAGES } from 'apps/dashboard/constants/buildYourProfile';
import BuildProfileLandingPage from './BuildProfileLandingPage';
import SelectDebts from './SelectDebts';
import ConfirmMortgageGaps from './ConfirmMortgageGaps';
import ConfirmDebts from './ConfirmDebts';
import {
  BUILD_PROFILE_FIELDS,
  BUILD_PROFILE_SELECTIONS_FIRST_TIME,
  SELECTION_OPTIONS,
} from 'apps/dashboard/constants/buildYourProfile';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { buildHomeProfileUrl, editHomeProfileUrl } from 'utils/links';
import useDebtForm from 'apps/dashboard/hooks/useDebtForm';
import ProgressiveFormSlides from '../ProgressiveFormSlides';
import DebtSlider from './DebtSlider';
import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';
import useProgressBar from 'hooks/useProgressBar';

const BuildProfileController = ({ instance, initialEditConfig }) => {
  const LIEN_ORDER = ['mortgage_loan', 'home_equity_loan', 'home_equity_line_of_credit', 'judgement_tax_or_other'];

  const { step: initialStage, ...initialFormData } = useHomeEquityQueryParamData(BUILD_PROFILE_FIELDS);
  const trackingData = useTrackingData();
  const navigate = useNavigate();
  const { currentScenario, getEquityScenarioLiensAsync } = useEquityScenarioContext();
  const [curStage, setCurStage] = useState(parseInt(initialStage) || initialEditConfig?.step || 1);
  const [showPrompt, setShowPrompt] = useState(false);

  // Boolean for tracking whether or not the confirmation page should display after editing liens
  const [noConfirm, setNoConfirm] = useState(initialEditConfig?.noConfirm || false);

  const { results: equityScenarioLiens } = getEquityScenarioLiensAsync;

  const filteredBuildProfileSelectionsFirstTime = BUILD_PROFILE_SELECTIONS_FIRST_TIME.filter(
    item => item.value !== SELECTION_OPTIONS.LINK,
  ).map(item => {
    if (item.value === SELECTION_OPTIONS.USE_EXISTING) {
      return {
        ...item,
        label: 'Add debts',
      };
    }
    return item;
  });

  const form = {
    ...useDebtForm(equityScenarioLiens, initialFormData, initialEditConfig),
    tracking_data: trackingData,
  };

  const updateStages = step => {
    setShowPrompt(1 < step && step < 5);
    setCurStage(step);
  };

  const [currentLienIndex, setCurrentLienIndex] = useState(0);

  const calculateProgressFromStageAndLiens = (stage, liens, lienIndex, isEdit) => {
    if (isEdit && curStage === PROFILE_STAGES.EDIT_YOUR_PROFILE) {
      return { hide: true };
    }

    const preDebtsStages = [PROFILE_STAGES.BUILD_HOME_PROFILE, PROFILE_STAGES.DEBT_SELECTION_STEP];
    const postDebtsStages = [PROFILE_STAGES.DEBT_CONFIRMATION_STEP];
    const steps = preDebtsStages.length + (liens?.length || 1) + postDebtsStages.length;

    let currStep = preDebtsStages.indexOf(stage) + 1;
    if (currStep !== 0) return { currStep, steps };

    currStep = postDebtsStages.indexOf(stage) + 1;
    if (currStep !== 0) return { currStep: currStep + liens.length + preDebtsStages.length, steps };

    return { currStep: preDebtsStages.length + lienIndex + 1, steps };
  };

  useProgressBar(
    calculateProgressFromStageAndLiens(
      curStage,
      form.formData?.liens,
      currentLienIndex,
      instance === PROFILE_INSTANCES.EDIT_PROFILE,
    ),
  );

  const onDebtSelect = () => {
    const orderedLiens = intersection(LIEN_ORDER, form?.formData?.liens);

    // Set the liens in the order we want the info pages to appear
    form.updateFormData({ liens: orderedLiens, hasOrderedLiens: true });
    setNoConfirm(false);
    updateStages(PROFILE_STAGES.DEBT_INFO_STEP);
    setCurrentLienIndex(0);
  };

  const onDebtNext = lienType => {
    const selectedLiens = form?.formData?.liens;
    const currentLienIdx = selectedLiens?.indexOf(lienType) ?? 0;
    if (currentLienIdx === selectedLiens?.length - 1 && !noConfirm) {
      return updateStages(PROFILE_STAGES.DEBT_CONFIRMATION_STEP);
    }

    setCurrentLienIndex(currentLienIdx + 1);
  };

  const onSingleLienEdit = lien => {
    // Navigate to the edit page for the lien type
    const url = instance === PROFILE_INSTANCES.EDIT_PROFILE ? editHomeProfileUrl() : buildHomeProfileUrl();
    navigate(url, {
      state: { lienType: lien.lien_type },
    });
    form.updateFormData({ liens: [lien.lien_type] });
    setCurStage(PROFILE_STAGES.DEBT_INFO_STEP);
  };

  const pagesToRender = [
    {
      component:
        instance === PROFILE_INSTANCES.SETUP_PROFILE ? (
          <BuildProfileLandingPage
            setCurStage={setCurStage}
            onNext={step => updateStages(step)}
            selections={filteredBuildProfileSelectionsFirstTime}
            form={form}
          />
        ) : (
          <ConfirmDebts
            instance={instance}
            setCurStage={setCurStage}
            onNext={step => updateStages(step)}
            form={form}
            liens={currentScenario?.liens}
            onSingleLienEdit={onSingleLienEdit}
            title="Edit your home profile"
          />
        ),
    },
    {
      component: (
        <SelectDebts
          onNext={onDebtSelect}
          onBack={() => updateStages(PROFILE_STAGES.BUILD_HOME_PROFILE)}
          form={form}
          instance={instance}
        />
      ),
    },

    {
      component: (
        <DebtSlider
          initialEditConfig={initialEditConfig}
          instance={instance}
          noConfirm={noConfirm}
          updateStages={updateStages}
          form={form}
          onDebtNext={onDebtNext}
        />
      ),
    },
    {
      component: (
        <ConfirmMortgageGaps
          onNext={() => updateStages(PROFILE_STAGES.DEBT_CONFIRMATION_STEP)}
          onBack={() => updateStages(PROFILE_STAGES.BUILD_HOME_PROFILE)}
          onConfirm={() => localStorage.setItem(`is_confirmed_mortgage_loan`, 'true')}
          form={form}
        />
      ),
    },
    {
      component: (
        <ConfirmDebts
          setCurStage={setCurStage}
          onNext={step => updateStages(step)}
          form={form}
          liens={currentScenario?.liens}
          instance={instance}
          onSingleLienEdit={onSingleLienEdit}
          title="Thanks! Let’s review the information you’ve provided."
          description="If everything looks good, click the button below to explore your equity."
          showCTA
        />
      ),
    },
  ];

  return (
    <PrimaryLayoutGrid>
      <div className="col-span-full">
        <ProgressiveFormSlides
          currentSlide={pagesToRender[curStage - 1].component}
          shouldShowPrompt={showPrompt}
          canGoBackToDashboard={instance === PROFILE_INSTANCES.EDIT_PROFILE}
        />
      </div>
    </PrimaryLayoutGrid>
  );
};

export default BuildProfileController;
