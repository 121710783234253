import { getFormattedDate } from 'utils/date';
import { DOCUMENTS_LABEL, RESOURCE_TYPE, STATEMENTS_AND_DOCUMENTS_LABEL } from './constants';
import { STAGES } from 'apps/dashboard/constants';
import { Icon } from '@hometap/htco-components';
import { getCurrentStageConfig, isTrackFunded } from './helpers';
import TrackDetailsSidebarIconWrapper from '../components/TrackDetailsSidebar/components/TrackDetailsSidebarIconWrapper/TrackDetailsSidebarIconWrapper';
import { formatPhoneNumberSeparator } from 'utils/stringFormatters';
import { getAllDocumentsUrl, getRundownOrOfferUrl } from 'utils/links';
import TrackDetailsMaxInvestmentAmount from '../components/TrackDetailsSidebar/components/TrackDetailsMaxInvestmentAmount/TrackDetailsMaxInvestmentAmount';

import TrackDetailsContactBlock from '../components/TrackDetailsSidebar/components/TrackDetailsContactBlock/TrackDetailsContactBlock';
import { toUpper } from 'lodash';

export const getDefaultTrackDetailsSidebarTopComponent = ({ track, isScreenSize }) => {
  const { currentUIStageConfig } = getCurrentStageConfig(track);

  return (
    <TrackDetailsMaxInvestmentAmount
      maxInvestmentAmount={track?.investment_amount}
      heading={toUpper(currentUIStageConfig.investmentAmountLabel)}
      homeValue={track?.relevant_home_valuation?.value}
      hideImage={isScreenSize('xs')}
    />
  );
};

export const getDefaultTrackDetailsSidebarBottomComponent = ({ track, isScreenSize }) => {
  const isTabletOrSmaller = isScreenSize('sm');
  return !isTabletOrSmaller && <TrackDetailsContactBlock contactData={getContactData(track)} />;
};

const mapOfferRundown = (rundown, currentStage) => {
  const offerValidUntilCopy = `Valid until ${getFormattedDate(rundown.accepted_by_date)}.`;
  let dateCopy;
  if (
    currentStage === STAGES.CONDITIONS_IN_REVIEW ||
    currentStage === STAGES.CLOSING_IN_PROGRESS ||
    currentStage === STAGES.INVESTED ||
    currentStage === STAGES.SETTLED
  ) {
    dateCopy = `Offer accepted ${getFormattedDate(rundown.accepted_on_date)}.`;
  } else if (currentStage === STAGES.OFFER_DECLINED) {
    dateCopy = `Offer declined. ${offerValidUntilCopy}`;
  } else {
    dateCopy = `Added ${getFormattedDate(rundown.created_at)}. ${offerValidUntilCopy}`;
  }

  return {
    icon: RESOURCE_TYPE.OFFER.icon,
    dateCopy,
    description: rundown.description,
    linkProps: { href: getRundownOrOfferUrl(rundown) },
  };
};

const mapEstimateRundown = rundown => {
  const dateCopy = `Added ${getFormattedDate(rundown.created_at)}.`;
  return {
    icon: RESOURCE_TYPE.RUNDOWN.icon,
    dateCopy,
    description: rundown.description,
    linkProps: { href: getRundownOrOfferUrl(rundown) },
  };
};

const mapResourcesPageLink = track => {
  return {
    resourceType: RESOURCE_TYPE.DOCUMENTS_PAGE_LINK.key,
    description: 'View all',
    linkProps: {
      to: getAllDocumentsUrl(track.id),
      theme: 'secondary',
      size: 'small',
      className: 'TrackDetailsDocumentsButton',
    },
  };
};

const getYourResourcesSection = ({ documents, track, statements, statementsError }) => {
  const hasActiveInvestment = track?.active_investment_id;
  const hasStatements = !!statements?.length;
  const showStatements = hasActiveInvestment && hasStatements && !statementsError;

  const documentHeader = showStatements ? STATEMENTS_AND_DOCUMENTS_LABEL : DOCUMENTS_LABEL;
  const documentSection = {
    sectionHeading: documentHeader,
    sectionResources: [{ resourceType: RESOURCE_TYPE.DOCUMENTS_PAGE_LINK.key }],
  };
  return documentSection;
};

const getYourEstimatesAndOffersSection = ({ offer, rundowns }) => {
  const configuredOfferRundown = offer && { ...offer, resourceType: RESOURCE_TYPE.OFFER.key };
  const configuredRundowns = rundowns?.length
    ? rundowns.map(rundown => ({ ...rundown, resourceType: RESOURCE_TYPE.RUNDOWN.key }))
    : [];

  const rundownResources = [configuredOfferRundown, ...configuredRundowns].filter(Boolean).map(rundown => rundown);

  return { sectionHeading: 'YOUR ESTIMATES AND OFFERS', sectionResources: rundownResources };
};

export const getResourceSections = ({
  offer,
  rundowns,
  documents,
  track,
  statements,
  statementsError,
  showDocumentsSection,
  isRolloutFlagEnabled,
}) => {
  const estimatesAndOffersSection = getYourEstimatesAndOffersSection({ offer, rundowns });
  const documentSection = getYourResourcesSection({
    documents,
    track,
    statements,
    statementsError,
    isRolloutFlagEnabled,
  });

  return showDocumentsSection ? [estimatesAndOffersSection, documentSection] : [estimatesAndOffersSection];
};

export const mapResource = (resource, track) => {
  const map = {
    [RESOURCE_TYPE.OFFER.key]: mapOfferRundown(resource, track?.current_stage),
    [RESOURCE_TYPE.RUNDOWN.key]: mapEstimateRundown(resource),
    [RESOURCE_TYPE.DOCUMENTS_PAGE_LINK.key]: mapResourcesPageLink(track),
  };

  return map[resource.resourceType];
};

const getIMContactInfo = investmentManager => {
  const heading = 'YOUR INVESTMENT MANAGER';
  if (!investmentManager) {
    return {
      heading,
      contactImage: (
        <TrackDetailsSidebarIconWrapper isDisabled>
          <Icon name="investment-manager" />
        </TrackDetailsSidebarIconWrapper>
      ),
      contactName: 'Finding a match...',
    };
  }
  return {
    heading,
    contactImage: (
      <TrackDetailsSidebarIconWrapper>
        <img src={investmentManager?.headshot_url} alt="your investment manager" />
      </TrackDetailsSidebarIconWrapper>
    ),
    contactName: investmentManager?.first_name,
    email: investmentManager?.email,
    schedulingUrl: investmentManager?.scheduling_url,
    phoneNumber: investmentManager?.phone_number && formatPhoneNumberSeparator(investmentManager.phone_number),
  };
};

const investmentSupportContactInfo = {
  heading: 'YOUR INVESTMENT SUPPORT TEAM',
  email: 'homeowners@hometap.com',
  schedulingUrl: 'https://calendly.com/investment-support',
  phoneNumber: '617.604.6985',
};

export const getContactData = track => {
  if (isTrackFunded(track)) {
    return investmentSupportContactInfo;
  }

  return getIMContactInfo(track?.investment_manager);
};
