import { useEffect, useMemo } from 'react';
import groq from 'groq';
import { createClient } from '@sanity/client';
import { useAsync } from '@hometap/htco-components';
import { homeownerResourceQuery, slugs, SLUG_NAMES } from './constants/sanityConstants';
import { transformResultsToHtml, filterArticlesBySlug, combineArticles } from './utils/sanityUtils';
import { ALL_POSTS, SIMPLI_SAFE, EFFICIENCY_UPGRADES } from 'apps/dashboard/content/resourceArticles';

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
  apiVersion: '2023-06-20',
});

const createQueryClient = slugs => {
  const slugQuery = slugs.map(slug => `"${slug}"`).join(',');
  const query = groq`*[_type == "article" && slug.current in [${slugQuery}]] ${homeownerResourceQuery}`;
  return () => client.fetch(query);
};

const articleQuery = createQueryClient(slugs);

const useSanityData = () => {
  const { results, loading, error, execute } = useAsync(articleQuery);

  useEffect(() => {
    if (!results && error === null) {
      execute();
    }
  }, [results, error, execute]);

  const transformedSanityArticles = useMemo(() => transformResultsToHtml(results), [results]);

  /*
    TODO: The following functions are used to combine articles fetched from Sanity and hardcoded articles.
    Once we fully transition to Sanity we can rewrite this section to only use Sanity data, or move
    logic outside of the hook.
  */
  const combinedArticles = useMemo(
    () => combineArticles(transformedSanityArticles, ALL_POSTS),
    [transformedSanityArticles],
  );

  /*
    Articles
  */
  const renovationBeforeRetiringArticle = useMemo(
    () => filterArticlesBySlug(transformedSanityArticles, SLUG_NAMES.RENOVATIONS_BEFORE_RETIRING),
    [transformedSanityArticles],
  );

  /*
    Card Sections
  */

  const homeMaintenanceCards = useMemo(
    () => combineArticles(renovationBeforeRetiringArticle, [SIMPLI_SAFE, EFFICIENCY_UPGRADES]),
    [renovationBeforeRetiringArticle],
  );

  return { articles: combinedArticles, homeMaintenanceCards, loading, error };
};

export default useSanityData;
