import { useContext, useEffect } from 'react';
import { ProgressContext } from 'components/ProgressProvider/ProgressProvider';

const useProgressBar = ({ steps, currStep, hide }) => {
  const { setProgress, clear } = useContext(ProgressContext);

  useEffect(() => {
    setProgress({ steps, currStep, visible: !hide });
  }, [steps, currStep, hide, setProgress]);

  useEffect(() => () => clear(), [clear]);
};

export default useProgressBar;
