// TODO: Concrete copy will be implemented in https://hometap.atlassian.net/browse/PAT-1032

export const getHomeValuationDisclaimer = ({ homeValuation, hasTracks }) => {
  if (!homeValuation)
    return {
      short: 'Sit tight — we’ll calculate your home value later in the Investment process',
      long: '',
    };

  if (homeValuation.home_valuation_type === 'SelfReportedHomeValuation') {
    return {
      short: 'This number is based on a recent home valuation, or you provided it to Hometap.',
      long:
        homeValuation &&
        'You’ve submitted your own home value. Please note, manually-submitted home values won’t update automatically.',
    };
  }

  if (homeValuation.home_valuation_type === 'PhysicalAppraisalHomeValuation') {
    return {
      short: 'Your home value was determined by a third-party appraisal company.',
    };
  }

  if (homeValuation.is_avm && hasTracks) {
    return {
      short:
        'Your home value was determined using Hometap’s Value Blend automated valuation model (AVM), which uses third-party data to calculate your home’s value without an in-person appraisal.',
      long: 'We’ll use the most recent appraised home value from the Investment process or our automated valuation model (AVM). If current AVM data isn’t available, we’ll use your last valid home value, then calculate any appreciation using data from the Federal Fair Housing Agency’s Home Price Index.',
    };
  }

  if (homeValuation.is_avm) {
    return {
      short:
        'Your home value was estimated using Hometap’s Value Blend automated valuation model (AVM), which uses third-party data to calculate your home’s value without an in-person appraisal.',
      long: 'We regularly estimate your home’s value using our automated valuation model (AVM). If current AVM data isn’t available, we’ll use your most recent valid AVM, then calculate any appreciation using data from the Federal Fair Housing Agency’s Home Price Index.',
    };
  }

  return {
    short: 'This number is based on a recent home valuation, or you provided it to Hometap.',
  };
};
