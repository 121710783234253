import RIBBON_ICON from 'images/equity-planner/dashboard/icons/ribbon.svg';
import STAR_ICON from 'images/equity-planner/dashboard/icons/star.svg';
import TOOLS_ICON from 'images/equity-planner/dashboard/icons/tools.svg';

export const RESOURCE_CATEGORIES = {
  CALCULATORS: 'Calculators',
  MARKET_INSIGHTS: 'Market Insights',
  FINANCIAL_GOALS: 'Financial Goals',
  HOMEOWNER_TIPS: 'Homeowner Tips',
  EXCLUSIVE_DISCOUNT: 'Exclusive Discount',
  HOME_FINANCING: 'Home Financing 101',
  MANAGE_YOUR_INVESTMENT: 'Manage Your Investment',
};

export const RESOURCE_TYPES = {
  ARTICLE: 'Article',
  TOOLS: 'Tools',
  PROMOTIONAL_OFFER: 'Promotional Offer',
  RESOURCES: 'Resources',
};

export const TYPE_TO_ICON_MAP = {
  [RESOURCE_TYPES.ARTICLE]: RIBBON_ICON,
  [RESOURCE_TYPES.PROMOTIONAL_OFFER]: STAR_ICON,
  [RESOURCE_TYPES.TOOLS]: TOOLS_ICON,
  [RESOURCE_TYPES.RESOURCES]: STAR_ICON,
};

export const CATEGORY_COLOR_MAP = {
  [RESOURCE_CATEGORIES.CALCULATORS]: 'secondaryOrange15',
  [RESOURCE_CATEGORIES.MARKET_INSIGHTS]: 'primaryBlue15',
  [RESOURCE_CATEGORIES.FINANCIAL_GOALS]: 'primaryGreen15',
  [RESOURCE_CATEGORIES.HOMEOWNER_TIPS]: 'secondaryYellow15',
  [RESOURCE_CATEGORIES.EXCLUSIVE_DISCOUNT]: 'primaryPurple15',
  [RESOURCE_CATEGORIES.HOME_FINANCING]: 'primaryTeal15',
  [RESOURCE_CATEGORIES.MANAGE_YOUR_INVESTMENT]: 'secondaryOrange15',
};
