import { useQuery } from '@tanstack/react-query';
import { fetchInvestmentSummary } from 'apps/settlement/data/settlementRequests';

const useTrackInvestment = ({ investmentId }) => {
  return useQuery({
    queryFn: () => fetchInvestmentSummary(investmentId),
    enabled: !!investmentId,
    queryKey: ['track.investment', investmentId],
  });
};

export default useTrackInvestment;
