import { Button, useForm, Tooltip, Icon } from '@hometap/htco-components';

const OnboardingFormWrapper = ({
  form: Form,
  buttons,
  image,
  initialFormData,
  title,
  subtitle,
  tooltipContent,
  onSubmit,
  header,
  isFirstForm,
}) => {
  const { registerField, isFormValid, formData, handleSubmit, setErrors } = useForm(initialFormData);

  return (
    <div className="mb-8 flex w-full max-w-screen-lg flex-col rounded-xl bg-white shadow sm:flex-row">
      <div className="w-full md:w-1/2">
        <div className="p-6 sm:p-10 md:p-16">
          <div className="mb-4 text-sm uppercase tracking-wider text-neutral-dark-75">{header}</div>
          {title && (
            <div className="relative my-2 sm:mb-4 sm:mt-0" data-testid="onboarding-form-title">
              <h2 className="mr-2 inline">{title}</h2>
              {tooltipContent && (
                <Tooltip content={tooltipContent} action="hover">
                  <span className="absolute mt-0 sm:mt-1.5">
                    <Icon name="info" size="sm" />
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          {subtitle && <div className="mb-8 leading-6 text-neutral-dark-75 sm:mb-10">{subtitle}</div>}
          <form
            onSubmit={handleSubmit(() => onSubmit({ formData, initialFormData, setErrors }))}
            className="md:max-w-2xl"
          >
            <Form registerField={registerField} formData={formData} />
            <div className="mt-10 flex flex-col items-center justify-center gap-7 xs:flex-row xs:justify-between">
              {(buttons || []).map(({ text, onClick, isDisabled, type, ...buttonProps }, i) => {
                return (
                  <Button
                    type={type}
                    key={`onboarding-form-wrapper-button-${i}`}
                    className="m-0 w-full xs:w-auto"
                    onClick={() => type !== 'submit' && onClick({ formData })}
                    disabled={isDisabled({ isFormValid })}
                    {...buttonProps}
                  >
                    {text}
                  </Button>
                );
              })}
            </div>
          </form>
        </div>
      </div>

      <div className={`flex w-full items-center ${isFirstForm ? 'justify-center' : 'justify-end'} md:w-1/2`}>
        <div className="sm:pl-10 md:pl-16">
          <img className="w-full max-w-[559px]" src={image} alt="home-equity" />
        </div>
      </div>
    </div>
  );
};

export default OnboardingFormWrapper;
