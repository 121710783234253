import {
  Modal,
  InputMask,
  MuiTextInput,
  Button,
  useForm,
  MuiSelect,
  SelectionSet,
  useWindowSize,
} from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';

import React, { useState, useRef, useEffect } from 'react';
import { useHomeValuation, useSelfReportedHomeValuationMutation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';
import { currency } from 'utils/numbers';

const HOME_VALUATION_OPTIONS = {
  AVM: 'avm',
  SELF_REPORTED: 'self-reported',
};

const EditHomeValueModal = ({ on, onClose, updateSmartFacts }) => {
  const { home } = useCurrentHome();
  const { homeValuation, selfReportedHomeValuation } = useHomeValuation();
  const hasAvm = !!homeValuation.value;
  const hasSelfReported = !!selfReportedHomeValuation.valuation;
  const updateSelfReportedValuation = useSelfReportedHomeValuationMutation(home);
  const { currentScenario } = useEquityScenarioContext();
  const formDefaults = {
    homeValuationSource: hasAvm && !hasSelfReported ? HOME_VALUATION_OPTIONS.AVM : HOME_VALUATION_OPTIONS.SELF_REPORTED,
    homeowner_estimated_home_value: selfReportedHomeValuation.valuation?.value,
  };
  const { formData, updateFormData, registerField, isFormValid, handleSubmit } = useForm(formDefaults);
  const [selectedValue, setSelectedValue] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize('xs');

  const handleClose = updated => {
    onClose();
    updateFormData(updated || formDefaults);
  };

  const onSubmit = async data => {
    const useAvm = data?.homeValuationSource === HOME_VALUATION_OPTIONS.AVM;
    if (data?.homeowner_estimated_home_value || (useAvm && hasSelfReported)) {
      await updateSelfReportedValuation.mutateAsync(
        useAvm ? null : Math.round(parseFloat(data.homeowner_estimated_home_value)),
      );
      updateSmartFacts({ unhelpfulSmartFactsChanged: false, homeId: home?.id, scenarioId: currentScenario?.id });
      handleClose(data);
      return;
    }

    handleClose();
  };

  // TODO: PAT-1008 Remove modal dirty fix after the bug is fixed in the modal component
  const MODAL_PADDING = 40;
  const contentRef = useRef(null);
  const isRendered = !!contentRef.current;
  const [modalStyle, setModalStyle] = useState(null);
  useEffect(() => {
    const isHigherThenScreen =
      contentRef.current && contentRef.current.clientHeight + MODAL_PADDING * 2 > window.outerHeight;
    if (isHigherThenScreen && !modalStyle) {
      setModalStyle({ alignItems: 'start' });
    } else if (!isHigherThenScreen && modalStyle) {
      setModalStyle(null);
    }
  }, [modalStyle, formData.homeValuationSource, isRendered]);

  return (
    <div className="EditHomeValueModalWrapper">
      <Modal open={on} width={580} onClose={handleClose} style={modalStyle}>
        {() => {
          return (
            <form onSubmit={handleSubmit(onSubmit)} className="EditHomeValueModal" ref={contentRef}>
              <h2>Update home value</h2>
              <p>We’ll use this value to calculate your equity today and forecast it over time</p>
              {hasAvm && (
                <SelectionSet
                  name="homeValuationSource"
                  data-testid="home-use-avm"
                  options={[
                    {
                      label: `Use Hometap’s estimate: ${currency(homeValuation.value)}`,
                      description: 'We’ll regularly estimate your home’s value using industry data',
                      icon: !isMobile ? 'icon-house-3' : null,
                      value: HOME_VALUATION_OPTIONS.AVM,
                    },
                    {
                      label: 'Use your own home value',
                      description: 'Enter your home value manually. Note that it won’t update automatically.',
                      icon: !isMobile ? 'icon-edit' : null,
                      value: HOME_VALUATION_OPTIONS.SELF_REPORTED,
                    },
                  ]}
                  {...registerField('homeValuationSource')}
                />
              )}
              {formData.homeValuationSource === HOME_VALUATION_OPTIONS.SELF_REPORTED && (
                <>
                  <div className="ModalField">
                    <MuiTextInput
                      name="homeValue"
                      label="Manual home value"
                      type="number"
                      inputMode="decimal"
                      data-testid="home-value"
                      mask={InputMask.MONEY_USD_MASK}
                      startAdornment="$"
                      unmask="typed"
                      width="100%"
                      data-lpignore="true"
                      {...registerField('homeowner_estimated_home_value')}
                      required
                    />
                  </div>
                  <div className="ModalField">
                    <MuiSelect
                      error=""
                      isSearchable
                      label="Select a reason"
                      onChange={setSelectedValue}
                      name="select a reason"
                      width="100%"
                      options={[
                        {
                          value: 'Curious',
                          label: 'Just exploring the tool',
                        },
                        {
                          value: 'renovation/remodel',
                          label: 'I recently renovated',
                        },
                        {
                          value: 'market fluctuation',
                          label: 'The value better reflects the market',
                        },
                        {
                          value: 'Other',
                          label: 'Other',
                        },
                      ]}
                      value={selectedValue}
                    />
                  </div>
                  <div className="ModalField">
                    {selectedValue === 'Other' && (
                      <MuiTextInput
                        label='If "other," share why'
                        maxLength={999}
                        name="OtherResponse"
                        onChange={setOtherValue}
                        type="text"
                        value={otherValue}
                        width="100%"
                      />
                    )}
                  </div>
                </>
              )}
              <div className="ModalField">
                <Button type="submit" disabled={!isFormValid} data-testid="submit-button">
                  Update value
                </Button>
              </div>
            </form>
          );
        }}
      </Modal>
    </div>
  );
};

export default EditHomeValueModal;
