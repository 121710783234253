import React from 'react';
import CardWrapper from 'components/CardWrapper';
import StackedEquityGraph from './StackedEquityGraph';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import { formatDate } from 'utils/date';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import { useCurrentHome } from 'hooks/useCurrentHome';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';

const StackedEquityGraphController = () => {
  const { home, loading: homeLoading } = useCurrentHome();
  const { displayedHomeValuation, isLoading: homeValuationLoading } = useHomeValuation();
  const { currentScenario, chartDataLoading } = useEquityScenarioContext();
  const { investment, loading: investmentLoading } = useHedInvestmentContext();
  const isLoading = homeLoading || homeValuationLoading || chartDataLoading || investmentLoading;
  const hasConfirmedHomeDebts = home && home.hed_debts_confirmed;
  const annualAppreciation = currentScenario?.annualAppreciation;

  // Get the current year's appreciation data from the scenario. This is to align with data shown in the HED, but we
  // should consider adding some sort of 'current' datasource that would be more precise than yearly data.
  const currentAppreciation = annualAppreciation?.find(x => x.year === new Date().getFullYear());
  const currentHometapShareDollars = currentAppreciation?.hometap_share?.dollars;
  const isInvestmentActive = !!investment && currentHometapShareDollars;

  const getFormattedAsOfDate = () => formatDate(displayedHomeValuation?.as_of_date, 'MMMM D, YYYY');

  const getDisclaimer = () => {
    const investedDisclaimer =
      'These values are estimates based on the market and information you provided or confirmed. Keep in mind, this estimate is for informational purposes only — it might differ from the final values at settlement.';
    const nonInvestedDisclaimer =
      'These values are estimates based on the market and information you provided or confirmed. It is for illustrative purposes only and not intended as financial advice.';
    return isInvestmentActive ? investedDisclaimer : nonInvestedDisclaimer;
  };
  const graphData = {
    hometapShare: {
      colorClassName: 'bg-blue-medium-100',
      value: currentHometapShareDollars,
      hidden: !isInvestmentActive,
      empty: !hasConfirmedHomeDebts,
    },
    equity: {
      colorClassName: 'bg-blue-75',
      value: currentScenario?.valuations?.currentEquity,
      empty: !hasConfirmedHomeDebts,
    },
    debt: {
      colorClassName: 'bg-blue-30',
      value:
        displayedHomeValuation?.value - currentScenario?.valuations?.currentEquity - (currentHometapShareDollars ?? 0),
      empty: !hasConfirmedHomeDebts,
      overlay: true,
    },
  };
  return (
    <SectionLoadingWrapper loading={isLoading}>
      <CardWrapper className={'mb-4 pt-5'}>
        <div className="mb-5 flex flex-row justify-between">
          <p className="leading-2 m-0 font-sans text-sm uppercase tracking-[1px] text-neutral-dark-100">
            Financial Snapshot
          </p>
          <p className="m-0 hidden text-sm text-neutral-dark-75 xs:block">Updated {getFormattedAsOfDate()}</p>
        </div>
        <StackedEquityGraph
          graphData={graphData}
          homeValue={displayedHomeValuation?.value}
          locked={!hasConfirmedHomeDebts}
          legendDisclaimer={getDisclaimer()}
        />
        <p className="m-0 mt-8 block text-sm text-neutral-dark-75 xs:hidden">Updated {getFormattedAsOfDate()}</p>
        <div className="mt-3 block xs:hidden">
          <p className="m-0 text-xs text-neutral-dark-75">{getDisclaimer()}</p>
        </div>
      </CardWrapper>
    </SectionLoadingWrapper>
  );
};
export default StackedEquityGraphController;
