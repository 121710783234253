import { Button } from '@hometap/htco-components';
import { useHistory } from 'react-router-dom';
import { HOMEOWNER_DASHBOARD } from 'utils/links';

const BackToDashBoardButton = () => {
  const history = useHistory();
  return (
    <Button
      theme="link"
      className="!p-0"
      onClick={() => history.replace(`${HOMEOWNER_DASHBOARD.ROOT}/${HOMEOWNER_DASHBOARD.HOME_EQUITY}`)}
    >
      Back to home equity forecast
    </Button>
  );
};

export default BackToDashBoardButton;
