import { HEADER_NAV_SECTION } from './constants';
import { Dropdown, useWindowSize } from '@hometap/htco-components';

import './HeaderNav.scss';
import AccessHomeEquityCTA from 'components/AccessHomeEquityCTA/AccessHomeEquityCTA';

const HeaderNav = ({ configuredNavItems }) => {
  const { isScreenSize } = useWindowSize();

  const isMobile = isScreenSize('sm');
  const isTablet = isScreenSize('md');

  const accountNavItems = configuredNavItems[HEADER_NAV_SECTION.ACCOUNT.key]?.items || [];
  const accountDropdownOptions = accountNavItems.map((item, index) => ({
    optionLabel: item.component,
    selectedLabel: '',
    value: index,
  }));

  // We display these nav items in the mobile menu for devices smaller than tablets
  return accountNavItems.length === 1 && !isTablet ? (
    accountNavItems[0].component
  ) : (
    <div className="HeaderNav">
      {!isMobile && <AccessHomeEquityCTA className="m-0 mx-0" />}

      {!isTablet && (
        <div className="HeaderNavDropdown" data-testid="header-nav-account-dropdown">
          <Dropdown label="Account" options={accountDropdownOptions} onChange={() => null} />
        </div>
      )}
    </div>
  );
};

export default HeaderNav;
