import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, useForm, useAsync } from '@hometap/htco-components';
import { useCookies } from 'react-cookie';
import InquiryConsent from 'components/InquiryConsent/InquiryConsent';

import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import { PREQUAL_OUTCOME_CODES, PREQUAL_TYPES } from 'apps/progressive-inquiry/constants/APIConstants';
import { COOKIES } from 'apps/dashboard/constants';
import {
  convertInquiry,
  createInquiry,
  sendInquiryConvertedSegmentEvents,
} from 'apps/progressive-inquiry/api/inquiryRequests';
import { parsePrequalificationsToMap } from 'apps/progressive-inquiry/utils/parsePrequalificationOutcomes';
import { getNewInquiryUrl } from 'utils/links';

const ReInquiryReviewConsentForm = ({ originalInquiryId, initialFormData, onError }) => {
  const history = useHistory();

  const createInquiryAsync = useAsync(createInquiry);
  const convertInquiryAsync = useAsync(convertInquiry);
  const { handleSubmit, registerField, formData } = useForm(initialFormData);
  const { setSessionPropertyData, utmData } = useInitialInquiryData();
  const [, setCookie] = useCookies();

  const onSubmit = async () => {
    if (!originalInquiryId) {
      onError(500);
      return;
    }

    const { first_name, last_name, email } = formData;

    // As of now, we pass all re-inquiry data through the frontend except for SSN. Because SSN is
    // particularly sensitive, and not shown for confirmation during re-inquiry, we use URL Params
    // reinquiry_id and reinquiry_fields=['ssn'] to trigger the backend reusing the SSN from the
    // original inquiry.
    const reinquiryFields = ['ssn'];

    const newInquiry = await createInquiryAsync.execute(
      { ...formData, ...utmData },
      [PREQUAL_TYPES.INVESTMENT_AMOUNT],
      originalInquiryId,
      reinquiryFields,
    );

    // something went wrong in the request and no inquiry was created
    if (!newInquiry) return;

    const { outcome } = parsePrequalificationsToMap(newInquiry?.prequalifications);
    const redirectPath = `/re-inquiry/${newInquiry?.id}/outcome`;

    if (outcome === PREQUAL_OUTCOME_CODES.FAIL) {
      return history.replace(redirectPath);
    }

    const convertedData = await convertInquiryAsync.execute(newInquiry.id, { email });
    // something went wrong in the request and the inquiry was not converted
    if (!convertedData) return;

    sendInquiryConvertedSegmentEvents({ first_name, last_name, email, isAdditionalTrack: true });
    return history.replace(redirectPath);
  };

  const handleSetSessionDataOnClick = () => {
    const inquirySessionData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone_number: formData.phone_number,
      primary_use_case: formData.primary_use_case,
      when_interested: formData.when_interested,
      residence_type: formData.residence_type,
      consent_given: formData.consent_given,
      property_type: formData.property_type,
      street: formData.address.street,
      unit: formData.address.unit,
      city: formData.address.city,
      state: formData.address.state,
      zip_code: formData.address.zip_code,
      mortgage_balance: formData.mortgage_balance,
    };
    setCookie(COOKIES.DISABLE_PROPERTY_MODAL, true, { path: '/' });
    setSessionPropertyData(inquirySessionData);
    return history.push(getNewInquiryUrl());
  };

  useEffect(() => {
    const getErrorResponseStatusOr500 = error => (error.response ? onError(error.response.status) : onError(500));

    // these would be triggered by onSubmit
    if (createInquiryAsync?.error) {
      getErrorResponseStatusOr500(createInquiryAsync.error);
    }

    if (convertInquiryAsync?.error) {
      getErrorResponseStatusOr500(convertInquiryAsync.error);
    }
  }, [createInquiryAsync?.error, convertInquiryAsync?.error, onError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ReInquiryReviewConsentForm">
      <div className="ReInquiryReviewConsentFormContent">
        <InquiryConsent registerField={registerField} />
      </div>
      <div className="ReInquiryReviewConsentFormButtonRow">
        <Button
          className="ReInquiryReviewConsentFormSubmit"
          type="submit"
          loading={createInquiryAsync.loading || convertInquiryAsync.loading}
          disabled={!formData.consent_given}
        >
          Looks good!
        </Button>
        <Button onClick={handleSetSessionDataOnClick} className="ReInquiryReviewConsentFormEdit" theme="link">
          I need to edit some information
        </Button>
      </div>
    </form>
  );
};

export default ReInquiryReviewConsentForm;
