import { Button } from '@hometap/htco-components';
import useCurrentUser from 'hooks/useCurrentUser';
import inquiryDqImg from 'images/inquiry/hed-dq-phones.png';
import './HedDqCta.scss';

const HedDqCta = () => {
  const { user } = useCurrentUser();
  return (
    <div className="HedDqCta" data-testid="dq-cta">
      <div>
        <img src={inquiryDqImg} alt="home-equity-charts" />
      </div>
      <div>
        <h3 className="HedCtaHeader">Home Equity Dashboard</h3>
        <div className="HedDqCtaBody" data-testid="dq-cta-body">
          Get real-time value estimates, equity forecasting tools, and customized insights — all for free.
        </div>
        <Button
          className="HedCtaLink"
          href={user ? '/dashboard' : '/equity-planner/onboard/address'}
          variant="contained"
          theme="secondary"
        >
          {user ? 'Go to dashboard' : 'Check it out'}
        </Button>
      </div>
    </div>
  );
};

export default HedDqCta;
