export const DOMAINS = [
  'msn.com',
  'bellsouth.net',
  'telus.net',
  'comcast.net',
  'optusnet.com.au',
  'earthlink.net',
  'qq.com',
  'sky.com',
  'icloud.com',
  'sympatico.ca',
  'googlemail.com',
  'att.net',
  'xtra.co.nz',
  'web.de',
  'cox.net',
  'gmail.com',
  'ymail.com',
  'aim.com',
  'rogers.com',
  'verizon.net',
  'rocketmail.com',
  'optonline.net',
  'sbcglobal.net',
  // aol
  'aol.com',
  'aim.com',
  'me.com',
  'mailw.com',
  'btinternet.com',
  'charter.net',
  'shaw.ca',
  'hey.com',
  // Proton
  'proton.me',
  'pm.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'titan.email',
  'yahoo.com',
  'hotmail.com',
  'live.com',
  'outlook.com',
  'mail.com',
  'google.com',
  'hometap.com',
  'gmx.com',
];

export const HOSTS = [
  'yahoo',
  'hotmail',
  'aol',
  'msn',
  'live',
  'outlook',
  'googlemail',
  'mail',
  'gmail',
  'google',
  'verizon',
  'icloud',
  'protonmail',
  'zoho',
  'ymail',
  'comcast',
  'me',
  'hometap',
  'gmx',
];

export const TLDS = [
  'com',
  'com.au',
  'com.tw',
  'co',
  'ca',
  'co.nz',
  'co.uk',
  'de',
  'fr',
  'it',
  'ru',
  'org',
  'edu',
  'gov',
  'jp',
  'nl',
  'kr',
  'se',
  'eu',
  'ie',
  'co.il',
  'us',
  'at',
  'be',
  'dk',
  'hk',
  'es',
  'gr',
  'ch',
  'no',
  'cz',
  'net',
  'net.au',
  'info',
  'biz',
  'mil',
  'co.jp',
  'sg',
  'hu',
  'uk',
  'sk',
  // Country specific popular domains
  'ar',
  'cf',
  'cl',
  'cn',
  'ga',
  'gq',
  'ir',
  'ml',
  'mx',
  'nu',
  'nz',
  'ph',
  'pl',
  'ro',
  'tk',
  'tw',
  'ua',
  'vg',
  'ws',
  'xn',
  'za',
  // popular TLDs that startup use in 2022
  // https://thewebsiteflip.com/domains/funded-startup-brands-2021/#:~:text=from%20the%20data.-,1.,TLDs%20for%20the%20foreseeable%20future.
  // https://martijnoud.com/most-popular-tld-crypto/
  'app',
  'au',
  'ai',
  'biz',
  'br',
  'blog',
  'cloud',
  'club',
  'cc',
  'de',
  'dev',
  'digital',
  'fi',
  'finance',
  'id',
  'in',
  'io',
  'me',
  'mobi',
  'network',
  'pw',
  'so',
  'xyz',
  'software',
  'to',
  'tech',
];
