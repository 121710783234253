import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useCookies } from 'react-cookie';
import { Button } from '@hometap/htco-components';
import { getNewInquiryUrl } from 'utils/links';
import { COOKIES } from 'apps/dashboard/constants';
import ReInquiryAddressPickerModal from 'apps/re-inquiry/components/ReInquiryAddressPickerModal';
import useInitialInquiryData from 'apps/progressive-inquiry/hooks/useInitialInquiryData';
import './ReInquiryCTA.scss';

const ReInquiryCTA = ({ title, text, image, tracksLoading, tracks, pendingTracks, equityPlannerHomes }) => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies();
  const { setSessionPropertyData } = useInitialInquiryData();
  const [open, setOpen] = useState(false);

  const hasTracks = tracks?.length > 0;
  const hasPendingTracks = pendingTracks?.length > 0;
  const hasEquityPlannerHomes = equityPlannerHomes?.length > 0;

  const onSelectEquityPlannerHome = equityPlannerHome => {
    setCookie(COOKIES.DISABLE_PROPERTY_DATA, true, { path: '/' });
    setCookie(COOKIES.DISABLE_PROPERTY_MODAL, true, { path: '/' });
    setSessionPropertyData({ ...equityPlannerHome.address });
    return navigate(getNewInquiryUrl());
  };

  const handleClick = () => {
    if (!tracksLoading) {
      if (!(hasPendingTracks || hasTracks)) {
        navigate(getNewInquiryUrl());
      } else if (hasTracks || hasEquityPlannerHomes) {
        setOpen(true);
      }
    }
  };

  return (
    <>
      <ReInquiryAddressPickerModal
        onClose={() => setOpen(false)}
        open={open}
        homeownerTracks={tracks}
        homeownerEquityPlannerHomes={equityPlannerHomes}
        onSelectEquityPlannerHome={onSelectEquityPlannerHome}
      />
      <div className="ReInquiryCTA">
        <div className="ReInquiryCTAImage">
          <img alt="succulent in a pot" src={image} />
        </div>
        <div className="ReInquiryCTAContent">
          <div className="ReInquiryCTAText">
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
          <div className="ReInquiryCTAButton">
            <Button
              onClick={handleClick}
              size="small"
              theme={tracks?.length ? 'secondary' : 'primary'}
              loading={tracksLoading}
            >
              Start a new Investment
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReInquiryCTA;
