import PropTypes from 'prop-types';
import { Paper, IconButton } from '@hometap/htco-components';
import { getAmountOfTasksCompleted } from '../../../utils/homeMaintenanceTasksUtils';

const SeasonChecklist = ({ checklist = {}, onSelectTask }) => {
  const tasksComplete = getAmountOfTasksCompleted(checklist?.tasks);

  return (
    <Paper className="h-full w-full rounded-xl px-4 py-6 sm:px-6 sm:py-10">
      <div className="flex items-center">
        <h4 className="inline text-xl text-neutral-dark-100 sm:text-2xl">{checklist?.season}</h4>
        <label className="ml-3 rounded-md bg-blue-5 px-[0.4rem] py-1 text-xs font-bold text-blue-100">
          {tasksComplete}
        </label>
      </div>
      <div className="my-[25px]">
        {checklist?.tasks?.map(task => (
          <div key={task.label} className="TaskItem my-7 flex items-center">
            <span className="flex items-center">
              {task.complete ? (
                <IconButton
                  className="h-[26px] w-[26px] align-[-4%] text-[1.05em] transition-none"
                  icon="check-mdc"
                  theme="solid"
                  onClick={() => onSelectTask(task?.name)}
                />
              ) : (
                <IconButton
                  className="h-[26px] w-[26px] border border-solid border-blue-100 align-[-4%] text-[1.05em] transition-none [&_i]:hidden"
                  icon="circle-thin"
                  onClick={() => onSelectTask(task?.name)}
                />
              )}
            </span>
            <a
              href={task.link}
              target="_blank"
              rel="noreferrer"
              className="ml-3 cursor-pointer !text-sm !text-[#0f172a] underline md:!text-base [&_:hover]:opacity-75"
            >
              {task.label}
            </a>
          </div>
        ))}
      </div>
    </Paper>
  );
};

SeasonChecklist.propTypes = {
  onSelectTask: PropTypes.func,
  checklist: PropTypes.shape({
    season: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        complete: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
};

export default SeasonChecklist;
