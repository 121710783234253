import cx from 'classnames';
import { Link } from 'react-router-dom';

import NavAccordion from 'components/nav/NavAccordion/NavAccordion';
import NavIcon from '../NavIcon/NavIcon';
import useNavItem from 'components/nav/hooks/useNavItem/useNavItem';
import PropTypes from 'prop-types';
import usePrimaryNav from 'components/Layouts/PrimaryLayout/hooks/usePrimaryNav';
import NavTooltip from '../NavTooltip/NavTooltip';
import { buildHomeProfileUrl } from 'utils/links';

const NestedNavItem = ({ path, title, onClick, active = false, disabled }) => {
  const { isPressed, className, ...rest } = useNavItem({ active });

  return (
    <div
      className={cx(
        'text-blue-600 relative flex items-center py-[2.5px] pl-6 pr-1 font-semibold before:absolute before:left-0',
        'before:h-full before:w-[3px] before:translate-x-[-2px] before:content-[""] focus:before:bg-blue-100',
        {
          'before:bg-blue-100': active,
          'hover:before:bg-blue-100': !isPressed,
          'before:!bg-blue-medium-100': isPressed,
        },
      )}
    >
      <Link
        className={cx(
          'htco- w-full text-sm hover:text-blue-100 hover:no-underline focus:rounded focus:outline-none focus:outline-1 focus:outline-blue-100',
          {
            'font-normal text-neutral-dark-100': !active,
            'font-bold ': active,
            '!text-blue-medium-100': isPressed,
          },
        )}
        to={path}
        onClick={() => onClick && onClick()}
        {...rest}
      >
        {title}
      </Link>
    </div>
  );
};

const NavNestedItems = ({ active, disabled, open, navItems = [], title, icon, onToggle, onNavItemClick }) => {
  const isOpen = open && !disabled;
  const { navItemsExpanded } = usePrimaryNav();
  const { className, isPressed, ...onPressProps } = useNavItem({ active, open: isOpen, disabled });

  const navIconIndicator = () => {
    if (disabled && !navItemsExpanded) {
      return (
        <NavTooltip
          title="Finish your home profile"
          description="Add more details about your property to unlock the scenario planner"
          link={buildHomeProfileUrl()}
          linkText="Finish your profile"
          position="right"
        >
          <NavIcon icon={icon} />
        </NavTooltip>
      );
    }
    return <NavIcon icon={icon} />;
  };

  return (
    <NavAccordion
      open={isOpen}
      className={className}
      onToggle={open => onToggle && onToggle(open)}
      canExpand={navItemsExpanded}
      dataTestId="nav-nested-items"
    >
      <NavAccordion.Toggle
        title={title}
        titleIndicator={navIconIndicator()}
        onPressProps={onPressProps}
        disabled={disabled}
      />
      <NavAccordion.Content>
        <div className="pl-4">
          <div className="mt-6 flex flex-col gap-6 border-0 border-l border-solid border-blue-30">
            {navItems.map(item => (
              <NestedNavItem
                key={item.title}
                path={item.path}
                title={item.title}
                active={item.active}
                onClick={onNavItemClick}
                disabled={disabled}
              />
            ))}
          </div>
        </div>
      </NavAccordion.Content>
    </NavAccordion>
  );
};

export default NavNestedItems;

const NestedNavItemPropTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

const NavNestedItemsPropTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  onToggle: PropTypes.func,
  onNavItemClick: PropTypes.func,
  active: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape(NestedNavItemPropTypes)).isRequired,
  icon: PropTypes.string.isRequired,
};

NestedNavItem.propTypes = NestedNavItemPropTypes;
NavNestedItems.propTypes = NavNestedItemsPropTypes;
