import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { cloneDeepWith, isPlainObject, toString } from 'lodash';
import { useAsync } from '@hometap/htco-components';
import { getNewHEDUrl } from 'utils/links';
import houseImage from 'images/dashboard/build-profile-house-2.jpg';
import equityIncreaseImage from 'images/dashboard/build-profile-landing.png';
import OnboardingFormWrapper from '../OnboardingFormWrapper';
import ProgressiveFormSlides from '../ProgressiveFormSlides';
import { useEquityScenarioContext } from '../../hooks';
import { LIEN_FIELDS } from 'apps/dashboard/constants';
import numeral from 'numeral';
import { getVersion } from 'utils/env';
import { browserTrack } from 'utils/segment';
import useCurrentUser from 'hooks/useCurrentUser';
import { formatDate } from 'utils/date';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';
import useProgressBar from 'hooks/useProgressBar';

const LIEN_ACTION_TYPE = {
  CONFIRMED: 'confirmed',
  EDITED: 'edited',
  REMOVED: 'removed',
};

const convertObjectToValidFormData = obj => {
  return cloneDeepWith(obj, value => {
    return !isPlainObject(value) ? toString(value) : undefined;
  });
};

const TooltipContent = ({ propertyDataAsOf }) => {
  return (
    <div className="p-2" data-testid="onboarding-form-tooltip-content">
      <h4 className="mb-2.5">How did we come up with this data?</h4>
      <div>
        Hometap pulls comprehensive public data on estimated property LTV (loan-to-value) ratio from HouseCanary's
        database. The below details have been populated based on their most recent data. If anything looks incorrect,
        please make changes before saving.
      </div>
      {propertyDataAsOf && (
        <div className="mt-2.5 text-[10px]/[16px] italic text-neutral-dark-75">
          Property data last updated on {formatDate(propertyDataAsOf, 'M/D/Y')}
        </div>
      )}
    </div>
  );
};

const HouseCanaryLiensController = ({ lienForms = [], asOfDate }) => {
  const [currentLienFormIndex, setCurrentLienIndex] = useState(0);
  const navigate = useNavigate();
  const { initialScenario, addLien } = useEquityScenarioContext();
  const addLienAsync = useAsync(addLien, { executeThrow: true });
  const { home } = useCurrentHome();
  const currentHomeMutation = useCurrentHomeMutation();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (!lienForms.length || home.hed_debts_confirmed) {
      navigate(getNewHEDUrl(), { replace: true });
      return;
    }
  }, [navigate, home.hed_debts_confirmed, lienForms.length]);

  const currentLienForm = lienForms[currentLienFormIndex];
  const isLastLien = currentLienFormIndex === lienForms.length - 1;
  const isFirstLien = currentLienFormIndex === 0;

  const buildSegmentData = (lienType, action) => ({
    email: user.email,
    home_id: home.id,
    equity_scenario_id: initialScenario.id,
    lien_type: lienType,
    action: action,
    version: getVersion(),
  });

  const isLienDataChanged = (formData, initialData) =>
    initialData.lien_kind !== formData.lien_kind ||
    numeral(initialData.estimated_lien_balance).value() !== numeral(formData.estimated_lien_balance).value() ||
    numeral(initialData.rate).value() !== numeral(formData.rate).value() ||
    numeral(initialData.monthly_payment).value() !== numeral(formData.monthly_payment).value();

  const handleSkip = async initialFormData => {
    browserTrack.houseCanaryUpdateLienInformation(
      buildSegmentData(initialFormData.lien_type, LIEN_ACTION_TYPE.REMOVED),
    );
    await handleNext();
  };

  const handleNext = async () => {
    if (!isLastLien) return setCurrentLienIndex(prev => prev + 1);

    const res = await currentHomeMutation.mutateAsync({ hed_debts_confirmed: true });
    navigate(getNewHEDUrl());
    return res;
  };

  const handleSave = async ({ formData, initialFormData }) => {
    const actionType = isLienDataChanged(formData, initialFormData)
      ? LIEN_ACTION_TYPE.EDITED
      : LIEN_ACTION_TYPE.CONFIRMED;
    browserTrack.houseCanaryUpdateLienInformation(buildSegmentData(formData.lien_type, actionType));

    const formattedRate = parseFloat(formData.rate) / 100;
    const formattedFormData = {
      ...formData,
      rate: numeral(formattedRate.toFixed(4)).value(),
      monthly_payment: numeral(formData.monthly_payment).value(),
      last_known_current_balance: numeral(formData.estimated_lien_balance).value(),
    };

    await addLienAsync.execute(initialScenario, formattedFormData, handleNext);
  };

  const { record_date, ...mostRecentLienData } = currentLienForm?.liens[0] || {};
  const initialFormData = {
    ...mostRecentLienData,
    lien_type: currentLienForm?.relativeScenarioLienType,
    last_known_current_balance: mostRecentLienData.estimated_lien_balance,
    lien_kind: mostRecentLienData.lien_kind !== LIEN_FIELDS.LIEN_KIND_OTHER ? mostRecentLienData.lien_kind : '',
  };

  const isButtonLoading = addLienAsync.loading;
  const mappedLienSlides = lienForms.map((form, i) => {
    return {
      component: (
        <OnboardingFormWrapper
          key={`house-canary-lien-form-${i}`}
          form={form.form}
          title={form.title}
          tooltipContent={<TooltipContent propertyDataAsOf={asOfDate} />}
          subtitle={form.subtitle}
          isFirstForm={isFirstLien}
          header={`Finish setup for ${home.address.street}`}
          initialFormData={convertObjectToValidFormData(initialFormData)}
          image={isFirstLien ? equityIncreaseImage : houseImage}
          onSubmit={handleSave}
          buttons={[
            {
              text: 'Save details',
              type: 'submit',
              loading: isButtonLoading,
              isDisabled: ({ isFormValid }) => {
                return !isFormValid;
              },
            },
            {
              text: form.skipButtonText,
              onClick: () => handleSkip(initialFormData),
              isDisabled: () => isButtonLoading,
              theme: 'link',
            },
          ]}
        />
      ),
    };
  });

  useProgressBar({ hide: !lienForms.length, steps: lienForms.length, currStep: currentLienFormIndex + 1 });

  return (
    <ProgressiveFormSlides currentSlide={mappedLienSlides[currentLienFormIndex]?.component} shouldShowPrompt={false} />
  );
};

export default HouseCanaryLiensController;
