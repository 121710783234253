const RISK_DQ_COPY = {
  header: 'We can’t make an investment, yet',
  subtitle:
    'We consider a combination of factors when making investments, including information from a credit reporting agency.',
  link: 'Learn about the Fair Credit Reporting Act',
};

const RiskFailHeader = () => {
  return (
    <div>
      <h1 className="DQHeader" data-testid="dq-header">
        {RISK_DQ_COPY.header}
      </h1>
    </div>
  );
};

export default RiskFailHeader;
