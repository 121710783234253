// This object needs to match what is represented in base.py in eng_portals
// xls is removed since it is not a client upload type
export const ACCEPTED_FILE_TYPE_AND_EXTENSIONS = {
  pdf: ['application/pdf'],
  jpg: ['image/jpeg', 'image/pjpeg'],
  jpeg: ['image/jpeg', 'image/pjpeg'],
  png: ['image/png'],
  // temporarily disabling until uploader is componentized (ticket unknown)
  // heic: ['image/heif', 'image/heic', 'image/heif-sequence', 'image/heic-sequence'],
};

// based on MEDIA FILES CONFIGURATION in base.py in eng_portals, slightly smaller to be safe
export const MAX_UPLOAD_SIZE_BYTES = 1000 * 1000 * 50; // 50mb
export const MAX_BULK_UPLOAD_FILE_COUNT = 20;

// We can expect that /:kind in the url can possibly be "other". Because of backend implications we
// don't have or expect to have a document with a kind === 'other'.
export const [KIND_OTHER_VALUE, KIND_OTHER_DISPLAY] = ['other', 'Other'];
