import { useWindowSize } from '@hometap/htco-components';
import { useCycle } from 'framer-motion';
import usePrev from 'hooks/usePrev';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

const PrimaryNavContext = createContext({});

const usePrimaryNav = () => {
  const context = useContext(PrimaryNavContext);
  if (!context) {
    throw new Error('usePrimaryNav must be used within a PrimaryNavProvider');
  }
  return context;
};

export const PrimaryNavProvider = ({ children }) => {
  const SMALL_DESKTOP_BREAKPOINT = 1280;
  const TABLET_BREAKPOINT = 900;

  const { isScreenSize, screenWidth } = useWindowSize();
  const isMobile = (isScreenSize('sm') || isScreenSize('md')) && screenWidth <= TABLET_BREAKPOINT;
  const isSmallDesktop = screenWidth > TABLET_BREAKPOINT && screenWidth < SMALL_DESKTOP_BREAKPOINT;
  const isLargeDesktop = screenWidth >= SMALL_DESKTOP_BREAKPOINT;
  const prevMobile = usePrev(isMobile);
  const prevSmDesktop = usePrev(isSmallDesktop);

  const [isHomePickerOpen, setIsHomePickerOpen] = useState(false);

  const [slideState, setSlideState] = useState();
  const [isMobileNavOpen, onMobileNavOpen] = useCycle(false, true);

  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(!isMobile);
  const prevIsDesktopNavOpen = usePrev(isDesktopNavOpen);
  const prevLgDesktop = usePrev(screenWidth >= SMALL_DESKTOP_BREAKPOINT);

  useEffect(
    function onResizeSidebarState() {
      if (prevSmDesktop && isLargeDesktop) {
        setIsDesktopNavOpen(true);
      }

      if (!isMobile) {
        onMobileNavOpen(0);
      }

      if (prevLgDesktop && isSmallDesktop && isDesktopNavOpen) {
        setIsDesktopNavOpen(false);
      }
    },
    [
      prevMobile,
      isMobile,
      onMobileNavOpen,
      prevLgDesktop,
      isSmallDesktop,
      isDesktopNavOpen,
      prevSmDesktop,
      isLargeDesktop,
    ],
  );

  const initialSmallDTRender = useRef(true);
  const isInitialSmallDesktopRender = isSmallDesktop && prevIsDesktopNavOpen && initialSmallDTRender.current;
  useEffect(
    function closeSidebarOnInitialSmallDesktopRender() {
      if (isInitialSmallDesktopRender) {
        initialSmallDTRender.current = false;
        setIsDesktopNavOpen(false);
      }
    },
    [isInitialSmallDesktopRender],
  );

  const isSlideState = (...args) => {
    return slideState && args.some(arg => arg === slideState);
  };

  const isSidebarOpeningOrOpen = isSlideState('entered', 'entering');
  const isSidebarClosingOrClosed = isSlideState('exiting', 'exited');
  const isSidebarMovingOrClosed = isSlideState('entering', 'exiting', 'exited');
  const isSideBarMovingOrOpen = isSlideState('entered', 'entering', 'exiting');
  const navItemsExpanded = isMobile || isSidebarOpeningOrOpen || isDesktopNavOpen;

  const shouldOverlaySidebar = !prevLgDesktop && isSmallDesktop && isSideBarMovingOrOpen;

  const contextData = {
    isMobile,
    isSmallDesktop,
    isMobileNavOpen,
    isDesktopNavOpen,
    navItemsExpanded,
    onMobileNavOpen,
    isSidebarOpeningOrOpen,
    isSidebarMovingOrClosed,
    isSidebarClosingOrClosed,
    isSideBarMovingOrOpen,
    isInitialSmallDesktopRender,
    isHomePickerOpen,
    shouldOverlaySidebar,
    onChangeIsHomePickerOpen: setIsHomePickerOpen,
    onChangeIsDesktopNavOpen: setIsDesktopNavOpen,
    onSlideStateChange: setSlideState,
  };

  return <PrimaryNavContext.Provider value={contextData}>{children}</PrimaryNavContext.Provider>;
};

export default usePrimaryNav;
