import { useNavigate, useMatch } from 'react-router-dom-v5-compat';
import { HOMEOWNER_DASHBOARD, getNewDashboardUrl } from 'utils/links';
import useUserTracksContext from 'hooks/useUserTracksContext';
import { useCurrentHome } from 'hooks/useCurrentHome';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useHEDRedirect = () => {
  const navigate = useNavigate();
  const { showOverviewPage } = useFlags();
  const { tracks, loading: loadingTracks, pendingTracks } = useUserTracksContext();
  const { home, loading: loadingHome, userCanAccessHome, hasHEDAccessToCurrentHome } = useCurrentHome();
  const unconfirmedUserSSN = pendingTracks?.find(track => track?.membership?.status === 'pending');

  const homeEquityMatch = useMatch(`${getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY)}/*`);
  const maintenanceMatch = useMatch(getNewDashboardUrl(HOMEOWNER_DASHBOARD.MAINTENANCE));
  const investmentsMatch = useMatch(getNewDashboardUrl(HOMEOWNER_DASHBOARD.INVESTMENTS));
  const isHomeEquityOrMaintenance = Boolean(homeEquityMatch) || Boolean(maintenanceMatch);

  const currentHomeTracks = tracks?.filter(track => {
    return track?.home.address.business_key === home?.address.business_key;
  });
  const currentHomeTracksLength = currentHomeTracks?.length || 0;

  useEffect(() => {
    if (unconfirmedUserSSN) {
      navigate(`/track/${unconfirmedUserSSN.id}/co-applicant/`);
      return;
    }

    if (loadingTracks || loadingHome) return;

    const isNotPrimaryUser = userCanAccessHome === false;
    if (isHomeEquityOrMaintenance && isNotPrimaryUser) {
      navigate(getNewDashboardUrl(showOverviewPage ? HOMEOWNER_DASHBOARD.OVERVIEW : HOMEOWNER_DASHBOARD.INVESTMENTS));
      return;
    }

    if (
      Boolean(investmentsMatch) &&
      tracks &&
      !loadingTracks &&
      !currentHomeTracksLength &&
      hasHEDAccessToCurrentHome
    ) {
      navigate(getNewDashboardUrl(showOverviewPage ? HOMEOWNER_DASHBOARD.OVERVIEW : HOMEOWNER_DASHBOARD.HOME_EQUITY));
    }
  }, [
    unconfirmedUserSSN,
    navigate,
    loadingTracks,
    loadingHome,
    userCanAccessHome,
    isHomeEquityOrMaintenance,
    investmentsMatch,
    tracks,
    currentHomeTracksLength,
    home,
    hasHEDAccessToCurrentHome,
    showOverviewPage,
  ]);
};

export default useHEDRedirect;
