import simpliSafe from '../../../images/dashboard/simpli_safe_ad.png';
import Liberty from '../../../images/dashboard/liberty-ad.png';
import { RESOURCE_CATEGORIES } from './resources';

const EQUITY_PLANNER_URL = '/equity-planner';
const ONBOARD_URL = '/equity-planner/onboard';
const ONBOARD_URL_WITH_PARAMS = `${ONBOARD_URL}?utm_source=hometaphed&utm_medium=sharelink&utm_campaign=sharehed&utm_term=sharebutton`;
const SHARE_URL = '/equity-planner/share';
const DASHBOARD_URL = '/dashboard/home-equity';
const SCENARIOS_URL = '/equity-planner/scenarios';
const HOME_MAINTENANCE_TASKS_URL = '/equity-planner/home-maintenance-tasks';

const ONBOARD_STEPS = ['welcome', 'address', 'goals', 'homeownerInfo', 'signup', 'verify'];

const ONBOARD_FIELDS = [
  'step',
  'address',
  'street',
  'unit',
  'city',
  'state',
  'zip_code',
  'onboarding_goal',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'liens',

  // Fields for each lien type
  'mortgage_loan_lien_kind',
  'mortgage_loan_rate',
  'mortgage_loan_last_known_current_balance',
  'mortgage_loan_monthly_payment',

  'home_equity_loan_rate',
  'home_equity_loan_last_known_current_balance',
  'home_equity_loan_monthly_payment',

  'home_equity_line_of_credit_last_known_current_balance',
  'home_equity_line_of_credit_rate',
  'home_equity_line_of_credit_monthly_payment',

  'judgement_tax_or_other_last_known_current_balance',
  'judgement_tax_or_other_monthly_payment',
  'judgement_tax_or_other_rate',
];

const EQUITY_VIEW = {
  TODAY: 'today',
  FUTURE: 'future',
};

const SEASONS = {
  SPRING: 'Spring',
  SUMMER: 'Summer',
  WINTER: 'Winter',
  FALL: 'Fall',
};

const FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID = 'Liberty-id';
const FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID = 'simpli-safe-id';
const FEATURED_CONTENT_CAROUSEL_SLIDES = [
  {
    id: FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID,
    theme: 'light',
    title: RESOURCE_CATEGORIES.EXCLUSIVE_DISCOUNT,
    header: 'Give your family the gift of a safer home.',
    text: 'Receive an exclusive 50% Hometap discount on SimpliSafe + 2 months of free monitoring.',
    linkText: 'Save Now',
    href: 'https://simplisafe.com/hometap?utm_source=hometap&utm_medium=partnerdigital&utm_campaign=sr',
    disclaimer: 'Hometap may receive compensation when you purchase a SimpliSafe kit.',
    image: simpliSafe,
    shouldHide: false,
  },
  {
    id: FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID,
    theme: 'light',
    title: '',
    header: 'Worry less with a home warranty.',
    text: 'Protect your most valuable systems and appliances with a top-rated home warranty.',
    extraText: 'Plans start at less than $1 a day.',
    linkText: 'Get your quote',
    href: 'https://www.libertyhomeguard.com/hometap/',
    disclaimer: 'Hometap may receive compensation when you purchase a home warranty.',
    image: Liberty,
    shouldHide: false,
  },
  {
    theme: 'light',
    title: 'CALCULATOR',
    header: 'Prioritize bills with the Cost of Debt Calculator',
    text: 'See how your loans and other debts stack up side by side and discover which bills you need to prioritize to be debt-free, faster.',
    linkText: 'Try It Free',
    href: 'https://www.hometap.com/homeowner-resources/debt-calculator/',
  },
  {
    theme: 'light',
    title: 'GUIDE',
    header: 'The Homeowner’s Guide to Home Value and Appraisals',
    text: 'Find out what home appraisers look for during an appointment, what you can do to improve your home’s value, and why Automated Value Models are gaining popularity.',
    linkText: 'Learn More',
    href: 'https://ucarecdn.com/fb653e07-91bd-4664-82a4-33ce426c3dcc/-/inline/yes/',
  },
];

const TOOLTIP_VALUES = {
  HOME_VAL_AVM: {
    today: {
      header: 'How did we come up with this number?',
      content:
        'Hometap’s Home Value Estimate combines multiple industry-proven AVMs (automated value models) to give you an accurate and up-to-date estimated home value. If the figure doesn’t look right to you, you can enter a manual value to override it.',
    },
    future: {
      header: 'How might this number change?',
      content:
        'Your future estimated home value is based on multiple industry-proven AVMs (automated valuation models), plus any renovation scenarios you add.',
    },
  },

  HOME_VAL_MANUAL: {
    today: {
      header: 'You’ve added a manual home value',
      content:
        'Your current estimated home value has been updated based on the number you provided manually. Please note that this value will not update automatically.',
    },
    future: {
      header: 'How might this number change?',
      content:
        'Your future estimated home value is based on the number you provided manually, and will change based on any renovation scenarios you add. Please note that this value will not update automatically.',
    },
  },

  EQUITY: {
    today: {
      header: 'How did we come up with this number?',
      content:
        'We calculate your current equity by taking the estimated value of your home, then subtracting the outstanding debt you still have on the property. The data is pulled from your latest home profile details, and this value may change if debts are added or removed from your home profile.',
    },
    future: {
      header: 'How did we come up with this number?',
      content:
        'We calculate your future equity by taking the future estimated value of your home, and then subtracting the outstanding debt amount you’ve provided.  This number will change as you add different renovation or financial scenarios',
    },
  },

  TAPPABLE_EQUITY: {
    header: 'How did we come up with this number?',
    content:
      'Based on your current estimated home value and equity figures, we estimate this as the amount of funds that Hometap could provide to you through an Investment. This value is an approximation and is subject to change during the application and underwriting review process.',
  },
};

const LOCAL_STORAGE_LATER_KEY = 'htcoSetupProfileLater';

const INVESTED_HOMEOWNER_SUPPORT_NUMBER = '855-223-3144';
const HED_USER_SUPPORT_NUMBER = '833-990-6188';

const PRODUCT_SUPPORT_EMAIL = 'productsupport@hometap.com';
const INVESTMENT_SUPPORT_EMAIL = 'homeowners@hometap.com';

export {
  EQUITY_PLANNER_URL,
  ONBOARD_URL_WITH_PARAMS,
  ONBOARD_URL,
  SHARE_URL,
  DASHBOARD_URL,
  ONBOARD_STEPS,
  ONBOARD_FIELDS,
  EQUITY_VIEW,
  SEASONS,
  FEATURED_CONTENT_CAROUSEL_SLIDE_LIBERTY_ID,
  FEATURED_CONTENT_CAROUSEL_SLIDE_SIMPLI_SAFE_ID,
  FEATURED_CONTENT_CAROUSEL_SLIDES,
  SCENARIOS_URL,
  HOME_MAINTENANCE_TASKS_URL,
  TOOLTIP_VALUES,
  LOCAL_STORAGE_LATER_KEY,
  INVESTED_HOMEOWNER_SUPPORT_NUMBER,
  HED_USER_SUPPORT_NUMBER,
  PRODUCT_SUPPORT_EMAIL,
  INVESTMENT_SUPPORT_EMAIL,
};
